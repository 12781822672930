import * as aboutUsItem from './aboutUsItems';
import whoWeAreHD from '../../assets/images/about-us/who-we-are/who-we-are-Header-HD.jpg';
import whoWeAreMobile from '../../assets/images/about-us/who-we-are/who-we-are-Header-Mobile.jpg';
import whoWeAreTablet from '../../assets/images/about-us/who-we-are/who-we-are-Header-Tablet.jpg';
import whoWeAre2k from '../../assets/images/about-us/who-we-are/who-we-are-Header-2k.jpg';
import whoWeAre4k from '../../assets/images/about-us/who-we-are/who-we-are-Header-4k.jpg';
import valuesHD from '../../assets/images/about-us/our-values/our-values-HEADER-HD.jpg';
import valuesMobile from '../../assets/images/about-us/our-values/our-values-HEADER-Mobile.jpg';
import valuesTablet from '../../assets/images/about-us/our-values/our-values-HEADER-Tablet.jpg';
import values2k from '../../assets/images/about-us/our-values/our-values-Header-2k.jpg';
import values4k from '../../assets/images/about-us/our-values/our-values-Header-4k.jpg';
import globalFootprintHD from '../../assets/images/about-us/global-footprint/global-footprint-Header-HD.jpg';
import globalFootprintMobile from '../../assets/images/about-us/global-footprint/global-footprint-Header-Mobile.jpg';
import globalFootprintTablet from '../../assets/images/about-us/global-footprint/global-footprint-Header-Tablet.jpg';
import globalFootprint2k from '../../assets/images/about-us/global-footprint/global-footprint-Header-2k.jpg';
import globalFootprint4k from '../../assets/images/about-us/global-footprint/global-footprint-Header-4k.jpg';
import usHD from '../../assets/images/about-us/who-we-are/who-we-are-image.jpg';
import HQ from '../../assets/images/about-us/who-we-are/who-we-are-banner.jpg';
import valuesBannerImageHD from '../../assets/images/about-us/our-values/our-values-banner.png';
import valuesBannerImageMobile from '../../assets/images/about-us/our-values/our-values-banner-mobile.png';
import valuesBannerImageTablet from '../../assets/images/about-us/our-values/our-values-banner-tablet.png';
import valuesImageHD from '../../assets/images/about-us/our-values/our-values-image.jpg';
import valuesImageMobile from '../../assets/images/about-us/our-values/our-values-image-mobile.jpg';
import valuesImageTablet from '../../assets/images/about-us/our-values/our-values-image-tablet.jpg';
import globalFootprintBannerImage from '../../assets/images/about-us/global-footprint/global-footprint-banner.jpg';
import globalFootprintImage from '../../assets/images/about-us/global-footprint/global-footprint.jpg';

const aboutUsPages = [
  {
    path: '/who-we-are',
    subtitle: aboutUsItem.WHO_WE_ARE_SECTION_SUBTITLE,
    video: '',
    content4k: whoWeAre2k,
    content2k: whoWeAre4k,
    HDContent: whoWeAreHD,
    mobileContent: whoWeAreMobile,
    tabletContent: whoWeAreTablet,
    bannerImageHD: HQ,
    bannerImageMobile: HQ,
    bannerImageTablet: HQ,
    descriptionTitle: aboutUsItem.ABOUT_US_DESCRIPTION_TITLE,
    secondDescriptionTitle: aboutUsItem.WHO_WE_ARE_DESCRIPTION_SUBTITLE,
    descriptionImageHD: usHD,
    descriptionImageMobile: usHD,
    descriptionImageTablet: usHD,
    secondImage: '',
    imageHeight: '31.75rem',
    firstBoxAboutSecondDisplay: 'none',
    secondBoxAboutSecondDisplay: 'flex',
    imageDescriptionHeight: '88%',
    titleWidth: '21rem',
    subparagraphMarginTop: '2rem',
    paragraph: aboutUsItem.WHO_WE_ARE_DESCRIPTION_PARAGRAPH1,
    secondParagraph: aboutUsItem.WHO_WE_ARE_PARAGRAPH2,
    thirdParagraph: aboutUsItem.WHO_WE_ARE_PARAGRAPH3,
    fourthParagraph: aboutUsItem.WHO_WE_ARE_PARAGRAPH4,
    minHeightBanner: '36.25rem',
    heightBannerLarge: '42rem',
    heightTablet: 'auto',
    justifyContentFirst: 'center',
    justifyContentSecond: 'center',
    marginTopBoxFirst: '0',
    marginTopBoxSecond: '0',
    marginTopFirstSection: '6.6rem',
    marginBottomFirstSection: '6.6rem',
    marginTopSecondSection: '2.4rem',
    marginBottomSecondSection: '8rem',
    marginTopFirstSectionTablet: '4.5rem',
    marginBottomFirstSectionTablet: '4rem',
    marginTopSecondSectionTablet: '3rem',
    marginBottomSecondSectionTablet: '5rem',
    marginTop2k: '10rem',
    marginBottom2k: '10rem',
    bannerColor: 'transparent',
    gridFirst: '4',
    gridSecond: '3',
    gridInitial: '3',
  },
  {
    path: '/values',
    subtitle: aboutUsItem.VALUES_SECTION_SUBTITLE,
    video: '',
    content4k: values4k,
    content2k: values2k,
    HDContent: valuesHD,
    mobileContent: valuesMobile,
    tabletContent: valuesTablet,
    bannerImageHD: valuesBannerImageHD,
    bannerImageMobile: valuesBannerImageMobile,
    bannerImageTablet: valuesBannerImageTablet,
    descriptionTitle: aboutUsItem.ABOUT_US_DESCRIPTION_TITLE,
    secondDescriptionTitle: '',
    descriptionImageHD: valuesImageHD,
    descriptionImageMobile: valuesImageMobile,
    descriptionImageTablet: valuesImageTablet,
    secondImage: '',
    imageHeight: '27.3rem',
    firstBoxAboutSecondDisplay: 'none',
    secondBoxAboutSecondDisplay: 'none',
    imageDescriptionHeight: '94%',
    titleWidth: '',
    subparagraphMarginTop: '2rem',
    paragraph: aboutUsItem.VALUES_PARAGRAPH1,
    secondParagraph: aboutUsItem.VALUES_PARAGRAPH2,
    thirdParagraph: aboutUsItem.VALUES_PARAGRAPH3,
    fourthParagraph: '',
    minHeightBanner: '36rem',
    heightBannerLarge: '46rem',
    heightTablet: 'auto',
    justifyContentFirst: 'center',
    justifyContentSecond: 'center',
    marginTopBoxFirst: '0',
    marginTopBoxSecond: '0',
    marginTopFirstSection: '6.6rem',
    marginBottomFirstSection: '6.6rem',
    marginTopSecondSection: '10rem',
    marginBottomSecondSection: '14rem',
    marginTopFirstSectionTablet: '4.5rem',
    marginBottomFirstSectionTablet: '4rem',
    marginTopSecondSectionTablet: '3rem',
    marginBottomSecondSectionTablet: '5rem',
    marginTop2k: '10rem',
    marginBottom2k: '10rem',
    bannerColor: 'transparent linear-gradient(91deg, #005BAC 0%, #00CCFF 100%) 0% 0% no-repeat padding-box',
    gridFirst: '4',
    gridSecond: '4',
    gridInitial: '3',
  },
  {
    path: '/global-footprint',
    subtitle: aboutUsItem.GLOBAL_FOOTPRINT_SECTION_SUBTITLE,
    video: '',
    content4k: globalFootprint4k,
    content2k: globalFootprint2k,
    HDContent: globalFootprintHD,
    mobileContent: globalFootprintMobile,
    tabletContent: globalFootprintTablet,
    bannerImageHD: globalFootprintBannerImage,
    bannerImageMobile: globalFootprintBannerImage,
    bannerImageTablet: globalFootprintBannerImage,
    descriptionTitle: aboutUsItem.GLOBAL_FOOTPRINT_DESCRIPTION_TITLE,
    secondDescriptionTitle: aboutUsItem.GLOBAL_FOOTPRINT_DESCRIPTION_SUBTITLE,
    secondDescriptionTitleMobile1:
      aboutUsItem.GLOBAL_FOOTPRINT_DESCRIPTION_SUBTITLE_MOBILE_PART1,
    secondDescriptionTitleMobile2:
      aboutUsItem.GLOBAL_FOOTPRINT_DESCRIPTION_SUBTITLE_MOBILE_PART2,
    descriptionImage: '',
    secondImageHD: globalFootprintImage,
    secondImageMobile: globalFootprintImage,
    secondImageTablet: globalFootprintImage,
    imageHeight: '31.75rem',
    firstBoxAboutSecondDisplay: 'flex',
    secondBoxAboutSecondDisplay: 'none',
    imageDescriptionHeight: '92%',
    titleWidth: '22rem',
    subparagraphMarginTop: '0',
    paragraph: aboutUsItem.GLOBAL_FOOTPRINT_DESCRIPTION_PARAGRAPH1,
    secondParagraph: aboutUsItem.GLOBAL_FOOTPRINT_PARAGRAPH2,
    thirdParagraph: '',
    fourthParagraph: '',
    newParagraph: aboutUsItem.GLOBAL_FOOTPRINT_PARAGRAPH2_PART2,
    firstListTitle: aboutUsItem.GLOBAL_FOOTPRINT_LOCATION_TITLE,
    secondListTitle: aboutUsItem.GLOBAL_FOOTPRINT_HISTORY_TITLE,
    thirdListTitle: aboutUsItem.GLOBAL_FOOTPRINT_TECHNOLOGY_TITLE,
    minHeightBanner: '48.063rem',
    heightBannerLarge: '57rem',
    heightTablet: 'auto',
    justifyContentFirst: 'center',
    justifyContentSecond: 'space-between',
    marginTopBoxFirst: '0',
    marginTopBoxSecond: '0.2rem',
    marginTopFirstSection: '9.375rem',
    marginBottomFirstSection: '6.25rem',
    marginTopSecondSection: '7rem',
    marginBottomSecondSection: '10rem',
    marginTopFirstSectionTablet: '4.5rem',
    marginBottomFirstSectionTablet: '4rem',
    marginTopSecondSectionTablet: '1rem',
    marginBottomSecondSectionTablet: '0',
    marginTop2k: '10rem',
    marginBottom2k: '10rem',
    bannerColor: 'transparent',
    gridFirst: '4',
    gridSecond: '4',
    gridInitial: '3',
  },
];
export default aboutUsPages;
