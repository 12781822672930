import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination } from 'swiper';
import 'swiper/swiper.scss';
import '../../global/swiperPagination.scss';
import FooterContact from '../../components/Footer/footerContact';
import Banner from '../../components/HomeSection/Banner';
import HomeDescription from '../../components/HomeSection/HomeDescription';
import HeroSection from '../../components/HomeSection/HeroSection';
import SliderWeb from '../../components/HomeSection/SliderWeb';
import HomeService from '../../components/HomeSection/HomeService';
import RedirectToTop from '../../components/Footer/RedirectToTop';
import {
  homeServiceContentSwiper,
  homeServiceContent,
} from '../../components/HomeSection/homeServiceContent';
import UseScrollToTop from '../../hooks/UseScrollToTop';
import {
  FIRST_BANNER_TITLE,
  SECOND_BANNER_TITLE,
  SECOND_BANNER_TEXT,
  HOME_HERO_SECTION_SUBTITLE,
} from '../../components/HomeSection/homeText';
import { bannerColor } from '../../colors';
import carVideoWebmHDHigh from '../../assets/images/home/video/full-hd-high.webm';
import carVideoWebmHDLow from '../../assets/images/home/video/full-hd-low.webm';
import carVideoMP4HDHigh from '../../assets/images/home/video/full-hd-high.mp4';
import carVideoMp4HDLow from '../../assets/images/home/video/full-hd-low.mp4';

const useStyles = makeStyles(() => ({
  homeContainer: {
    overflow: 'hidden',
    minHeight: '200vh',
    width: '100%',
    position: 'relative',
  },
  webSlider: {
    display: 'flex',
    '@media(max-width:67.5em)': {
      display: 'none',
    },
    '@media(max-device-width:67.5em)': {
      display: 'none',
    },
  },
  tabletSlider: {
    display: 'none',
    '@media(max-width:67.5em)': {
      display: 'flex',
    },
    '@media(max-device-width:67.5em)': {
      display: 'flex',
    },
  },
}));

const Home = ({
  deviceSize, headerRef,
}) => {
  const classes = useStyles();
  let videoFormat;
  const userAgentString = navigator.userAgent;
  const chromeAgent = userAgentString.indexOf('Chrome') > -1;
  let safariAgent = userAgentString.indexOf('Safari') > -1;
  if (chromeAgent && safariAgent) safariAgent = false;
  const getVideo = () => {
    if (chromeAgent) {
      videoFormat = 'webm';
      if (deviceSize.width <= '1080') {
        return carVideoWebmHDLow;
      }
      return carVideoWebmHDHigh;
    }
    videoFormat = 'mp4';
    if (deviceSize.width <= '1080') {
      return carVideoMp4HDLow;
    }
    return carVideoMP4HDHigh;
  };

  return (
    <div className={classes.homeContainer}>
      <HeroSection
        deviceSize={deviceSize}
        positionSection="relative"
        headerRef={headerRef}
        subtitle={HOME_HERO_SECTION_SUBTITLE}
        video={getVideo()}
        videoFormat={videoFormat}
        safariAgent={safariAgent}
        sectionId="home-section"
        webContent=""
        mobileContent=""
        tabletContent=""
        justifyContent="space-between"
      />
      <HomeDescription sectionId="home-section" />
      <Banner
        minHeight="18rem"
        minHeightTablet="18rem"
        title={FIRST_BANNER_TITLE}
        content=""
        image=""
        bannerColor={bannerColor}
        justifyContent="center"
      />
      <div className={classes.webSlider}>
        <SliderWeb
          content={homeServiceContent}
        />
      </div>
      <div className={classes.tabletSlider}>
        <Swiper
          modules={[Pagination]}
          pagination={{ clickable: true }}
          spaceBetween={0}
          grabCursor
          slidesPerView={1}
        >
          {homeServiceContentSwiper.map((homeService) => (
            <SwiperSlide key={homeService.title}>
              <HomeService
                title={homeService.title}
                subtitle={homeService.subtitle}
                titleDescription={homeService.titleDescription}
                items={homeService.items}
                URL={homeService.URL}
                image={homeService.image}
                displayWrapper={homeService.displayWrapper}
                alignItemsWrapper={homeService.alignItemsWrapper}
                order={homeService.order}
                height={homeService.height}
                justifyContent={homeService.justifyContent}
                color={homeService.color}
                hoverBackgroundColor={homeService.hoverBackgroundColor}
                titleColor={homeService.titleColor}
                hoverTitleColor={homeService.hoverTitleColor}
                alignItems={homeService.alignItems}
                alignSubtitle={homeService.alignSubtitle}
                background={homeService.background}
                width={homeService.width}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <Banner
        minHeight="18rem"
        minHeightTablet="25rem"
        title={SECOND_BANNER_TITLE}
        content={SECOND_BANNER_TEXT}
        bannerColor={bannerColor}
        justifyContent="space-evenly"
      />
      <FooterContact />
      <RedirectToTop />
      <UseScrollToTop />
    </div>
  );
};

export default Home;
