/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import axios from 'axios';
import {
  white, neusoftBlue, textColor, errorInputColor, secondBlue,
} from '../../colors';
import uploadButton from '../../assets/images/icons/careers/open-positions/feather-upload.svg';
import deleteFile from '../../assets/images/icons/careers/open-positions/delete-file-icon.svg';
import addFile from '../../assets/images/icons/careers/open-positions/ionic-ios-add-circle-outline.svg';
import calendarButton from '../../assets/images/icons/careers/open-positions/feather-calendar.svg';

const useStyles = makeStyles(() => ({
  openPositionContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  openPositionDetails: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'transparent linear-gradient(90deg, #005BAC 0%, #00CCFF 100%) 0% 0% no-repeat padding-box',
    color: white,
    '& h3': {
      margin: '4rem 0',
      '@media(max-width:67.5em)': {
        marginBottom: '0',
      },
      '@media(max-width:47.938em)': {
        fontSize: '1.7rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.7rem',
      },
    },
    '& h4': {
      margin: '2.5rem 0',
      textAlign: 'center',
      fontWeight: 'normal',
      '@media(max-width:67.5em)': {
        width: '80%',
      },
      '@media(max-width:47.938em)': {
        width: '85%',
        fontSize: '1.2rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.2rem',
      },
    },
    '& p': {
      width: '70%',
      margin: '5rem 0',
      textAlign: 'center',
      fontStyle: 'italic',
      '@media(max-width:67.5em)': {
        width: '73%',
        marginTop: '0',
      },
      '@media(max-width:47.938em)': {
        width: '90%',
        fontSize: '1.2rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.2rem',
      },
    },
  },
  openPositionApplicationDetail: {
    width: '70%',
    margin: '1rem auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '@media(max-width:67.5em)': {
      flexDirection: 'column',
      alignItems: 'center',
      width: '70%',
    },
    '@media(max-width:47.938em)': {
      width: '80%',
      marginTop: '0',
      marginBottom: '2.8rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      marginTop: '0',
      marginBottom: '2rem',
    },
  },
  openPositionBox: {
    width: '36%',
    '& ul': {
      paddingInlineStart: '0',
      width: '100%',
      '& li': {
        fontWeight: 'bold',
        fontSize: '1.5rem',
        '@media(max-width:67.5em) and (orientation: portrait)': {
          fontSize: '1.6rem',
        },
        '@media(max-width:47.938em)': {
          fontSize: '1.2rem',
        },
        '@media only screen and (max-height : 47em) and (orientation: landscape)':
        {
          fontSize: '1.1rem',
        },
        '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
        {
          fontSize: '1.2rem',
        },
        '&::marker': {
          fontSize: '1.3rem',
          '@media(max-width:47.938em)': {
            fontSize: '1.35rem',
          },
          '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
          {
            fontSize: '1.35rem',
          },
        },
      },
    },
    '& h4': {
      textAlign: 'start',
      margin: '0',
      fontWeight: 'medium',
    },
    '@media(max-width:67.5em)': {
      width: '100%',
      marginBottom: '4rem',
    },
    '@media(max-width:47.938em)': {
      width: '100%',
      marginBottom: '0',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      marginBottom: '0',
    },
  },
  openPositionFormContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: white,
  },
  careersTitle: {
    height: '5rem',
    margin: '3rem 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& h3': {
      color: neusoftBlue,
      textAlign: 'center',
      '@media(max-width:47.938em)': {
        margin: '0',
        fontSize: '1.5rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '2rem',
      },
    },
    '@media(max-width:64em)': {
      marginBottom: '3rem',
    },
    '@media only screen and (max-device-height : 437em) and (orientation: landscape)':
    {
      margin: '3rem 0',
    },
  },
  sectionLine: {
    marginTop: '0.8rem',
    backgroundColor: neusoftBlue,
    width: '8rem',
    height: '0.25rem',
    zIndex: '1',
  },
  formBox: {
    width: '55%',
    margin: '0 auto',
    marginTop: '2rem',
    marginBottom: '6rem',
    '@media(max-width:67.5em)': {
      width: '90%',
    },
  },
  enableButton: {
    alignSelf: 'center',
    cursor: 'pointer',
    padding: '1rem 0.2rem',
    width: '10.5rem',
    border: '2px solid #00002E',
    marginTop: '3rem',
    background: white,
    color: textColor,
    fontWeight: 'bold',
    fontSize: '1.125rem',
    textTransform: 'uppercase',
    opacity: '1',
    '@media(max-width:67.5em)': {
      marginBottom: '1.5rem',
    },
    '@media(max-width:47.938em)': {
      fontSize: '1.4rem',
      marginBottom: '0',
    },
    '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em) and (orientation: portrait)':
    {
      width: '12.45rem',
      fontSize: '1.35rem',
      padding: '1.3rem 0.2rem',
    },
    '@media(max-device-width:40.625em)': {
      width: '9.2rem',
      fontSize: '1.35rem',
      marginBottom: '0',
      padding: '0.6rem 0.2rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      width: '8.5rem',
    },
    '@media only screen and (max-device-width : 20em)': {
      padding: '0.5rem 0.2rem',
      width: '10.5rem',
    },
    '&:hover': {
      background: textColor,
      color: white,
    },
    transition: 'all ease 0.3s',
  },
  openPositionForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      margin: '0',
      marginBottom: '1rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      fontSize: '1.25rem',
    },
    '& input': {
      fontSize: '1.5rem',
      padding: '0 1rem',
      color: textColor,
      width: '100%',
      height: '6.25rem',
      margin: '0',
      marginTop: '1rem',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#00002E1F',
      borderRadius: '4px',
      outline: 'none',
      '@media(min-width:150em)': {
        height: '5.5rem',
      },
      '@media(max-width:47.938em)': {
        height: '4.25rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        height: '4.25rem',
      },
    },
    '& input:focus': {
      borderColor: '#00002E80',
    },
    '& input:active': {
      borderColor: textColor,
    },
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    '& label': {
      color: textColor,
      position: 'relative',
      margin: '2rem 0',
      fontSize: '1.5rem',
      height: '1.5rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& h4': {
        fontWeight: 'normal',
        marginLeft: '5rem',
        '@media(max-width:47.938em)': {
          marginLeft: '2rem',
          fontSize: '1.35rem',
        },
        '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
        {
          marginLeft: '2rem',
          fontSize: '1.35rem',
        },
      },
      '& span': {
        color: neusoftBlue,
        fontWeight: 'bold',
      },
    },
    '& p': {
      marginLeft: '6.3rem',
      fontStyle: 'italic',
      opacity: '0.57',
      '& span': {
        color: '#00CCFF',
      },
      '@media(max-width:67.5em)': {
        width: '95%',
        marginLeft: '0',
        textAlign: 'center',
      },
      '@media(max-width:47.938em)': {
        fontSize: '1.2rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.2rem',
      },
    },
    '& input[type="checkbox"]': {
      visibility: 'hidden',
      width: '1.5rem',
      height: '1.5rem',
      padding: '0',
      margin: '0',
    },

  },
  error: {
    color: errorInputColor,
    position: 'relative',
    '& input': {
      color: errorInputColor,
      borderColor: errorInputColor,
    },
    '& p': {
      position: 'absolute',
      fontWeight: 'bold',
      fontSize: '0.9rem',
      backgroundColor: white,
      left: '1rem',
      bottom: '-5%',
      padding: '0 0.5rem',
    },
  },
  checkboxCustom: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '1.5rem',
    width: '1.5rem',
    backgroundColor: white,
    borderColor: textColor,
    borderRadius: '0.2rem',
    borderWidth: '0.2rem',
    borderStyle: 'solid',
  },
  uploadButtonBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    height: '3.5rem',
    width: '14.75rem',
    border: '3px solid #00012C',
    padding: '0.2rem',
    marginTop: '3rem',
    background: white,
    color: textColor,
    fontWeight: 'normal',
    fontSize: '1.5rem',
  },
  fileButtonBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'default',
    height: '3.5rem',
    width: '14.75rem',
    border: '3px solid #005BAC',
    padding: '0 1rem',
    background: neusoftBlue,
    color: white,
    fontWeight: 'normal',
    fontSize: '1.5rem',
    textOverflow: 'ellipsis',
    marginRight: '20px',
    marginBottom: '1rem',
  },
  fileButtonContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'auto',
    '& span': {
      wordBreak: 'break-all',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '1.5rem',
    },
  },
  addButtonBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    height: '3.5rem',
    width: '3.5rem',
    padding: '0.2rem',
    fontWeight: 'normal',
    fontSize: '1.5rem',
    textOverflow: 'ellipsis',
    marginRight: '20px',
  },
  addButtonContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'auto',
  },
  removeFileIcon: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0 0.6rem',
  },
  uploadButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'auto',
    '& img': {
      height: '1.5rem',
      marginRight: '1rem',
      '@media(max-width:67.5em)': {
        height: '1.5rem',
      },
      '@media(max-width:47.938em)': {
        height: '1.2rem',
      },

    },
  },
  disableButton: {
    alignSelf: 'center',
    cursor: 'pointer',
    width: '10.5rem',
    border: '2px solid #00002E',
    padding: '1rem 0.2rem',
    marginTop: '3rem',
    background: white,
    color: textColor,
    fontWeight: 'bold',
    fontSize: '1.125rem',
    textTransform: 'uppercase',
    opacity: '0.5',
    '@media(max-width:67.5em)': {
      marginBottom: '1.5rem',
    },
    '@media(max-width:47.938em)': {
      fontSize: '1.4rem',
      marginBottom: '0',
    },
    '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em) and (orientation: portrait)':
    {
      width: '12.45rem',
      fontSize: '1.35rem',
      padding: '1.3rem 0.2rem',
    },
    '@media(max-device-width:40.625em)': {
      width: '9.2rem',
      fontSize: '1.35rem',
      marginBottom: '0',
      padding: '0.6rem 0.2rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      width: '8.5rem',
    },
    '@media only screen and (max-device-width : 20em)': {
      padding: '0.5rem 0.2rem',
      width: '10.5rem',
    },
    transition: 'all ease 0.3s',
  },
  startDate: {
    position: 'relative',
  },
  dateContainer: {
    height: '50%',
    width: '4rem',
    backgroundColor: white,
    position: 'absolute',
    pointerEvents: 'none',
    right: '1rem',
    bottom: '7%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  date: {
    height: '2rem',
    width: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '@media(max-width:47.938em)': {
      height: '1.5rem',
      width: '1.5rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      height: '2.5rem',
      width: '2.5rem',
    },
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  fileEntry: {
    marginTop: '10px',
  },
  fileList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  loading: {
    fontSize: 0,
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    borderLeftColor: 'transparent',
    animation: 'rotate 1.4s ease 0.3s infinite',
    '&:hover': {
      background: white,
    },
  },
  sent: {
    color: secondBlue,
    borderColor: secondBlue,
    opacity: '1',
    transition: 'all ease 0.3s',
  },
  sendError: {
    fontSize: '1.5rem',
    fontStyle: 'italic',
    '& span:': {
      fontWeight: 'bold',
    },
    marginTop: '4rem',
    textAlign: 'center',
    color: errorInputColor,
  },
}));

const OpenPositionForm = ({
  desktopLayout,
  pathCase,
  positions,
  positionId,
  position,
  ...props
}) => {
  const classes = useStyles(props);
  const initialFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    location: '',
    salary: '',
    startDate: '',
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const [formErrors, setFormErrors] = useState(initialFormValues);
  const [submitForm, setSubmitForm] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSent, setSent] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [sendError, setSendError] = useState(false);

  let completedFormValues = [];
  useEffect(() => {
    Object.values(formValues).every((val) => {
      completedFormValues = [...completedFormValues, val];
      return completedFormValues;
    });
  }, [formValues]);
  const checkboxHandle = () => {
    const check = checkbox;
    setCheckbox(!check);
  };

  useEffect(() => () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }, []);

  useEffect(() => {
    if (completedFormValues.every((val) => val !== '') && (Object.keys(formErrors).length === 0) && uploadedFiles.length > 0 && checkbox === true) {
      setSubmitForm(true);
    } else { setSubmitForm(false); }
  }, [formValues, checkboxHandle]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = (values) => {
    const errors = {};
    const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    const phoneRegex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    if (!formErrors.email) {
      setEmailError(false);
    }
    if (!formErrors.phone) {
      setPhoneError(false);
    }
    if (!emailRegex.test(values.email)) {
      errors.email = 'E-mail should have a format of *****@****.com';
      setEmailError(true);
      setSubmitForm(false);
    }
    if (!phoneRegex.test(values.phone)) {
      errors.phone = 'Phone number is not valid';
      setPhoneError(true);
      setSubmitForm(false);
    }
    return errors;
  };
  const validateForm = () => {
    setFormErrors(validate(formValues));
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0) {
      setPhoneError(false);
      setEmailError(false);
    }
  }, [formErrors]);

  const onFileUpload = (event) => {
    setUploadedFiles([...uploadedFiles, ...event.target.files]);
  };

  async function uploadFile(file) {
    if (!file) {
      return Promise.resolve();
    }
    const response = await axios.post(`${process.env.REACT_APP_URL}/api/create-upload-url`, { fileName: file.name, type: file.type });
    if (response?.data?.url) {
      return axios.put(response.data.url, file);
    }
    return Promise.resolve(); // Error, handle properly
  }

  const handleError = (error) => {
    console.log(error, '1');
    setLoading(false);
    setSendError(true);
    const sentTimeoutId = setTimeout(() => {
      setSendError(false);
    }, 10000);
    setTimeoutId(sentTimeoutId);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    Promise.all(uploadedFiles.map((file) => uploadFile(file))).then(() => {
      const userData = {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email,
        phone: formValues.phone,
        location: formValues.location,
        salary: formValues.salary,
        startDate: formValues.startDate,
        jobTitle: position.title,
        jobReferenceNumber: position.referenceNumber,
        jobLocation: position.jobLocation,
      };
      const fileNames = uploadedFiles.map((file) => file.name);
      axios.post(`${process.env.REACT_APP_URL}/api/send-application`,
        { userData, fileNames }).then(() => {
          setFormValues(initialFormValues);
          setCheckbox(false);
          setUploadedFiles([]);
          setLoading(false);
          setSent(true);
          const sentTimeoutId = setTimeout(() => {
            setSent(false);
          }, 3000);
          setTimeoutId(sentTimeoutId);
        }).catch(handleError);
    }).catch(handleError);
  };
  const removeFile = (index) => () => {
    const files = [...uploadedFiles];
    files.splice(index, 1);
    setUploadedFiles(files);
  };

  const getSubmitButton = () => {
    if (sendError) return (<></>);
    return submitForm ? (<button type="submit" className={`${loading ? classes.loading : ''} ${classes.enableButton}`}>SEND</button>)
      : (<button type="submit" disabled className={`${isSent ? classes.sent : ''} ${classes.disableButton}`}>{isSent ? 'SUCCESS' : 'SEND'}</button>);
  };

  return (
    <div className={classes.openPositionContainer}>
      <div className={classes.openPositionDetails}>
        <h3>Have we caught your interest?</h3>
        <h4>
          Before submitting your application,
          please check that you have included the following:
        </h4>
        <div className={classes.openPositionApplicationDetail}>
          <div className={classes.openPositionBox}>
            <ul>
              <li>
                CV
              </li>
              <li>
                Letter of application (incl. the information
                of your notice period as well as your desired salary level)
              </li>
            </ul>
          </div>
          <div className={classes.openPositionBox}>
            <ul>
              <li>
                References and/or certificates
              </li>
              <li>
                University diploma or vocational training certificate
              </li>
              <li>
                Graduation diploma (if applicable)
              </li>
            </ul>
          </div>
        </div>
        <p>
          A CV should reflect your skills, achievements and
          summarize your education history, work experience and hobbies and interests.
          It should present you in the best possible light.
          Think of it as a marketing tool.
          Ensure that your CV is concise, to the point and updated
          plus try to restrict it to a max. of 2 pages and make it easy to read.
        </p>
      </div>
      <div className={classes.openPositionFormContainer}>
        <div className={classes.careersTitle}>
          <h3>Apply today</h3>
          <div className={classes.sectionLine} />
        </div>
        <div className={classes.formBox}>
          <form className={classes.openPositionForm} onSubmit={handleSubmit}>
            <label htmlFor="firstName">
              First Name*
              <input
                id="firstName"
                name="firstName"
                type="text"
                value={formValues.firstName}
                onChange={handleChange}
                required
              />
            </label>
            <label htmlFor="lastName">
              Last Name*
              <input
                id="lastName"
                name="lastName"
                type="text"
                value={formValues.lastName}
                onChange={handleChange}
                required
              />
            </label>
            <label htmlFor="eMail" className={emailError ? classes.error : ''}>
              E-mail*
              <input
                id="eMail"
                name="email"
                type="email"
                value={formValues.email}
                onChange={handleChange}
                onBlur={validateForm}
                required
              />
              {emailError && <p>{formErrors.email}</p>}
            </label>
            <label htmlFor="phone" className={phoneError ? classes.error : ''}>
              Phone*
              <input
                id="phone"
                name="phone"
                type="tel"
                value={formValues.phone}
                onChange={handleChange}
                onBlur={validateForm}
                required
              />
              {phoneError && <p>{formErrors.phone}</p>}
            </label>
            <label htmlFor="location">
              Location*
              <input
                id="location"
                name="location"
                type="text"
                value={formValues.location}
                onChange={handleChange}
                required
              />
            </label>
            <label htmlFor="salaryExpectations">
              Salary Expectations*
              <input
                id="salaryExpectations"
                name="salary"
                type="text"
                value={formValues.salary}
                onChange={handleChange}
                required
              />
            </label>
            <label htmlFor="startDate" className={classes.startDate}>
              Possible Start Date*
              <input
                id="startDate"
                name="startDate"
                type="date"
                value={formValues.startDate}
                onChange={handleChange}
                required
                min={new Date().toJSON().split('T')[0]}
              />
              <div className={classes.dateContainer}>
                <div className={classes.date}><img src={calendarButton} alt="" /></div>
              </div>
            </label>
            {uploadedFiles.length
              ? (
                <div className={classes.fileList}>
                  {
                    uploadedFiles.map((file, index) => (
                      <div className={classes.fileButtonBox}>
                        <div className={classes.fileButtonContent}>
                          <div
                            className={classes.removeFileIcon}
                            onClick={removeFile(index)}
                            onKeyDown={removeFile(index)}
                            role="button"
                            tabIndex={0}
                          >
                            <img src={deleteFile} alt="" />
                          </div>
                          <span>{file.name}</span>
                        </div>
                      </div>
                    ))
                  }
                  <label className={classes.addButtonBox} htmlFor="file">
                    <div className={classes.addButtonContent}>
                      <img src={addFile} alt="" />
                    </div>
                    <input type="file" name="files" value={formValues.files} onChange={onFileUpload} id="file" hidden multiple />
                  </label>
                </div>
              )
              : (
                <label className={classes.uploadButtonBox} htmlFor="file">
                  <div className={classes.uploadButton}>
                    <img src={uploadButton} alt="" />
                    Attach files*
                  </div>
                  <input type="file" name="files" value={formValues.files} onChange={onFileUpload} id="file" hidden multiple />
                </label>
              )}

            <div className={classes.checkbox}>
              <label htmlFor="GDPR">
                <input type="checkbox" id="GDPR" checked={checkbox} onClick={checkboxHandle} name="gdpr" />
                <span id="checkbox-button" className={classes.checkboxCustom} />
                <h4>
                  I have read and understood the
                  <span> privacy policy </span>
                </h4>
              </label>
              <p>
                By expressing consent explicitly, you agree to allow Neusoft to store your personal
                data in our Candidates Database,
                as well as process it in order to achieve the purpose of this Agreement.
                You can request the deletion of your data.
                Please contact
                <span> career@de.neusoft.com </span>
                for this purpose.
              </p>
            </div>
            {sendError ? (
              <div>
                <div className={classes.sendError}>
                  Oops! Something went wrong, please try again
                  {' '}
                  <br />
                  or contact us directly at
                  {' '}
                  <strong> career@de.neusoft.com </strong>
                </div>
              </div>
            ) : <></>}
            {getSubmitButton()}
          </form>
        </div>
      </div>
    </div>
  );
};

export default OpenPositionForm;
