/* eslint-disable max-len */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import TextBox from '../../components/Common/TextBox';
import UseScrollToTop from '../../hooks/UseScrollToTop';
import RedirectToTop from '../../components/Footer/RedirectToTop';

const useStyles = makeStyles(() => ({
  aboutUsContainer: {
    overflow: 'hidden',
    minHeight: '100vh',
    width: '100%',
    position: 'relative',
  },
  container: {
    marginTop: '50px',
    whiteSpace: 'pre-wrap',
  },
}));

const imprintTitle = 'Test Vehicle GPDR - Disclaimer';

const GDPRDisclaimer = ({ deviceSize }) => {
  const classes = useStyles();
  return (
    <div className={classes.aboutUsContainer}>
      <TextBox
        title={imprintTitle}
        deviceSize={deviceSize}
        isGDPRPage
        sections={[
          {
            title: 'Dansk',
            content: (
              <div className={classes.container}>
                <h4>
                  Databeskyttelseserklæring om behandling af personoplysninger
                  (Art. 13 GDPR)
                  <br />
                  Videodata fra testkørsler
                </h4>
                <p>
                  Vi tager beskyttelsen af dine personlige data meget alvorligt.
                  Vi behandler personoplysninger i overensstemmelse med de
                  lovbestemte databeskyttelsesbestemmelser, såsom den generelle
                  databeskyttelsesforordning (GDPR) og andre gældende love og
                  bestemmelser. Denne privatlivspolitik forklarer, hvilke data
                  vi indsamler, og hvad vi bruger dem til. Den forklarer også,
                  hvordan og med hvilket formål dette sker.
                </p>
                <h4>Ansvarlig part</h4>
                <p>
                  Neusoft Technology Solutions GmbH
                  <br />
                  Hugh-Greene-Weg 2-4
                  <br />
                  22529 Hamburg
                  <br />
                  E-mail:
                  {' '}
                  admin@de.neusoft.com
                  <br />
                  <a href="https://www.neusoft.de/">https://www.neusoft.de/</a>
                </p>
                <h4>Kontaktoplysninger for den databeskyttelsesansvarlige</h4>
                <p>
                  Michael Gruber
                  <br />
                  Ekstern databeskyttelsesrådgiver
                  <br />
                  BSP-SECURITY
                  <br />
                  Thundorferstr. 10
                  <br />
                  D-93047 Regensburg
                </p>
                <h4>Formål med behandlingen</h4>
                <p>
                  Vi bruger såkaldte testkøretøjer til udvikling, test og
                  sikring af førerassistentsystemer og
                  førerinformationssystemer. I de særligt markerede
                  testkøretøjer resulterer brugen af sensorer og videoteknologi
                  i dataoptagelser, som også registrerer omgivelserne. Personer
                  og nummerplader, der befinder sig i de optagede omgivelser,
                  registreres i krypteret form i et første behandlingsskridt og
                  gøres uigenkendelige i et andet trin. Derefter slettes de
                  oprindelige data. De anonymiserede data anvendes til
                  kvalitetssikring og validering af softwareløsninger til
                  bilindustrien.
                </p>
                <p>
                  Under visse omstændigheder bestilles såkaldte ordrebehandlere
                  (Art. 28 GDPR) til at udføre kørslerne. Kontrakter om
                  bestillingsbehandling indgås til dette formål i
                  overensstemmelse med bestemmelserne i databeskyttelsesloven.
                </p>
                <h4>Opbevaringsperiode</h4>
                <p>
                  De oprindelige personoplysninger, der er indsamlet under
                  testkørslerne, slettes umiddelbart efter den ovenfor beskrevne
                  afidentifikation.
                </p>
                <h4>Retsgrundlag for behandlingen</h4>
                <p>Art. 6 stk. 1f GDPR (legitim interesse)</p>
                <h4>Modtagere af oplysningerne</h4>
                <p>
                  Interne kontorer. Der videregives ingen data til uautoriserede
                  tredjeparter.
                </p>
                <h4>Sikker behandling</h4>
                <p>
                  Vi anvender de mest avancerede krypteringsmetoder af
                  sikkerhedshensyn og for at beskytte fortrolige data. Vores
                  tekniske og organisatoriske foranstaltninger garanterer den
                  mere sikre behandling af personoplysninger, som kræves i
                  henhold til GDPR.
                </p>
                <h4>Overførsel af data til et tredjeland</h4>
                <p>Der overføres ingen personoplysninger til et tredjeland.</p>
                <h4>Profilering</h4>
                <p>Profilering i henhold til Art. 22 GDPR finder ikke sted.</p>
                <h4>Rettigheder for registrerede personer</h4>
                <p>
                  De registrerede har følgende rettigheder i henhold til GDPR:
                </p>
                <p>
                  Information (artikel 15)
                  <br />
                  Berigtigelse (artikel 16)
                  <br />
                  Sletning (artikel 17)
                  <br />
                  Begrænsning af behandling (artikel 18)
                  <br />
                  Indsigelse mod behandling (artikel 21)
                  <br />
                  Dataportabilitet (artikel 20)
                </p>
                <h4>Ret til at klage til den kompetente tilsynsmyndighed</h4>
                <p>
                  I tilfælde af overtrædelser af GDPR har de registrerede
                  personer ret til at klage til en tilsynsmyndighed, navnlig i
                  den medlemsstat, hvor de har deres sædvanlige opholdssted,
                  arbejdssted eller det sted, hvor den påståede overtrædelse
                  fandt sted.
                </p>
              </div>
            ),
          },
          {
            title: 'Deutsch',
            content: (
              <div className={classes.container}>
                <h4>
                  Datenschutzerklärung zur Verarbeitung personenbezogener Daten
                  (Art. 13 DS-GVO)
                  <br />
                  Videodaten aus Testfahrten
                </h4>
                <p>
                  Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir
                  behandeln personenbezogene Daten entsprechend den gesetzlichen
                  Datenschutzvorschriften, wie etwa der
                  Datenschutz-Grundverordnung (DS-GVO) und weiterer mitgeltender
                  Gesetze und Vorschriften. Die vorliegende Datenschutzerklärung
                  erläutert, welche Daten wir erheben und wofür wir sie nutzen.
                  Sie erläutert auch, wie und zu welchem Zweck das geschieht.
                </p>
                <h4>Verantwortliche Stelle</h4>
                <p>
                  Neusoft Technology Solutions GmbH
                  <br />
                  Hugh-Greene-Weg 2-4
                  <br />
                  22529 Hamburg
                  <br />
                  E-Mail:
                  {' '}
                  admin@de.neusoft.com
                  <br />
                  <a href="https://www.neusoft.de/">https://www.neusoft.de/</a>
                </p>
                <h4>Kontaktdaten des Datenschutzbeauftragten </h4>
                <p>
                  Michael Gruber
                  <br />
                  Externer Datenschutzbeauftragter
                  <br />
                  BSP-SECURITY
                  <br />
                  Thundorferstr. 10
                  <br />
                  D-93047 Regensburg
                </p>
                <h4>Zweck der Verarbeitung</h4>
                <p>
                  Zur Entwicklung, Erprobung und Absicherung von
                  Fahrerassistenz- und Fahrerinformationssystemen setzen wir
                  sogenannte Erprobungsfahrzeuge ein. In den speziell
                  gekennzeichneten Erprobungsfahrzeugen kommt es durch den
                  Einsatz von Sensoren und Videotechnik zu Datenaufzeichnungen,
                  welche auch die Umgebung erfassen. Personen und
                  KFZ-Kennzeichen, die sich auf der erfassten Umgebung befinden
                  werden in einem ersten Verarbeitungsschritt verschlüsselt
                  aufgezeichnet, und in einem zweiten Schritt unkenntlich
                  gemacht. Die Originaldaten werden daraufhin gelöscht. Die
                  anonymisierten Daten dienen der Qualitätssicherung und
                  Validierung von Automotive-Softwarelösungen.
                </p>
                <p>
                  Unter Umständen werden sogenannte Auftragsverarbeiter (Art. 28
                  DS-GVO) mit der Durchführung der Fahrten beauftragt. Hierzu
                  werden gemäß den datenschutzrechtlichen Bestimmungen Verträge
                  zur Auftragverarbeitung geschlossen.
                </p>
                <h4>Speicherdauer</h4>
                <p>
                  Die während der Testfahrten erfassten personenbezogenen
                  Original-Daten werden unmittelbar nach der weiter oben
                  beschriebenen Unkenntlichmachung gelöscht.
                </p>
                <h4>Rechtsgrundlage der Verarbeitung </h4>
                <p>Art. 6 Abs. 1f DS-GVO (berechtigtes Interesse)</p>
                <h4>Empfänger der Daten</h4>
                <p>
                  Interne Stellen. Es erfolgt keine Weitergabe an unbefugte
                  Dritte
                </p>
                <h4>Sichere Verarbeitung </h4>
                <p>
                  Wir nutzen aus Sicherheitsgründen und zum Schutz vertraulicher
                  Daten Verschlüsselungsmethoden nach dem aktuellen Stand der
                  Technik. Unsere technischen und organisatorischen Maßnahmen
                  garantieren die in der DS-GVO geforderte sicherere
                  Verarbeitung personenbezogener Daten.
                </p>
                <h4>Übermittlung der Daten in ein Drittland</h4>
                <p>
                  Es werden keine personenbezogenen Daten in ein Drittland
                  übermittelt.
                </p>
                <h4>Profilbildung</h4>
                <p>Eine Profilbildung nach Art. 22 DS-GVO findet nicht statt</p>
                <h4>Rechte Betroffener</h4>
                <p>Betroffene haben nach DS-GVO folgende Rechte:</p>
                <p>
                  Auskunft (Art. 15)
                  <br />
                  Berichtigung (Art. 16)
                  <br />
                  Löschung (Art. 17)
                  <br />
                  Einschränkung der Verarbeitung (Art. 18)
                  <br />
                  Widerspruch gegen die Verarbeitung (Art. 21)
                  <br />
                  Datenübertragbarkeit (Art. 20)
                </p>
                <h4>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h4>
                <p>
                  Im Falle von Verstößen gegen die DSGVO steht den Betroffenen
                  ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere
                  in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres
                  Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu.
                </p>
              </div>
            ),
          },
          {
            title: 'English',
            content: (
              <div className={classes.container}>
                <h4>
                  Data protection declaration on the processing of personal data
                  (Art. 13 GDPR)
                  <br />
                  Video data from test drives
                </h4>
                <p>
                  We take the protection of your personal data very seriously.
                  We treat personal data in accordance with the statutory data
                  protection regulations, such as the General Data Protection
                  Regulation (GDPR) and other applicable laws and regulations.
                  This privacy policy explains what data we collect and what we
                  use it for. It also explains how and for what purpose this is
                  done.
                </p>
                <h4>Responsible party</h4>
                <p>
                  Neusoft Technology Solutions GmbH
                  <br />
                  Hugh-Greene-Weg 2-4
                  <br />
                  22529 Hamburg
                  <br />
                  E-mail:
                  {' '}
                  admin@de.neusoft.com
                  <br />
                  <a href="https://www.neusoft.de/">https://www.neusoft.de/</a>
                </p>
                <h4>Contact details of the data protection officer</h4>
                <p>
                  Michael Gruber
                  <br />
                  External data protection officer
                  <br />
                  BSP-SECURITY
                  <br />
                  Thundorferstr. 10
                  <br />
                  D-93047 Regensburg
                </p>
                <h4>Purpose of processing</h4>
                <p>
                  We use so-called test vehicles for the development, testing
                  and safeguarding of driver assistance and driver information
                  systems. In the specially marked test vehicles, the use of
                  sensors and video technology results in data recordings that
                  also capture the surroundings. Persons and licence plates that
                  are in the recorded environment are recorded in an encrypted
                  form in a first processing step and made unrecognisable in a
                  second step. The original data is then deleted. The anonymised
                  data is used for quality assurance and validation of
                  automotive software solutions.
                </p>
                <p>
                  In some circumstances, so-called order processors (Art. 28
                  GDPR) are commissioned to carry out the journeys. Contracts
                  for commissioned processing are concluded for this purpose in
                  accordance with the provisions of data protection law.
                </p>
                <h4>Storage period</h4>
                <p>
                  The original personal data collected during the test drives
                  will be deleted immediately after the disidentification
                  described above.
                </p>
                <h4>Legal basis of processing</h4>
                <p>Art. 6 para. 1f GDPR (legitimate interest)</p>
                <h4>Recipients of the data</h4>
                <p>
                  Internal offices. No data is passed on to unauthorised third
                  parties.
                </p>
                <h4>Secure processing</h4>
                <p>
                  We use state-of-the-art encryption methods for security
                  reasons and to protect confidential data. Our technical and
                  organisational measures guarantee the more secure processing
                  of personal data required by the GDPR.
                </p>
                <h4>Transfer of data to a third country</h4>
                <p>No personal data is transferred to a third country.</p>
                <h4>Profiling</h4>
                <p>Profiling according to Art. 22 GDPR does not take place.</p>
                <h4>Rights of data subjects</h4>
                <p>Data subjects have the following rights under the GDPR:</p>
                <p>
                  Information (Art. 15)
                  <br />
                  Correction (Art. 16)
                  <br />
                  Deletion (Art. 17)
                  <br />
                  Restriction of processing (Art. 18)
                  <br />
                  Objection to processing (Art. 21)
                  <br />
                  Data portability (Art. 20)
                </p>
                <h4>Right of appeal to the competent supervisory authority</h4>
                <p>
                  In the event of breaches of the GDPR, data subjects shall have
                  a right of appeal to a supervisory authority, in particular in
                  the Member State of their habitual residence, place of work or
                  the place of the alleged breach.
                </p>
              </div>
            ),
          },
          {
            title: 'Español',
            content: (
              <div className={classes.container}>
                <h4>
                  Declaración de protección de datos sobre el tratamiento de
                  datos personales (artículo 13 reglamento de protección de
                  datos, en breve: RGPD)
                  <br />
                  Datos de vídeo de pruebas de conducción
                </h4>
                <p>
                  Nos tomamos muy en serio la protección de sus datos
                  personales. Tratamos los datos personales de acuerdo con las
                  normas legales de protección de datos, como el RGPD y otras
                  leyes y reglamentos aplicables. Esta política de privacidad
                  explica qué datos recogemos y para qué los utilizamos. También
                  explica cómo y para qué se hace.
                </p>
                <h4>Responsable</h4>
                <p>
                  Neusoft Technology Solutions GmbH
                  <br />
                  Hugh-Greene-Weg 2-4
                  <br />
                  22529 Hamburg
                  <br />
                  Correo electrónico:
                  {' '}
                  admin@de.neusoft.com
                  <br />
                  <a href="https://www.neusoft.de/">https://www.neusoft.de/</a>
                </p>
                <h4>
                  Datos de contacto del responsable de la protección de datos
                </h4>
                <p>
                  Michael Gruber
                  <br />
                  Responsable externo de protección de datos
                  <br />
                  BSP-SECURITY
                  <br />
                  Thundorferstr. 10
                  <br />
                  D-93047 Regensburg
                </p>
                <h4>Objetivo del tratamiento</h4>
                <p>
                  Utilizamos los denominados vehículos de prueba para
                  desarrollo, pruebas y salvaguardia de los sistemas de
                  asistencia e información al conductor. En los vehículos de
                  prueba especialmente marcados, el uso de sensores y tecnología
                  de vídeo da lugar a grabaciones de datos que también captan el
                  entorno. Las personas y las matrículas que se encuentran en el
                  entorno grabado se registran de forma codificada en un primer
                  paso de procesamiento y se hacen irreconocibles en un segundo
                  paso. A continuación se borran los datos originales. Los datos
                  anonimizados se utilizan para el control de calidad y la
                  validación de soluciones de software para automóviles.
                </p>
                <p>
                  En algunas circunstancias, se encarga la realización de los
                  viajes a los denominados encargados de pedidos (artículo 28
                  RGPD). Para ello se celebran contratos de encargo de
                  tratamiento de acuerdo con las disposiciones de la ley de
                  protección de datos.
                </p>
                <h4>Período de almacenamiento</h4>
                <p>
                  Los datos personales originales recogidos durante las pruebas
                  de conduccíon se eliminarán inmediatamente después de la
                  desidentificación descrita anteriormente.
                </p>
                <h4>Base jurídica del tratamiento</h4>
                <p>Artículo 6 adpo. 1f RGPD (interés legítimo)</p>
                <h4>Destinatarios de los datos</h4>
                <p>
                  Oficinas internas. No se transmiten datos a terceros no
                  autorizados.
                </p>
                <h4>Tratamiento seguro</h4>
                <p>
                  Utilizamos métodos de encriptación de última generación por
                  razones de seguridad y para proteger los datos confidenciales.
                  Nuestras medidas técnicas y organizativas garantizan el
                  tratamiento más seguro de los datos personales exigido por el
                  reglamento básico de protección de datos.
                </p>
                <h4>Transferencia de datos a un tercer país</h4>
                <p>No se transferirán datos personales a terceros países.</p>
                <h4>Perfil</h4>
                <p>No se elaboran perfiles según el artículo 22 del RGPD.</p>
                <h4>Derechos de los interesados</h4>
                <p>
                  Los interesados tienen los siguientes derechos en virtud del
                  RGPD:
                </p>
                <p>
                  Información (artículo 15)
                  <br />
                  Rectificación (artículo 16)
                  <br />
                  Supresión (artículo 17)
                  <br />
                  Limitación del tratamiento (artículo 18)
                  <br />
                  Oposición al tratamiento (artículo 21)
                  <br />
                  Portabilidad de los datos (artículo 20)
                </p>
                <h4>
                  Derecho de recurso ante la autoridad de control competente
                </h4>
                <p>
                  En caso de infracción del RGPD, los interesados tendrán
                  derecho a recurrir ante una autoridad de control, en
                  particular en el Estado miembro de su residencia habitual,
                  lugar de trabajo o el lugar de la presunta infracción.
                </p>
              </div>
            ),
          },
          {
            title: 'Français',
            content: (
              <div className={classes.container}>
                <h4>
                  Déclaration de protection des données sur le traitement des
                  données personnelles (Art. 13 GDPR)
                  <br />
                  Données vidéo des essais de conduite
                </h4>
                <p>
                  Nous prenons très au sérieux la protection de vos données
                  personnelles. Nous traitons les données personnelles
                  conformément aux dispositions légales en matière de protection
                  des données, telles que le Règlement général sur la protection
                  des données (RGPD) et les autres lois et règlements
                  applicables. La présente politique de confidentialité explique
                  quelles données nous collectons et à quelles fins nous les
                  utilisons. Elle explique également comment et dans quel but
                  nous le faisons.
                </p>
                <h4>Partie responsable</h4>
                <p>
                  Neusoft Technology Solutions GmbH
                  <br />
                  Hugh-Greene-Weg 2-4
                  <br />
                  22529 Hamburg
                  <br />
                  Courriel:
                  {' '}
                  admin@de.neusoft.com
                  <br />
                  <a href="https://www.neusoft.de/">https://www.neusoft.de/</a>
                </p>
                <h4>Coordonnées du délégué à la protection des données</h4>
                <p>
                  Michael Gruber
                  <br />
                  Délégué externe à la protection des données
                  <br />
                  BSP-SECURITY
                  <br />
                  Thundorferstr. 10
                  <br />
                  D-93047 Regensburg
                </p>
                <h4>Finalité du traitement</h4>
                <p>
                  Nous utilisons des véhicules d’essai pour développer, tester
                  et protéger les systèmes d’aide à la conduite et d’information
                  du conducteur. Dans les véhicules d’essai spécialement
                  marqués, l’utilisation de capteurs et de la technologie vidéo
                  donne lieu à des enregistrements de données qui saisissent
                  également l’environnement. Les personnes et les plaques
                  d’immatriculation qui se trouvent dans l’environnement
                  enregistré sont enregistrées sous forme cryptée lors d’une
                  première étape de traitement et rendues méconnaissables lors
                  d’une seconde étape. Les données originales sont ensuite
                  supprimées. Les données anonymes sont utilisées pour
                  l’assurance qualité et la validation des solutions logicielles
                  automobiles.
                </p>
                <p>
                  Dans certains cas, des sous-traitants (article 28 du RGPD)
                  sont chargés d’effectuer les trajets. Des contrats de
                  sous-traitance sont conclus à cette fin conformément aux
                  dispositions de la loi sur la protection des données.
                </p>
                <h4>Période de stockage</h4>
                <p>
                  Les données à caractère personnel originales collectées au
                  cours des essais routiers seront supprimées immédiatement
                  après la désidentification décrite ci-dessus.
                </p>
                <h4>Base juridique du traitement</h4>
                <p>Art. 6 para. 1f GDPR (intérêt légitime)</p>
                <h4>Destinataires des données</h4>
                <p>
                  Bureaux internes. Aucune donnée n’est transmise à des tiers
                  non autorisés.
                </p>
                <h4>Traitement sécurisé</h4>
                <p>
                  Pour des raisons de sécurité et pour protéger les données
                  confidentielles, nous utilisons des méthodes de cryptage à la
                  pointe de la technologie. Nos mesures techniques et
                  organisationnelles garantissent le traitement plus sûr des
                  données personnelles exigé par le GDPR.
                </p>
                <h4>Transfert de données vers un pays tiers</h4>
                <p>
                  Aucune donnée personnelle n’est transférée vers un pays tiers.
                </p>
                <h4>Profilage</h4>
                <p>
                  Il n’y a pas de profilage conformément à l’Art. 22 GDPR n’a
                  pas lieu.
                </p>
                <h4>Droits des personnes concernées</h4>
                <p>
                  Les personnes concernées ont les droits suivants en vertu du
                  GDPR :
                </p>
                <p>
                  Information (article 15)
                  <br />
                  Rectification (article 16)
                  <br />
                  Suppression (article 17)
                  <br />
                  Restriction du traitement (article 18)
                  <br />
                  Opposition au traitement (article 21)
                  <br />
                  Portabilité des données (article 20)
                </p>
                <h4>
                  Droit de recours auprès de l’autorité de contrôle compétente
                </h4>
                <p>
                  En cas de violation du GDPR, les personnes concernées
                  disposent d’un droit de recours auprès d’une autorité de
                  contrôle, en particulier dans l’État membre de leur résidence
                  habituelle, de leur lieu de travail ou du lieu de la violation
                  présumée.
                </p>
              </div>
            ),
          },
          {
            title: 'Italiano',
            content: (
              <div className={classes.container}>
                <h4>
                  Dichiarazione di protezione sul trattamento dei dati personali
                  (Art. 13 GDPR)
                  <br />
                  Dati video dei test drive
                </h4>
                <p>
                  Prendiamo molto sul serio la protezione dei vostri dati
                  personali. Trattiamo i dati personali in conformità alle norme
                  di legge sulla protezione dei dati, come il Regolamento
                  generale sulla protezione dei dati (GDPR) e altre leggi e
                  regolamenti applicabili. La presente informativa sulla privacy
                  spiega quali dati raccogliamo e per cosa li utilizziamo.
                  Spiega inoltre come e per quale scopo ciò avviene.
                </p>
                <h4>Responsabile</h4>
                <p>
                  Neusoft Technology Solutions GmbH
                  <br />
                  Hugh-Greene-Weg 2-4
                  <br />
                  22529 Amburgo
                  <br />
                  E-mail:
                  {' '}
                  admin@de.neusoft.com
                  <br />
                  <a href="https://www.neusoft.de/">https://www.neusoft.de/</a>
                </p>
                <h4>
                  Dati di contatto del responsabile della protezione dei dati
                </h4>
                <p>
                  Michael Gruber
                  <br />
                  Responsabile esterno della protezione dei dati
                  <br />
                  BSP-SECURITY
                  <br />
                  Thundorferstr. 10
                  <br />
                  D-93047 Regensburg
                </p>
                <h4>Scopo del trattamento</h4>
                <p>
                  Utilizziamo i cosiddetti veicoli di prova per lo sviluppo, il
                  collaudo e la salvaguardia dei sistemi di assistenza alla
                  guida e di informazione alla guida. Nei veicoli di prova
                  appositamente contrassegnati, l’uso di sensori e tecnologie
                  video dà luogo a registrazioni di dati che riprendono anche
                  l’ambiente circostante. Le persone e le targhe che si trovano
                  nell’ambiente registrato vengono registrate in forma criptata
                  in una prima fase di elaborazione e rese irriconoscibili in
                  una seconda fase. I dati originali vengono quindi cancellati.
                  I dati anonimizzati vengono utilizzati per l’assicurazione
                  della qualità e la convalida delle soluzioni software per il
                  settore automobilistico.
                </p>
                <p>
                  In alcune circostanze, i cosiddetti responsabili del
                  trattamento degli ordini (Art. 28 GDPR) sono incaricati di
                  eseguire i viaggi. I contratti per l’elaborazione
                  commissionata vengono stipulati a questo scopo in conformità
                  con le disposizioni della legge sulla protezione dei dati.
                </p>
                <h4>Periodo di conservazione</h4>
                <p>
                  I dati personali originali raccolti durante i test drive
                  saranno cancellati immediatamente dopo la disidentificazione
                  descritta sopra.
                </p>
                <h4>Base giuridica del trattamento</h4>
                <p>Art. 6 para. 1f GDPR (legittimo interesse)</p>
                <h4>Destinatari dei dati</h4>
                <p>
                  Uffici interni. Nessun dato viene trasmesso a terzi non
                  autorizzati.
                </p>
                <h4>Elaborazione sicura</h4>
                <p>
                  Utilizziamo metodi di crittografia all’avanguardia per motivi
                  di sicurezza e per proteggere i dati riservati. Le nostre
                  misure tecniche e organizzative garantiscono il trattamento
                  più sicuro dei dati personali richiesto dal GDPR.
                </p>
                <h4>Trasferimento di dati a un paese terzo</h4>
                <p>Nessun dato personale viene trasferito in un paese terzo.</p>
                <h4>Profilazione</h4>
                <p>La profilazione ai sensi dell’Art. 22 GDPR non ha luogo.</p>
                <h4>Diritti degli interessati</h4>
                <p>
                  Gli interessati hanno i seguenti diritti ai sensi del GDPR:
                </p>
                <p>
                  Informazione (Art. 15)
                  <br />
                  Correzione (Art. 16)
                  <br />
                  Cancellazione (Art. 17)
                  <br />
                  Limitazione del trattamento (Art. 18)
                  <br />
                  Obiezione al trattamento (Art. 21)
                  <br />
                  Portabilità dei dati (Art. 20)
                </p>
                <h4>Diritto di ricorso all’autorità di controllo competente</h4>
                <p>
                  In caso di violazione del GDPR, gli interessati hanno il
                  diritto di ricorrere a un’autorità di controllo, in
                  particolare nello Stato membro in cui risiedono abitualmente,
                  lavorano o in cui si è verificata la presunta violazione.
                </p>
              </div>
            ),
          },
          {
            title: 'Nederlands',
            content: (
              <div className={classes.container}>
                <h4>
                  Gegevensbeschermingsverklaring over de verwerking van
                  persoonsgegevens (Art. 13 GDPR)
                  <br />
                  Videogegevens van testritten
                </h4>
                <p>
                  Wij nemen de bescherming van uw persoonsgegevens zeer serieus.
                  Wij behandelen persoonsgegevens in overeenstemming met de
                  wettelijke voorschriften inzake gegevensbescherming, zoals de
                  Algemene Verordening Gegevensbescherming (GDPR) en andere
                  toepasselijke wet- en regelgeving. In dit privacybeleid wordt
                  uitgelegd welke gegevens wij verzamelen en waarvoor wij deze
                  gebruiken. Ook wordt uitgelegd hoe en met welk doel dit
                  gebeurt.
                </p>
                <h4>Verantwoordelijke partij</h4>
                <p>
                  Neusoft Technology Solutions GmbH
                  <br />
                  Hugh-Greene-Weg 2-4
                  <br />
                  22529 Hamburg
                  <br />
                  E-mail:
                  {' '}
                  admin@de.neusoft.com
                  <br />
                  <a href="https://www.neusoft.de/">https://www.neusoft.de/</a>
                </p>
                <h4>
                  Contactgegevens van de functionaris voor gegevensbescherming
                </h4>
                <p>
                  Michael Gruber
                  <br />
                  Externe functionaris voor gegevensbescherming
                  <br />
                  BSP-SECURITY
                  <br />
                  Thundorferstr. 10
                  <br />
                  D-93047 Regensburg
                </p>
                <h4>Doel van de verwerking</h4>
                <p>
                  Wij gebruiken zogenaamde testvoertuigen voor het ontwikkelen,
                  testen en beveiligen van bestuurdersassistentie- en
                  bestuurdersinformatiesystemen. In de speciaal gemarkeerde
                  testvoertuigen leidt het gebruik van sensoren en
                  videotechnologie tot gegevensopnames die ook de omgeving
                  vastleggen. Personen en kentekens die zich in de opgenomen
                  omgeving bevinden, worden in een eerste verwerkingsstap in
                  gecodeerde vorm opgenomen en in een tweede stap onherkenbaar
                  gemaakt. Vervolgens worden de oorspronkelijke gegevens gewist.
                  De geanonimiseerde gegevens worden gebruikt voor
                  kwaliteitsborging en validering van softwareoplossingen voor
                  de automobielsector.
                </p>
                <p>
                  In sommige omstandigheden wordt aan zogenaamde
                  opdrachtverwerkers (Art. 28 GDPR) opdracht gegeven om de
                  ritten uit te voeren. Hiervoor worden contracten voor
                  opdrachtverwerking gesloten in overeenstemming met de
                  bepalingen van het gegevensbeschermingsrecht.
                </p>
                <h4>Opslagperiode</h4>
                <p>
                  De oorspronkelijke persoonsgegevens die tijdens de testritten
                  zijn verzameld, worden onmiddellijk na de hierboven beschreven
                  desidentificatie gewist.
                </p>
                <h4>Rechtsgrondslag van de verwerking</h4>
                <p>Art. 6 para. 1f GDPR (gerechtvaardigd belang)</p>
                <h4>Ontvangers van de gegevens</h4>
                <p>
                  Interne kantoren. Er worden geen gegevens doorgegeven aan
                  onbevoegde derden.
                </p>
                <h4>Beveiligde verwerking</h4>
                <p>
                  Wij gebruiken geavanceerde encryptiemethoden om
                  veiligheidsredenen en om vertrouwelijke gegevens te
                  beschermen. Onze technische en organisatorische maatregelen
                  garanderen de door de GDPR vereiste veiligere verwerking van
                  persoonsgegevens.
                </p>
                <h4>Overdracht van gegevens naar een derde land</h4>
                <p>
                  Er worden geen persoonsgegevens doorgegeven aan een derde
                  land.
                </p>
                <h4>Profilering</h4>
                <p>Profilering volgens Art. 22 GDPR vindt niet plaats.</p>
                <h4>Rechten van betrokkenen</h4>
                <p>Betrokkenen hebben de volgende rechten onder de GDPR:</p>
                <p>
                  Informatie (Art. 15)
                  <br />
                  Correctie (Art. 16)
                  <br />
                  Verwijdering (Art. 17)
                  <br />
                  Beperking van de verwerking (Art. 18)
                  <br />
                  Bezwaar tegen verwerking (Art. 21)
                  <br />
                  Gegevensportabiliteit (Art. 20)
                </p>
                <h4>
                  Recht van beroep bij de bevoe.gde toezichthoudende autoriteit
                </h4>
                <p>
                  In geval van inbreuken op de GDPR hebben betrokkenen het recht
                  in beroep te gaan bij een toezichthoudende autoriteit, met
                  name in de lidstaat van hun gewone verblijfplaats, hun
                  werkplek of de plaats van de vermeende inbreuk.
                </p>
              </div>
            ),
          },
          {
            title: 'Polska',
            content: (
              <div className={classes.container}>
                <h4>
                  Oświadczenie o ochronie danych dotyczące przetwarzania danych
                  osobowych (Art. 13 GDPR)
                  <br />
                  Dane wideo z jazd próbnych
                </h4>
                <p>
                  Ochronę Państwa danych osobowych traktujemy bardzo poważnie.
                  Dane osobowe traktujemy zgodnie z ustawowymi przepisami o
                  ochronie danych, takimi jak ogólne rozporządzenie o ochronie
                  danych (GDPR) oraz innymi obowiązującymi przepisami i
                  regulacjami. Niniejsza polityka prywatności wyjaśnia, jakie
                  dane gromadzimy i do czego je wykorzystujemy. Wyjaśnia
                  również, w jaki sposób i w jakim celu jest to realizowane.
                </p>
                <h4>Podmiot odpowiedzialny</h4>
                <p>
                  Neusoft Technology Solutions GmbH
                  <br />
                  Hugh-Greene-Weg 2-4
                  <br />
                  22529 Hamburg
                  <br />
                  E-mail:
                  {' '}
                  admin@de.neusoft.com
                  <br />
                  <a href="https://www.neusoft.de/">https://www.neusoft.de/</a>
                </p>
                <h4>Dane kontaktowe inspektora ochrony danych</h4>
                <p>
                  Michael Gruber
                  <br />
                  Zewnętrzny inspektor ochrony danych
                  <br />
                  BSP-SECURITY
                  <br />
                  Thundorferstr. 10
                  <br />
                  D-93047 Regensburg
                </p>
                <h4>Cel przetwarzania danych</h4>
                <p>
                  Używamy tzw. pojazdów testowych do rozwoju, testowania i
                  zabezpieczania systemów wspomagania kierowcy i informacji dla
                  kierowcy. W specjalnie oznakowanych pojazdach testowych,
                  dzięki zastosowaniu czujników i technologii wideo, dochodzi do
                  rejestracji danych, które obejmują również otoczenie. Osoby i
                  tablice rejestracyjne znajdujące się w zarejestrowanym
                  otoczeniu są w pierwszym etapie przetwarzania danych
                  zapisywane w formie zaszyfrowanej, a w drugim etapie stają się
                  nierozpoznawalne. Następnie oryginalne dane są usuwane.
                  Zanonimizowane dane są wykorzystywane do zapewniania jakości i
                  walidacji rozwiązań oprogramowania samochodowego.
                </p>
                <p>
                  W niektórych okolicznościach zlecane są tak zwane podmioty
                  przetwarzające zlecenia (Art. 28 GDPR) w celu realizacji
                  przejazdów. Umowy o zlecone przetwarzanie danych są w tym celu
                  zawierane zgodnie z przepisami prawa o ochronie danych.
                </p>
                <h4>Okres przechowywania</h4>
                <p>
                  Oryginalne dane osobowe zebrane podczas jazd testowych zostaną
                  usunięte natychmiast po opisanej powyżej dezidentyfikacji.
                </p>
                <h4>Podstawa prawna przetwarzania danych</h4>
                <p>Art. 6 para. 1f GDPR (uzasadniony interes)</p>
                <h4>Odbiorcy danych</h4>
                <p>
                  Biura wewnętrzne. Żadne dane nie są przekazywane
                  nieuprawnionym osobom trzecim.
                </p>
                <h4>Bezpieczne przetwarzanie danych</h4>
                <p>
                  Ze względów bezpieczeństwa i w celu ochrony poufnych danych
                  stosujemy najnowocześniejsze metody szyfrowania. Nasze środki
                  techniczne i organizacyjne gwarantują bezpieczniejsze
                  przetwarzanie danych osobowych wymagane przez GDPR.
                </p>
                <h4>Przekazywanie danych do państwa trzeciego</h4>
                <p>
                  Żadne dane osobowe nie są przekazywane do państwa trzeciego.
                </p>
                <h4>Profilowanie</h4>
                <p>Profilowanie zgodnie z Art. 22 GDPR nie ma miejsca.</p>
                <h4>Prawa osób, których dane dotyczą</h4>
                <p>
                  Osoby, których dane dotyczą, mają następujące prawa na mocy
                  GDPR:
                </p>
                <p>
                  Informacja (Art. 15)
                  <br />
                  Sprostowanie (Art. 16)
                  <br />
                  Usunięcie (Art. 17)
                  <br />
                  Ograniczenie przetwarzania (Art. 18)
                  <br />
                  Sprzeciw wobec przetwarzania (Art. 21)
                  <br />
                  Możliwość przenoszenia danych (Art. 20)
                </p>
                <h4>Prawo do odwołania się do właściwego organu nadzorczego</h4>
                <p>
                  W przypadku naruszenia GDPR osoby, których dane dotyczą, mają
                  prawo do odwołania się do organu nadzorczego, w szczególności
                  w państwie członkowskim ich zwykłego pobytu, miejsca pracy lub
                  miejsca domniemanego naruszenia.
                </p>
              </div>
            ),
          },
          {
            title: 'Portuguese',
            content: (
              <div className={classes.container}>
                <h4>
                  Declaração de protecção de dados sobre o tratamento de dados
                  pessoais (Art. 13.º do RGPD)
                  <br />
                  Dados de vídeo de test drives
                </h4>
                <p>
                  Levamos muito a sério a protecção dos seus dados pessoais.
                  Tratamos os dados pessoais de acordo com os regulamentos
                  legais de protecção de dados, como o Regulamento Geral de
                  Protecção de Dados (RGPD) e outras leis e regulamentos
                  aplicáveis. Esta política de privacidade explica quais os
                  dados que recolhemos e para que os utilizamos. Explica também
                  como e com que finalidade isso é feito.
                </p>
                <h4>Parte responsável</h4>
                <p>
                  Neusoft Technology Solutions GmbH
                  <br />
                  Hugh-Greene-Weg 2-4
                  <br />
                  22529 Hamburgo
                  <br />
                  Correio electrónico:
                  {' '}
                  admin@de.neusoft.com
                  <br />
                  <a href="https://www.neusoft.de/">https://www.neusoft.de/</a>
                </p>
                <h4>
                  Dati di contatto del responsabile della protezione dei dati
                </h4>
                <p>
                  Michael Gruber
                  <br />
                  Responsável externo pela protecção de dados
                  <br />
                  BSP-SECURITY
                  <br />
                  Thundorferstr. 10
                  <br />
                  D-93047 Regensburg
                </p>
                <h4>Objectivo do processamento</h4>
                <p>
                  Utilizamos os chamados veículos de teste para o
                  desenvolvimento, teste e protecção de sistemas de assistência
                  ao condutor e de informação ao condutor. Nos veículos de teste
                  especialmente marcados, a utilização de sensores e tecnologia
                  de vídeo resulta em gravações de dados que também captam o
                  ambiente circundante. As pessoas e as matrículas que se
                  encontram no ambiente gravado são registadas de forma
                  encriptada numa primeira fase de processamento e tornadas
                  irreconhecíveis numa segunda fase. Os dados originais são
                  depois eliminados. Os dados anonimizados são utilizados para
                  garantir a qualidade e a validação de soluções de software
                  para automóveis.
                </p>
                <p>
                  Em algumas circunstâncias, os chamados processadores de
                  pedidos (Art. 28 do RGPD) são contratados para realizar as
                  viagens. Para este efeito, são celebrados contratos de
                  processamento por encomenda, de acordo com as disposições da
                  lei de protecção de dados.
                </p>
                <h4>Período de armazenamento</h4>
                <p>
                  Os dados pessoais originais recolhidos durante as viagens de
                  teste serão eliminados imediatamente após a desidentificação
                  acima descrita.
                </p>
                <h4>Base jurídica do tratamento</h4>
                <p>Art. 6 para. 1f do RGPD (interesse legítimo)</p>
                <h4>Destinatários dos dados</h4>
                <p>
                  Escritórios internos. Nenhum dado é transmitido a terceiros
                  não autorizados.
                </p>
                <h4>Processamento seguro</h4>
                <p>
                  Utilizamos métodos de encriptação de última geração por razões
                  de segurança e para proteger dados confidenciais. As nossas
                  medidas técnicas e organizacionais garantem o tratamento mais
                  seguro dos dados pessoais exigido pelo RGPD.
                </p>
                <h4>Transferência de dados para um país terceiro</h4>
                <p>Nenhum dado pessoal é transferido para um país terceiro.</p>
                <h4>Definição de perfis</h4>
                <p>
                  A definição de perfis de acordo com o Art. 22 do RGPD não é
                  efectuada.
                </p>
                <h4>Direitos dos titulares dos dados</h4>
                <p>
                  Os titulares dos dados têm os seguintes direitos ao abrigo do
                  RGPD:
                </p>
                <p>
                  Informação (art. 15.º)
                  <br />
                  Correcção (art. 16.º)
                  <br />
                  Eliminação (art. 17.º)
                  <br />
                  Restrição do tratamento (art. 18.º)
                  <br />
                  Objecção ao tratamento (art. 21.º)
                  <br />
                  Portabilidade dos dados (art. 20.º)
                </p>
                <h4>
                  Direito de recurso para a autoridade de controlo competente
                </h4>
                <p>
                  Em caso de violação do RGPD, as pessoas em causa têm o direito
                  de recorrer a uma autoridade de controlo, em especial no
                  Estado-Membro da sua residência habitual, do seu local de
                  trabalho ou do local da alegada violação.
                </p>
              </div>
            ),
          },
          {
            title: 'Svenska',
            content: (
              <div className={classes.container}>
                <h4>
                  Dataskyddsförklaring om behandling av personuppgifter (artikel
                  13 i GDPR)
                  <br />
                  Videouppgifter från provkörningar
                </h4>
                <p>
                  Vi tar skyddet av dina personuppgifter på största allvar. Vi
                  behandlar personuppgifter i enlighet med de lagstadgade
                  dataskyddsbestämmelserna, såsom den allmänna
                  dataskyddsförordningen (GDPR) och andra tillämpliga lagar och
                  förordningar. Den här integritetspolicyn förklarar vilka
                  uppgifter vi samlar in och vad vi använder dem till. Den
                  förklarar också hur och i vilket syfte detta görs.
                </p>
                <h4>Ansvarig part</h4>
                <p>
                  Neusoft Technology Solutions GmbH
                  <br />
                  Hugh-Greene-Weg 2-4
                  <br />
                  22529 Hamburg
                  <br />
                  E-post:
                  {' '}
                  admin@de.neusoft.com
                  <br />
                  <a href="https://www.neusoft.de/">https://www.neusoft.de/</a>
                </p>
                <h4>Kontaktuppgifter till dataskyddsombudet</h4>
                <p>
                  Michael Gruber
                  <br />
                  Externt dataskyddsombud
                  <br />
                  BSP-SECURITY
                  <br />
                  Thundorferstr. 10
                  <br />
                  D-93047 Regensburg
                </p>
                <h4>Syfte med behandlingen</h4>
                <p>
                  Vi använder så kallade testfordon för utveckling, testning och
                  skydd av förarassistans- och förarinformationssystem. I de
                  särskilt märkta testfordonen resulterar användningen av
                  sensorer och videoteknik i dataregistreringar som även fångar
                  omgivningen. Personer och registreringsskyltar som befinner
                  sig i den inspelade miljön registreras i krypterad form i ett
                  första behandlingssteg och görs oigenkännliga i ett andra
                  steg. Därefter raderas de ursprungliga uppgifterna. De
                  anonymiserade uppgifterna används för kvalitetssäkring och
                  validering av mjukvarulösningar för bilar.
                </p>
                <p>
                  I vissa fall anlitas så kallade orderbehandlare (artikel 28 i
                  GDPR) för att utföra resorna. Avtal om uppdragsbearbetning
                  ingås för detta ändamål i enlighet med bestämmelserna i
                  dataskyddslagstiftningen.
                </p>
                <h4>Lagringsperiod</h4>
                <p>
                  De ursprungliga personuppgifter som samlas in under
                  testkörningarna raderas omedelbart efter den avidentifiering
                  som beskrivs ovan.
                </p>
                <h4>Rättslig grund för behandlingen</h4>
                <p>Art. 6 para. 1f GDPR (berättigat intresse)</p>
                <h4>Mottagare av uppgifterna</h4>
                <p>
                  Interna kontor. Inga uppgifter lämnas vidare till obehöriga
                  tredje parter.
                </p>
                <h4>Säker behandling</h4>
                <p>
                  Vi använder toppmoderna krypteringsmetoder av säkerhetsskäl
                  och för att skydda konfidentiella uppgifter. Våra tekniska och
                  organisatoriska åtgärder garanterar en säkrare behandling av
                  personuppgifter som krävs enligt GDPR.
                </p>
                <h4>Överföring av uppgifter till ett tredjeland</h4>
                <p>Inga personuppgifter överförs till ett tredje land.</p>
                <h4>Profilering</h4>
                <p>Profilering i enlighet med art. 22 GDPR äger inte rum.</p>
                <h4>De registrerades rättigheter</h4>
                <p>De registrerade har följande rättigheter enligt GDPR:</p>
                <p>
                  Information (artikel 15)
                  <br />
                  Rättelse (artikel 16)
                  <br />
                  Radering (artikel 17)
                  <br />
                  Begränsning av behandlingen (artikel 18)
                  <br />
                  Invändning mot behandling (artikel 21)
                  <br />
                  Uppgiftsportabilitet (artikel 20)
                </p>
                <h4>
                  Rätt att överklaga till den behöriga tillsynsmyndigheten
                </h4>
                <p>
                  Vid överträdelser av dataskyddsförordningen ska de
                  registrerade ha rätt att överklaga till en tillsynsmyndighet,
                  särskilt i den medlemsstat där de har sin vanliga vistelseort,
                  sin arbetsplats eller den plats där den påstådda överträdelsen
                  skett.
                </p>
              </div>
            ),
          },
        ]}
      />
      <RedirectToTop isGDPRPage />
      <UseScrollToTop scrollInstant />
    </div>
  );
};

export default GDPRDisclaimer;
