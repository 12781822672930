import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import downArrow from '../../assets/images/icons/header/feather-chevron-down.svg';
import upArrow from '../../assets/images/icons/header/feather-chevron-up.svg';
import { textColor, neusoftBlue, white } from '../../colors';

const useStyles = makeStyles(() => ({
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    '@media(max-width:67.5em)': {
      width: '100%',
      justifyContent: 'flex-end',
    },
    '@media(max-device-width:67.5em)': {
      width: '100%',
      justifyContent: 'flex-end',
    },
  },
  item: {
    textTransform: 'uppercase',
    margin: '0',
    position: 'relative',
    background: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    border: 'none',
    height: 'auto',
    cursor: 'pointer',
    fontStyle: 'normal',
    color: textColor,
    transition: 'all 0.5s ease',
    '@media(min-width:67.5em)': { width: '11.5rem' },
    '@media(min-width:160em)': {
      width: '12rem',
    },
  },
  hiddenMenu: {
    opacity: '0',
    visibility: 'hidden',
    position: 'absolute',
    minHeight: '8rem',
    zIndex: '2',
    backgroundColor: white,
    top: '99%',
    paddingTop: '0.7rem',
    left: '0',
    width: '100%',
    '@media(min-width:160em)': {
      minHeight: '9rem',
    },
    transition: 'all 0.5s ease',
  },
  fadeMenu: {
    opacity: '1',
    visibility: 'visible',
    transition: 'all 0.5s ease',
    position: 'absolute',
    minHeight: '8rem',
    zIndex: '2',
    backgroundColor: white,
    top: '99%',
    paddingTop: '0.7rem',
    left: '0',
    width: '100%',
    '@media(min-width:160em)': {
      minHeight: '9rem',
    },
  },
  menuLine: {
    width: '4.063rem',
    height: '0.188rem',
    margin: '0 auto',
    backgroundColor: neusoftBlue,
    '@media(max-width:67.5em)': {
      minHeight: '0.2rem',
      margin: '0',
      width: '5rem',
      marginTop: '0.8rem',
    },
    '@media(max-device-width:67.5em)': {
      minHeight: '0.2rem',
      margin: '0',
      width: '5rem',
      marginTop: '0.8rem',
    },
  },
  activeMenu: {
    opacity: '1',
    visibility: 'visible',
    transition: 'all 0.5s ease',
    position: 'absolute',
    height: 'auto',
    zIndex: '2',
    backgroundColor: white,
    top: '99%',
    paddingTop: '0.7rem',
    left: '0',
    width: '100%',
  },
  unactiveMenu: {
    opacity: '0',
    visibility: 'hidden',
    position: 'absolute',
    height: 'auto',
    zIndex: '2',
    backgroundColor: white,
    top: '99%',
    paddingTop: '0.7rem',
    left: '0',
    width: '100%',
    transition: 'all 0.5s ease',
  },
  menuList: {
    marginTop: '1.5rem',
    '@media(max-width:47.938em)': {
      margin: '0',
    },
    '@media(min-width:160em)': {
      marginTop: '2.5rem',
    },
    '@media(min-width:250em)': {
      marginTop: '2.8rem',
    },
    textAlign: 'center',
    width: 'auto',
  },
  menuItem: {
    width: '100%',
    padding: '0',
    '@media(max-width:67.5em)': { marginTop: '2rem', textAlign: 'right' },
    '@media(max-device-width:67.5em)': { marginTop: '2rem', textAlign: 'right' },
  },
  menuSubLink: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    cursor: 'pointer',
    color: textColor,
    fontWeight: 'normal',
    '& p': {
      margin: '1.2rem 0',
    },
  },
  mobileItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  mobileItemContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  mobileItemBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: '5rem',
    marginRight: '0.5rem',
  },
  mobileTitle: {
    textTransform: 'uppercase',
  },
  mobileMenuList: {
    marginRight: '14%',
    height: '0',
    overflow: 'hidden',
    transition: 'height 1s ease',
    '@media only screen and (max-device-width : 40.625em)':
    {
      marginRight: '18%',
    },
    '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em)':
    {
      marginRight: '15%',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      marginRight: '14%',
    },
  },
  activeMobileMenuList: {
    height: 'auto',
  },
  listButton: {
    height: '4rem',
    width: '15%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    padding: '0',
    '@media only screen and (max-device-width : 40.625em)':
    {
      width: '20%',
    },
    '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em)':
    {
      width: '16%',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      width: '15%',
    },
  },
  box: {
    display: 'block',
    width: '100%',
    '@media(max-width:67.5em)': { display: 'none' },
    '@media(max-device-width:67.5em)': { display: 'none' },
  },
  boxSecond: {
    display: 'none',
    width: '100%',
    '@media(max-width:67.5em)': { display: 'block' },
    '@media(max-device-width:67.5em)': { display: 'block' },
  },
}));

const HeaderItem = ({
  title,
  items,
  closeMobileView,
  mobileNav,
  status,
  ...props
}) => {
  const classes = useStyles(props);
  const [webListVisible, setWebListVisible] = useState(false);
  const [webFade, setWebFade] = useState(false);
  const [mobilelistVisible, setMobileListVisible] = useState(false);
  const lineRef = useRef(null);
  const listRef = useRef(null);
  const enterLinkHoverHandler = () => {
    setWebListVisible(true);
    setWebFade(true);
  };
  const leaveLinkHoverHandler = () => {
    setWebFade(false);
  };
  const openMobileSubLink = () => {
    listRef.current.style.height = 'auto';
    lineRef.current.style.transition = 'all 0.5s ease';
    lineRef.current.style.width = '100%';
    setMobileListVisible(true);
  };
  const closeMobileSubLink = () => {
    listRef.current.style.height = '0';
    lineRef.current.style.width = '5rem';
    setMobileListVisible(false);
  };
  useEffect(() => {
    if (!mobileNav) {
      setMobileListVisible(false);
    }
  }, [mobileNav]);

  return (
    <div className={classes.menuContainer}>
      <div className={classes.box}>
        <button
          type="button"
          id="button"
          onClick={enterLinkHoverHandler}
          onMouseEnter={enterLinkHoverHandler}
          onMouseLeave={leaveLinkHoverHandler}
          className={classes.item}
          onKeyPress={enterLinkHoverHandler}
        >
          <h5>{title}</h5>
          <div
            className={
              status === 'active' ? classes.activeMenu : classes.unactiveMenu
            }
          >
            <div className={classes.menuLine} />
          </div>
          {webListVisible && (
            <div className={webFade ? classes.fadeMenu : classes.hiddenMenu}>
              <div className={classes.menuLine} />
              <div className={classes.menuList}>
                {items.map((item) => (
                  <div className={classes.menuItem} key={item.text}>
                    <Link className={classes.menuSubLink} to={item.URL}>
                      <p>{item.text}</p>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          )}
        </button>
      </div>
      <div className={classes.boxSecond}>
        {mobileNav && (
          <div className={classes.mobileItem}>
            <div className={classes.mobileItemContainer}>
              <div className={classes.mobileItemBox}>
                <h5 className={classes.mobileTitle}>{title}</h5>
                <div className={classes.menuLine} ref={lineRef} />
              </div>
              {mobilelistVisible ? (
                <button
                  onClick={closeMobileSubLink}
                  className={classes.listButton}
                  type="button"
                >
                  <img src={upArrow} alt="Down" />
                </button>
              ) : (
                <button
                  onClick={openMobileSubLink}
                  className={classes.listButton}
                  type="button"
                >
                  <img src={downArrow} alt="Down" />
                </button>
              )}
            </div>
            <div ref={listRef} className={classes.mobileMenuList}>
              {items.map((item) => (
                <div className={classes.menuItem} key={item.text}>
                  <Link
                    onClick={closeMobileView}
                    className={classes.menuSubLink}
                    to={item.URL}
                  >
                    <p>{item.text}</p>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderItem;
