/* eslint-disable operator-linebreak */
export const WHO_WE_ARE_DESCRIPTION_PARAGRAPH1 =
  'is the European headquarter of the international Neusoft Corporation, which is one of the largest software companies in China. A wide range of software and hardware solutions, services around technologies for navigation, testing, infotainment systems, connectivity, electro mobility, V2X and autonomous driving applications are part of our broad portfolio.';
export const WHO_WE_ARE_PARAGRAPH2 =
  'OEMs are gaining product differentiation and achieving unique selling points with software solutions. The most relevant criteria in the automotive industry are service and software capabilities. With Neusoft Technology Solutions GmbH, customers have a reliable partner with proven expertise and target-oriented development of solutions for automotive embedded software at their side.';
export const WHO_WE_ARE_PARAGRAPH3 =
  'Neusoft Corporation, founded in 1991, is the first listed software company in China, and it has been committed to creating better life and promoting social development. Today, Neusoft has nearly 20,000 employees worldwide, and has established an R&D, sales and service network covering more than 60 cities in China, with subsidiaries in the U.S., Japan, Europe and other countries.';
export const WHO_WE_ARE_PARAGRAPH4 =
  'In the future, Neusoft will be more agile, innovative and customer-centric, using software to help individuals, businesses and many industries to respond and adapt to the hyper-connected era. It is committed to transforming people’s lives and the world by innovations beyond technology.';
export const ABOUT_US_DESCRIPTION_TITLE = 'Neusoft Technology Solutions GmbH';
export const NTS_NAME = 'Neusoft Technology Solutions';
export const WHO_WE_ARE_SECTION_SUBTITLE = 'Who We Are';
export const WHO_WE_ARE_DESCRIPTION_SUBTITLE = 'Neusoft';

export const VALUES_PARAGRAPH1 =
  'We at Neusoft Technology Solutions GmbH are committed to equal opportunity and we value diversity and inclusion within the workplace. Our corporate culture is based on mutual trust, fairness and respect; we value everyone as a person, regardless of background, role or function. Our company promotes a friendly and trust-based working environment, with an open and respectful discussion culture.';
export const VALUES_PARAGRAPH2 =
  'Our company recruits the most talented and we expect great accomplishments. We are strongest, when we work together, as a team or autonomously – every contribution matters! Our thirst for knowledge and willingness to constantly learn, allow us to grow as individuals and as a company. We work together as a global team to develop and deliver creative work and solutions.';
export const VALUES_PARAGRAPH3 =
  'Customer satisfaction is a measure of our performance. We carefully listen to our customers, in order to meet their expectations and requirements. Our team is committed to delivering results, overcoming hurdles and optimizing solutions. We treat our customers and suppliers the same, as we would like to be treated by them.';
export const VALUES_SECTION_SUBTITLE = 'Our Values';

export const GLOBAL_FOOTPRINT_DESCRIPTION_PARAGRAPH1 =
  'In the field of intelligent vehicles connectivity, Neusoft has 30 years of experience in serving global customers, focusing on the areas of navigation, intelligent connectivity and in-vehicle infotainment, autonomous driving, car sharing and electrification.';
export const GLOBAL_FOOTPRINT_PARAGRAPH2 =
  'We can offer local customer support with more than 4.000 experts worldwide and multiple R&D centers in Asia and Europe, which allows us to find a solution to almost all demands and needs of the automotive industry. ';
export const GLOBAL_FOOTPRINT_PARAGRAPH2_PART2 =
  'With our well-experienced worldwide development teams, we are well equipped to support our customers in regional and global programs alike.';
export const GLOBAL_FOOTPRINT_LOCATION = [
  {
    sign: '•',
    text: 'Hamburg',
  },
  {
    sign: '•',
    text: 'Munich',
  },
];
export const GLOBAL_FOOTPRINT_LOCATION_TITLE = 'Location';
export const GLOBAL_FOOTPRINT_HISTORY_TITLE = 'History';
export const GLOBAL_FOOTPRINT_TECHNOLOGY_TITLE =
  'Technology solutions & services provider';
export const GLOBAL_FOOTPRINT_HISTORY = [
  {
    sign: '•',
    text: 'Founded in 1987 as Innovative Systems GmbH',
  },
  {
    sign: '•',
    text: 'Acquired in 2000 by Harman',
  },
  {
    sign: '•',
    text: 'Since 2010 part of Neusoft Corporation',
  },
];
export const GLOBAL_FOOTPRINT_TECHNOLOGY = [
  {
    sign: '•',
    text: 'Automotive electronics & engineering services',
  },
  {
    sign: '•',
    text: 'Innovation center',
  },
  {
    sign: '•',
    text: 'Navigation & LBS CoC',
  },
  {
    sign: '•',
    text: 'Off-shore development bridge',
  },
  {
    sign: '•',
    text: 'Research & development',
  },
];
export const GLOBAL_FOOTPRINT_SECTION_SUBTITLE = 'Global Footprint';
export const GLOBAL_FOOTPRINT_DESCRIPTION_SUBTITLE =
  'Germany - Neusoft Technology Solutions GmbH';
export const GLOBAL_FOOTPRINT_DESCRIPTION_SUBTITLE_MOBILE_PART1 = 'Germany -';
export const GLOBAL_FOOTPRINT_DESCRIPTION_SUBTITLE_MOBILE_PART2 =
  'Neusoft Technology Solutions GmbH';
export const GLOBAL_FOOTPRINT_DESCRIPTION_TITLE =
  'Neusoft global footprint automotive';
