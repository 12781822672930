/* eslint-disable operator-linebreak */
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import logo from '../../assets/images/icons/header/neusoft-logo.svg';
import featherMenu from '../../assets/images/icons/header/feather-menu.svg';
import closeIcon from '../../assets/images/icons/header/feather-menu-close.svg';
import {
  ABOUT_US_ITEMS,
  OFFERING_ITEMS,
  CAREERS_ITEMS,
  ABOUT_US_TITLE,
  OFFERING_TITLE,
  CAREERS_TITLE,
  ABOUT_US_PATHS,
  OFFERING_PATHS,
  CAREERS_PATHS,
} from './items';
import HeaderItem from '../HeaderItem';
import { textColor, white, neusoftBlue } from '../../colors';

const useStyles = makeStyles(() => ({
  header: {
    width: '100%',
    position: 'fixed',
    top: '0',
    zIndex: '10',
    backgroundColor: white,
  },
  headerContainer: {
    display: 'flex',
    width: '87%',
    margin: '0 auto',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
    height: 'auto',
    '@media(max-width:67.5em)': {
      justifyContent: 'space-between',
      width: '90%',
    },
    '@media(max-device-width:67.5em)': {
      justifyContent: 'space-between',
      width: '90%',
    },
  },
  navContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '2rem 0',
    '@media(max-width:67.5em)': {
      display: 'none',
    },
    '@media(max-device-width:67.5em)': {
      display: 'none',
    },
  },
  mobileNavClose: {
    transform: 'translateX(100%)',
    position: 'absolute',
    zIndex: '9',
    minHeight: '98.2vh',
    display: 'none',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
    backgroundColor: white,
    overflowY: 'hidden',
    transition: 'all 0.5s ease',
    '@media(max-width:67.5em)': {
      display: 'flex',
    },
    '@media(max-device-width:67.5em)': {
      display: 'flex',
    },
  },
  mobileNavOpen: {
    animation: 'slideDownNav 2s',
    position: 'absolute',
    zIndex: '9',
    minHeight: '98.2vh',
    display: 'none',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
    backgroundColor: white,
    overflowY: 'hidden',
    transition: 'all 0.5s ease',
    '@media(max-width:67.5em)': {
      display: 'flex',
    },
    '@media(max-device-width:67.5em)': {
      display: 'flex',
    },
  },
  '@keyframes slideDownNav': {
    from: { transform: 'translateX(100%)' },
    to: { transform: 'translateX(0%)' },
  },
  mobileItem: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: '1rem',
    marginBottom: '0.5rem',
  },
  navButton: {
    height: 'auto',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    padding: '0',
    display: 'none',
    '@media(max-width:67.5em)': {
      display: 'block',
    },
    '@media(max-device-width:67.5em)': { display: 'block' },
  },
  closeIcon: {
    height: '2rem',
    width: '2rem',
    marginTop: '0.3rem',
  },
  logo: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '3%',
    margin: '2.9rem 0',
    '@media(max-width:67.5em)': { margin: '2.45rem 0' },
    '@media(min-width:67.5em)': { margin: '2rem 0' },
    '@media only screen and (max-device-width : 67.5em) and (orientation: landscape)':
    {
      margin: '2rem 0',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      margin: '1.5rem 0',
    },
    '@media(max-width:47.938em) and (orientation: portrait)': { margin: '2.45rem 0' },
  },
  img: {
    '@media(max-width:67.5em)': { height: '2rem' },
    '@media(max-device-width:67.5em)': { height: '2rem' },
    '@media(min-width:67.5em)': { height: '2rem' },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      height: '1.4rem',
    },
    '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em) and (orientation: portrait)':
    {
      height: '2.2rem',
    },
    '@media only screen and (max-device-width : 48em) and (orientation: portrait)':
    {
      height: '2.5rem',
    },
    '@media(max-device-width:40em)': { height: '1.4rem' },
    '@media(max-device-width:47.938em)': { height: '1.7rem' },
  },
  headerLink: {
    display: 'flex',
    margin: '0',
    height: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    textTransform: 'uppercase',
    cursor: 'pointer',
    fontStyle: 'normal',
    fontWeight: '500',
    color: textColor,
    '@media(max-width:67.5em)': {
      height: '5rem',
      flexDirection: 'column',
      alignItems: 'flex-end',
      width: '10rem',
      margin: '1rem 0',
      marginRight: '14%',
      padding: '0',
    },
    '@media(max-device-width:67.5em)': {
      flexDirection: 'column',
      alignItems: 'flex-end',
      width: '10rem',
      margin: '1rem 0',
      marginRight: '14%',
      height: '5rem',
      padding: '0',
    },
    '@media(min-width:67.5em)': { width: '11.5rem' },
    '@media(min-width:160em)': {
      width: '12rem',
    },
    '@media(max-device-width:40.625em)': {
      marginRight: '17%',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      marginRight: '14%',
      margin: '1.5rem',
    },
    '@media only screen and (max-device-height : 47em) and (min-device-width : 67.5em) and (orientation: landscape)':
    {
      marginRight: '0',
    },
  },
  menuLine: {
    width: '100%',
    minHeight: '0.188rem',
    backgroundColor: neusoftBlue,
  },
  line: {
    width: '5rem',
    minHeight: '0.188rem',
    backgroundColor: neusoftBlue,
    marginTop: '0.8rem',
  },
  burgerItem: {
    margin: '0.55rem 0',
    width: '2.7rem',
    height: '0.3rem',
    backgroundColor: textColor,
    pointerEvents: 'none',
    borderRadius: '5rem',
    transition: 'all 1s ease',
  },
  mobileList: {
    width: '100%',
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    overflowY: 'scroll',
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '@media(max-width:67.5em)': {
      marginRight: '0',
    },
    '@media(max-device-width:67.5em)': {
      marginRight: '0',
    },
  },
  linkMenuLine: {
    width: '4.063rem',
    height: '0.188rem',
    marginTop: '0.7rem',
    margin: '0 auto',
    position: 'absolute',
    backgroundColor: neusoftBlue,
    '@media(max-width:67.5em)': {
      minHeight: '0.2rem',
      margin: '0',
      width: '5rem',
      marginTop: '0.8rem',
    },
    '@media(max-device-width:67.5em)': {
      minHeight: '0.2rem',
      margin: '0',
      width: '5rem',
      marginTop: '0.8rem',
    },
  },
  activeMenu: {
    opacity: '1',
    visibility: 'visible',
    transition: 'all 0.5s ease',
    height: 'auto',
    zIndex: '2',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: white,
    width: '100%',
    position: 'relative',
  },
  unactiveMenu: {
    opacity: '0',
    visibility: 'visible',
    transition: 'all 0.5s ease',
    height: 'auto',
    zIndex: '2',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: white,
    width: '100%',
    position: 'relative',
  },
}));

const Header = ({ deviceSize, setHeaderRef }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [mobileNav, setMobileNav] = useState(false);
  const headerRef = useRef();
  const openMobileView = () => {
    setMobileNav(true);
    if (deviceSize.width > '1080') {
      document.body.style.overflowY = 'auto';
    } else {
      document.body.style.overflowY = 'hidden';
    }
  };
  const closeMobileView = () => {
    setMobileNav(false);
    document.body.style.overflowY = 'auto';
  };
  useEffect(() => {
    setHeaderRef(headerRef);
  }, [pathname.location]);

  return (
    <header id="header" className={classes.header} ref={headerRef}>
      <div className={classes.headerContainer}>
        <Link onClick={closeMobileView} to="/" className={classes.logo}>
          <img src={logo} alt="" className={classes.img} />
        </Link>
        {mobileNav ? (
          <button
            className={classes.navButton}
            type="button"
            onClick={closeMobileView}
          >
            <img className={classes.closeIcon} src={closeIcon} alt="" />
          </button>
        ) : (
          <button
            className={classes.navButton}
            type="button"
            onClick={openMobileView}
          >
            <img src={featherMenu} alt="" />
          </button>
        )}
        <div className={classes.navContainer}>
          <div>
            <HeaderItem
              title={ABOUT_US_TITLE}
              items={ABOUT_US_ITEMS}
              marginLeft="-1rem"
              status={ABOUT_US_PATHS.includes(pathname) ? 'active' : ''}
            />
          </div>
          <HeaderItem
            title={OFFERING_TITLE}
            items={OFFERING_ITEMS}
            marginLeft="-0.8rem"
            status={OFFERING_PATHS.includes(pathname) ? 'active' : ''}
          />
          <Link to="/solutions" className={classes.headerLink}>
            <h5>Solutions</h5>
            <div className={pathname === '/solutions' ? classes.activeMenu : classes.unactiveMenu}>
              <div className={classes.linkMenuLine} />
            </div>
          </Link>
          <HeaderItem
            title={CAREERS_TITLE}
            items={CAREERS_ITEMS}
            marginLeft="-0.8rem"
            status={CAREERS_PATHS.includes(pathname) ? 'active' : ''}
          />
          <Link to="/contacts" className={classes.headerLink}>
            <h5>Contacts</h5>
            <div className={pathname === '/contacts' ? classes.activeMenu : classes.unactiveMenu}>
              <div className={classes.linkMenuLine} />
            </div>
          </Link>
        </div>
      </div>
      <div className={mobileNav ? classes.mobileNavOpen : classes.mobileNavClose}>
        <div className={classes.menuLine} />
        <div id="mobile-nav" className={classes.mobileList}>
          <div className={classes.mobileItem}>
            <HeaderItem
              title={ABOUT_US_TITLE}
              items={ABOUT_US_ITEMS}
              closeMobileView={closeMobileView}
              mobileNav={mobileNav}
            />
          </div>
          <div className={classes.mobileItem}>
            <HeaderItem
              title={OFFERING_TITLE}
              items={OFFERING_ITEMS}
              closeMobileView={closeMobileView}
              mobileNav={mobileNav}
            />
          </div>
          {mobileNav && (
            <Link
              onClick={closeMobileView}
              to="/solutions"
              className={classes.headerLink}
            >
              <h5>Solutions</h5>
              <div className={classes.line} />
            </Link>
          )}
          <div className={classes.mobileItem}>
            <HeaderItem
              title={CAREERS_TITLE}
              items={CAREERS_ITEMS}
              closeMobileView={closeMobileView}
              mobileNav={mobileNav}
            />
          </div>
          {mobileNav && (
            <Link
              onClick={closeMobileView}
              to="/contacts"
              className={classes.headerLink}
              id="contacts"
            >
              <h5>Contacts</h5>
              <div className={classes.line} />
            </Link>
          )}
        </div>
      </div>

    </header>
  );
};

export default Header;
