import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { neusoftBlue, white, textColor } from '../../colors';

const useStyles = makeStyles(() => ({
  container: {
    height: 'auto',
    display: 'flex',
    order: '0',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '45%',
    '& h3': {
      marginTop: '6rem',
      marginBottom: '3rem',
      color: neusoftBlue,
      '@media(max-width:67.5em)': {
        fontSize: '2.2rem',
      },
      '@media(max-device-width:67.5em)': {
        fontSize: '2.2rem',
      },
      '@media(max-width:47.938em)': {
        fontSize: '1.35rem',
        marginTop: '4rem',
        marginBottom: '2rem',
      },
      '@media only screen and (max-device-height : 50em) and (orientation: landscape)':
      {
        fontSize: '2rem',
        marginTop: '3rem',
        marginBottom: '1rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.4rem',
        marginTop: '3rem',
        marginBottom: '1rem',
      },
    },
    '@media only screen and (max-width: 67.5em)': {
      width: '90%',
      margin: '0 auto',
      justifyContent: 'center',
    },
    '@media only screen and (max-device-width: 67.5em)': {
      width: '90%',
      margin: '0 auto',
      justifyContent: 'center',
    },
  },
  subcontainer: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& p': {
      fontSize: '1.625rem',
      '@media only screen and (max-device-width: 67.5em)': {
        fontSize: '1.8rem',
      },
      '@media(max-width:47.938em)': { fontSize: '1.3rem' },
      '@media only screen and (max-height : 40em) and (orientation: landscape)':
      {
        fontSize: '1.2rem',
      },
      '@media only screen and (max-device-height : 47em) and (min-device-width :67.5em) and (orientation: landscape)':
      {
        fontSize: '1.1rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.3rem',
      },
    },
  },
  subparagraph: {
    marginTop: '2rem',
  },
  homeButton: {
    cursor: 'pointer',
    width: '10.5rem',
    border: '2px solid',
    borderColor: textColor,
    color: textColor,
    padding: '1rem 0.2rem',
    background: 'transparent',
    fontWeight: 'bold',
    fontSize: '1.125rem',
    textTransform: 'uppercase',
    '@media(max-device-width:40.625em)': {
      width: '9.2rem',
      fontSize: '1.35rem',
      marginBottom: '0',
      padding: '0.6rem 0.2rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      width: '8.5rem',
    },
    '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em) and (orientation: portrait)':
    {
      width: '12.45rem',
      fontSize: '1.35rem',
      padding: '1.3rem 0.2rem',
    },
    '@media only screen and (max-device-width : 20em)': {
      padding: '0.5rem 0.2rem',
      width: '10.5rem',
    },
    '&:hover': {
      background: textColor,
      color: white,
    },
  },
  homeRedirect: {
    margin: '4rem 0',
    '@media only screen and (max-width: 50em)': {
      marginTop: '3rem',
      marginBottom: '2rem',
    },
    '@media only screen and (max-device-height : 50em) and (orientation: landscape)':
    {
      marginTop: '2rem',
      marginBottom: '2rem',
    },
  },
}));

const DescriptionComponent = ({
  title,
  paragraph,
  secondParagraph,
  thirdParagraph,
  URL,
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <h3>{title}</h3>
      <div className={classes.subcontainer}>
        <p className={classes.paragraph}>{paragraph}</p>
        <p className={classes.subparagraph}>{secondParagraph}</p>
        <p className={classes.subparagraph}>{thirdParagraph}</p>
      </div>
      <Link className={classes.homeRedirect} to={URL}>
        <button type="button" className={classes.homeButton}>
          More
        </button>
      </Link>
    </div>
  );
};

export default DescriptionComponent;
