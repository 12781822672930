import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import {
  bannerColor, neusoftBlue, white, textColor,
} from '../../colors';

const useStyles = makeStyles(() => ({
  workingSection: {
    width: '100%',
    height: 'auto',
    backgroundColor: bannerColor,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '6rem',
  },
  workingTitle: {
    height: '5rem',
    margin: '4rem 0',
    marginBottom: '6rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& h3': {
      color: neusoftBlue,
      textAlign: 'center',
      '@media(max-device-width:67.5em)': {
        fontSize: '2.2rem',
      },
      '@media(max-width:47.938em)': {
        fontSize: '1.4rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '2rem',
      },
    },
    '@media(max-width:70em)': {
      marginBottom: '6rem',
    },
    '@media(max-width:47.938em)': {
      marginBottom: '3rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      marginBottom: '4rem',
    },
  },
  sectionLine: {
    marginTop: '0.8rem',
    backgroundColor: neusoftBlue,
    width: '8rem',
    height: '0.25rem',
    zIndex: '1',
  },
  workingSectionContent: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    '@media(max-width:70em)': {
      flexDirection: 'column',
    },
  },
  workingSectionText: {
    order: '0',
    marginTop: '10rem',
    height: 'auto',
    width: '50%',
    '& h4': {
      '@media(min-width:48em) and (max-width:67.5em)': {
        fontSize: '1.8rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.18rem',
      },
      marginLeft: '13%',
      fontWeight: 'normal',
      width: '77%',
      '@media(max-width:85em)': {
        margin: '0 auto',
        width: '90%',
        textAlign: 'center',
      },
    },
    '@media(max-width:85em)': {
      marginTop: '2rem',
    },
    '@media(max-width:70em)': {
      order: '1',
      width: '100%',
      marginTop: '6rem',
    },
  },
  workingSectionImage: {
    order: '1',
    width: '50%',
    boxShadow: '0px 10px 20px #000000D1',
    '& img': {
      height: '100%',
      width: '100%',
      display: 'block',
    },
    '@media(max-width:70em)': {
      order: '0',
      width: '100%',
      boxShadow: 'none',
    },
  },
  workingSectionSubparagraph: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'transparent linear-gradient(90deg, #005BAC 0%, #00CCFF 100%) 0% 0% no-repeat padding-box',
    color: white,
    '& h4': {
      fontWeight: 'normal',
      width: '50%',
      margin: '0 auto',
      marginTop: '7rem',
      marginBottom: '3rem',
      textAlign: 'center',
      '@media(max-width:70em)': {
        width: '80%',
      },
      '@media(min-width:48em) and (max-width:67.5em)': {
        fontSize: '1.8rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.18rem',
      },
    },
  },
  workingSectionButton: {
    width: 'auto',
    height: 'auto',
    background: 'transparent',
    border: '2px solid #FFFFFF',
    color: white,
    fontWeight: 'Medium',
    cursor: 'pointer',
    marginTop: '3rem',
    marginBottom: '7rem',
    '&:hover': {
      background: white,
      color: neusoftBlue,
    },
    '& p': {
      margin: '1rem 1.5rem',
    },
  },
  careersJobsSection: {
    width: '87%',
    margin: '0 auto',
    marginTop: '5rem',
    height: 'auto',
    display: 'grid',
    '@media (max-width:47.938em)': {
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
    },
    '@media (min-width:40.625em) and (max-width:85em)': {
      gridTemplateColumns: 'repeat(2,1fr)',
      gridTemplateRows: 'repeat(auto-fill,18rem)',
      gridGap: '4rem 4rem',
    },
    '@media (min-width:85em)': {
      gridTemplateColumns: 'repeat(3,1fr)',
      gridTemplateRows: 'repeat(auto-fill,21.5rem)',
      gridGap: '5.875rem 5.438rem',
    },
    '@media (min-width:150em)': {
      gridTemplateColumns: 'repeat(4,1fr)',
      gridTemplateRows: 'repeat(auto-fill,21.5rem)',
      gridGap: '5.875rem 5.2rem',
    },
  },
  careersJobContainer: {
    width: '100%',
    height: '21rem',
    boxShadow: '0px 10px 20px #00002ECC',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& h4': {
      color: neusoftBlue,
      marginBottom: '1rem',
      textAlign: 'center',
      width: '80%',
    },
    '& p': {
      color: textColor,
      '@media(min-width:48em) and (max-width:67.5em)': {
        fontSize: '1.35rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.18rem',
      },
    },
    '@media (max-width:47.938em)': {
      marginBottom: '4rem',
    },
    '@media (min-width:40.625em) and (max-width:85em)': {
      height: '18rem',
    },
    '&:hover': {
      boxShadow: '0px 20px 30px #00002ECC',
    },
  },
}));

const WorkingSection = ({
  firstParagraphText,
  label,
  image,
  button,
  secondParagraphText,
  positions,
  setPositionId,
  ...props
}) => {
  const classes = useStyles(props);
  return (
    <>
      <div className={classes.workingSection}>
        {label && (
          <>
            <div className={classes.workingTitle}>
              <h3>{label}</h3>
              <div className={classes.sectionLine} />
            </div>
            <div className={classes.workingSectionContent}>
              <div className={classes.workingSectionText}><h4>{firstParagraphText}</h4></div>
              <div className={classes.workingSectionImage}><img src={image} alt="" /></div>
            </div>
          </>
        )}
        {!label && (
          <div className={classes.careersJobsSection}>
            {positions.map((position) => (
              <Link to={`/open-positions/${position.id}/${position.title.replaceAll(' ', '-')}`} onClick={() => setPositionId(position.id)} key={position.id}>
                <div className={classes.careersJobContainer}>
                  <h4>{position.title}</h4>
                  <p>{position.jobLocation}</p>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
      {button && (
        <>
          <div className={classes.workingSectionSubparagraph}>
            <h4>{secondParagraphText}</h4>
            <Link to="/open-positions">
              <button type="button" className={classes.workingSectionButton}><p>{button}</p></button>
            </Link>
          </div>
        </>
      )}

    </>
  );
};

export default WorkingSection;
