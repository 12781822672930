/* eslint-disable operator-linebreak */
export const CONNECTIVITY_TITLE = 'Connectivity';
export const CONNECTIVITY_SUBTITLE_FIRST = 'Experience';
export const CONNECTIVITY_SUBTITLE_SECOND = 'Focus areas';
export const CONNECTIVITY_SUBTITLE_THIRD = 'Reference cases';
export const CONNECTIVITY_SUBTITLE_FORTH =
  'Get in contact with one of our experts';

export const CONNECTIVITY_PARAGRAPH_FIRST =
  'Starting with the single function TCU with 3G telecommunication, Neusoft’s intelligent connectivity solution has been developed into a complete platform with high-integrated on-board telematics unit and the full backend platform. As one of the first 5G-TCU with C-V2X functions and backend solution. Neusoft’s intelligent connectivity platform is integrating more and more leading technology into one platform. Including 4G/5G, C-V2X, eCall, ERA Glonass, TCM, EMC, FOTA, Ethernet, CAN, Wi-Fi, Bluetooth and more.';

export const CONNECTIVITY_FOCUS_FIRST_TITLE = 'TCU';
export const CONNECTIVITY_FOCUS_SECOND_TITLE =
  'Intelligent Connectivity Backend';
export const CONNECTIVITY_FOCUS_THIRD_TITLE = 'Application';
export const CONNECTIVITY_FOCUS_FOURTH_TITLE = 'V2X';

export const CONNECTIVITY_FOCUS_FIRST_TEXT = [
  {
    sign: '•',
    text: 'T-Box HW & SW development + shark fin (integrated) <br/> 4G/5G',
  },
  {
    sign: '•',
    text: 'eCall (EU, ERA-GLONASS) backend-/ mobile device connection V2X (C-V2X / DRSC)',
  },
  {
    sign: '•',
    text: 'Remote diagnosis',
  },
  {
    sign: '•',
    text: 'Remote control',
  },
  {
    sign: '•',
    text: 'Find my car',
  },
  {
    sign: '•',
    text: 'Keyless entry',
  },
];
export const CONNECTIVITY_FOCUS_SECOND_TEXT = [
  {
    sign: '•',
    text: 'Cloud service platform <br/> incl. data, connectivity, monitoring, OTA, operation, account, CP/SP management and service  <br/>Connecting OEM system, third parties (e.g. dealer shop), network operators, users, TCU & management / operation)',
  },

  {
    sign: '•',
    text: 'Ecosystem for Chinese market (3rd party content-/service integration e.g. BAT)',
  },
];
export const CONNECTIVITY_FOCUS_THIRD_TEXT = [
  {
    sign: '•',
    text: 'Mobile application development (Android, iOS)',
  },
  {
    sign: '•',
    text: 'Embedded application development and integration (IVI Android, IVI Linux, IVI QNX)',
  },
  {
    sign: '•',
    text: ' Web application development',
  },
  {
    sign: '•',
    text: 'Mini-program subscription development (Mini-program, We-chat)',
  },
];
export const CONNECTIVITY_FOCUS_FOURTH_TEXT = [
  {
    sign: '•',
    text: 'HW-(OBU, RSU, TCU integration)',
  },
  {
    sign: '•',
    text: 'SW protocol stack-(VeTalk)',
  },
  {
    sign: '•',
    text: 'Compatibility test & certification service (VeTest)',
  },
  {
    sign: '•',
    text: 'Neusoft V2X data services (abstraction layer)',
  },
];

export const CONNECTIVITY_CASE_TITLE_FIRST = '5G T-Box, Smart Antenna & V2X';
export const CONNECTIVITY_CASE_TITLE_SECOND = 'RTM 4G: RTM+Antenna';
export const CONNECTIVITY_CASE_TITLE_THIRD = '4G T-Box';
export const CONNECTIVITY_CASE_TITLE_FOURTH = 'Cloud Platform';
export const CONNECTIVITY_CASE_TITLE_FIFTH = 'EV Mobile Application';

export const CONNECTIVITY_CASE_PATH_FIRST = '5G-T-Box';
export const CONNECTIVITY_CASE_PATH_SECOND = 'RTM-4G';
export const CONNECTIVITY_CASE_PATH_THIRD = '4G-T-Box';
export const CONNECTIVITY_CASE_PATH_FOURTH = 'Cloud-Platform';
export const CONNECTIVITY_CASE_PATH_FIFTH = 'EV-Mobile-Application';

export const CONNECTIVITY_CASE_SUBTITLE_FIRST = 'Chinese OEM';
export const CONNECTIVITY_CASE_SUBTITLE_SECOND = 'German OEM group';
export const CONNECTIVITY_CASE_SUBTITLE_THIRD = 'British OEM';
export const CONNECTIVITY_CASE_SUBTITLE_FOURTH =
  'Chinese OEM';
export const CONNECTIVITY_CASE_SUBTITLE_FIFTH = 'Chinese OEM';

export const CONNECTIVITY_POPUP_FIRST_CASE_TEXT = '<p>40+ engineers located in China build multiple T-Boxes including the V2X-Box version.</p><p><span>Features</span> – all in one design concept integrating: </p><ul><li>T-Box, shark fin, V2X, tuner, GPS, Wi-Fi</li><li>High precision positioning of sub meter level </li><li>Improved communication quality </li><li>Auxiliary warnings: FCW, BAW, LCW and ICW etc.</li></ul>';
export const CONNECTIVITY_POPUP_FIRST_CASE_FIRST_BOX_TEXT = 'T-Box 1: Shark fin + 4G BOX:  Global <br/>T-Box 2: Shark fin + 4G + V2X : China<br/>T-Box 3: Shark fin + 5G BOX: China<br/>';
export const CONNECTIVITY_POPUP_FIRST_CASE_SECOND_BOX_TEXT = '';
export const CONNECTIVITY_POPUP_FIRST_CASE_THIRD_BOX_TEXT = 'T-Box 1: Shark fin + 4G BOX: 2020.06 <br/>T-Box 2: Shark fin + 4G + V2X : 2021.09 <br/>T-Box 3: Shark fin + 5G BOX: 2022.06 <br/>';
export const CONNECTIVITY_POPUP_FIRST_CASE_FOURTH_BOX_TEXT = '4G + V2X module: Quactel AG35 (4G)+ AG15 (V2X)<br/>Shark fin+ 5G BOX: AG551<br/>3GPP R14 standard, high precision positioning, V2X Day 1 use cases, ethernet, multi-core all-IP communication';
export const CONNECTIVITY_POPUP_FIRST_CASE_FIFTH_BOX_TEXT = 'With the support of Neusoft the Chinese OEM succeeded to be the first car manufacturer in the world to bring 5G and V2X into mass production.';

export const CONNECTIVITY_POPUP_SECOND_CASE_TEXT = '<p>HW & SW development of a so called “Real Time Monitoring” module, for telecommunication between the electrical vehicles and the backend in order to upload/exchange diverse vehicle information to l the governmental regulations in Chinese market</p>';
export const CONNECTIVITY_POPUP_SECOND_CASE_FIRST_BOX_TEXT = 'China';
export const CONNECTIVITY_POPUP_SECOND_CASE_SECOND_BOX_TEXT = '';
export const CONNECTIVITY_POPUP_SECOND_CASE_THIRD_BOX_TEXT = '2018 – ongoing';
export const CONNECTIVITY_POPUP_SECOND_CASE_FOURTH_BOX_TEXT = 'Standard GB/T 32960.1-2016';
export const CONNECTIVITY_POPUP_SECOND_CASE_FIFTH_BOX_TEXT = 'Enable German OEM Group fulfill the Chinese national standards of electric vehicles';

export const CONNECTIVITY_POPUP_THIRD_CASE_TEXT = '<p>30+ Engineers located in China, built a 4G based T-Box for an European OEM fulfilling the global standard. </p>';
export const CONNECTIVITY_POPUP_THIRD_CASE_FIRST_BOX_TEXT = 'Global';
export const CONNECTIVITY_POPUP_THIRD_CASE_THIRD_BOX_TEXT = '2022.Q4';
export const CONNECTIVITY_POPUP_THIRD_CASE_FOURTH_BOX_TEXT = '4G module: Quactel AG35';
export const CONNECTIVITY_POPUP_THIRD_CASE_FIFTH_BOX_TEXT = 'Multiple variants with minimal HW/SW differences fulfill global emergency Call standards';

export const CONNECTIVITY_POPUP_FOURTH_CASE_TEXT = '<p>Neusoft engineers developed a platform solution for a Chinese OEM. This platform includes both front- and backend functions for vehicle remote control, vehicle status monitoring, content- (CP) / service provider (SP) services management and user account management. This platform makes the connected driving more safe and comfortable</p>';
export const CONNECTIVITY_POPUP_FOURTH_CASE_FIRST_BOX_TEXT = 'China';
export const CONNECTIVITY_POPUP_FOURTH_CASE_THIRD_BOX_TEXT = '2021';
export const CONNECTIVITY_POPUP_FOURTH_CASE_FOURTH_BOX_TEXT = 'Basic cloud management platform, user account management, data management platform, CP/SP management platform';
export const CONNECTIVITY_POPUP_FOURTH_CASE_FIFTH_BOX_TEXT = 'One platform integrating multiple platforms into one solution to cover all needed services from back- to front-end';

export const CONNECTIVITY_POPUP_FIFTH_CASE_TEXT = '<p>Neusoft engineers developed a mobile application for electric vehicle models for a Chinese OEM. The EV applications are based on real-time vehicle information and provide users with convenient and practical functions<span> such as</span>:</p><ul><li>Remote vehicle control</li><li>Remote charging management with different charging modes</li><li>Real-time vehicle positioning & tracking</li><li>Malfunction and maintenance reminde</li></ul>';
export const CONNECTIVITY_POPUP_FIFTH_CASE_FIRST_BOX_TEXT = 'China';
export const CONNECTIVITY_POPUP_FIFTH_CASE_THIRD_BOX_TEXT = '2021';
export const CONNECTIVITY_POPUP_FIFTH_CASE_FOURTH_BOX_TEXT = 'OS: Android, iOS';
export const CONNECTIVITY_POPUP_FIFTH_CASE_FIFTH_BOX_TEXT = 'Dedicated mobile application for electric vehicles, that provides a simple and convenient operation experience with practical and useful functions for both IOS and Android OS';

export const CONNECTIVITY_CASE_TEXT_FIRST =
  'First company to bring 5G and V2X in mass production with an all-in-one design concept integrating T-Box, shark fin, V2X, Turner, GPS, Wi-Fi and more';
export const CONNECTIVITY_CASE_TEXT_SECOND =
  'HW & SW development of a so called “Real Time Monitoring” module for electric vehicles for Chinese market';
export const CONNECTIVITY_CASE_TEXT_THIRD = '4G based T-Box for European customer fulfilling the global standard';
export const CONNECTIVITY_CASE_TEXT_FOURTH =
  'One platform integrating multiple platforms into one solution that grants the customer the possibility to cover all needed services from back- to front-end';
export const CONNECTIVITY_CASE_TEXT_FIFTH =
  'Mobile application for electric vehicles, that provides a simple and convenient operation experience with practical and useful functions';

export const CONNECTIVITY_EMPLOYEE_NAME = 'Mingzhe Sun';
export const CONNECTIVITY_EMPLOYEE_TITLE = 'Senior Business Developer / Expert for Connectivity & Cockpit';
export const CONNECTIVITY_EMPLOYEE_JOB_DESCRIPTION =
  '15 years experience in automotive infotainment and connectivity areas in a global environment';
export const NAVIGATION_EMPLOYEE_LINKEDIN_LINK = 'https://www.linkedin.com/in/mingzhe-sun-299102232/';
