export const CONTACT_TITLE = 'Let’s talk business!';
export const CONTACT_DESCRIPTION = 'Feel free to contact us for any questions!';
export const CONTACT_PHONE_NUMBER = '+49 40 30067-700';
export const CONTACT_EMAIL_ADDRESS = 'admin@de.neusoft.com';
export const CONTACT_MOTTO = 'If you have an idea or a more specific question, use the best solution for you.';
export const CONTACT_DETAIL = 'You can also find us at:';
export const CONTACT_HAMBURG_OFFICE_NAME = 'Hamburg Office';
export const CONTACT_HAMBURG_OFFICE_ADDRESS = 'Neusoft technology Solutions GmbH Hugh-Greene-Weg 2-4 22529 Hamburg – Germany';
export const CONTACT_MUNICH_OFFICE_NAME = 'Munich Office';
export const CONTACT_MUNICH_OFFICE_ADDRESS = 'Neusoft technology Solutions GmbH Stefan-George-Ring 2 81929 Munich – Germany';
