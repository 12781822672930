import { neusoftBlue, white, textColor } from '../../colors';
import ocgoImage from '../../assets/images/solutions/main-page/OCGO.jpg';
import ocgo from '../../assets/images/solutions/pop-ups/OCGO/OCGO-main.jpg';
import arImage from '../../assets/images/solutions/main-page/AR.jpg';
import ar from '../../assets/images/solutions/pop-ups/AR/AR-main.jpg';
import navImage from '../../assets/images/solutions/main-page/nav-adas.jpg';
import nav from '../../assets/images/solutions/pop-ups/NAV-ADAS/NAV-ADAS-main.jpg';
import tBoxImage from '../../assets/images/solutions/main-page/tbox.jpg';
import tBox from '../../assets/images/solutions/pop-ups/T-BOX/T-box-main.jpg';
import cloudImage from '../../assets/images/solutions/main-page/Cloud.jpg';
import cloud from '../../assets/images/solutions/pop-ups/Cloud/cloud-main.jpg';
import gisImage from '../../assets/images/solutions/main-page/GIS.jpg';
import gis from '../../assets/images/solutions/pop-ups/GIS/GIS-main.jpg';
import cockpitImage from '../../assets/images/solutions/main-page/ICS.jpg';
import cockpit from '../../assets/images/solutions/pop-ups/ICS/ICS-main.png';
import osmImage from '../../assets/images/solutions/main-page/OSM.jpg';
import osm from '../../assets/images/solutions/pop-ups/OSM/OSM-main.jpg';
import ocgoSwipe1 from '../../assets/images/solutions/pop-ups/OCGO/OCGO-slider1.jpg';
import ocgoSwipe2 from '../../assets/images/solutions/pop-ups/OCGO/OCGO-slider2.jpg';
import ocgoSwipe3 from '../../assets/images/solutions/pop-ups/OCGO/OCGO-slider3.jpg';
import ocgoSwipe4 from '../../assets/images/solutions/pop-ups/OCGO/OCGO-slider4.jpg';
import ocgoSwipe5 from '../../assets/images/solutions/pop-ups/OCGO/OCGO-slider5.jpg';
import ocgoSwipe6 from '../../assets/images/solutions/pop-ups/OCGO/OCGO-slider6.jpg';
import ocgoSwipe7 from '../../assets/images/solutions/pop-ups/OCGO/OCGO-slider7.jpg';
import arSwipe1 from '../../assets/images/solutions/pop-ups/AR/AR-1.jpg';
import arSwipe2 from '../../assets/images/solutions/pop-ups/AR/AR-2.jpg';
import arSwipe3 from '../../assets/images/solutions/pop-ups/AR/AR-3.jpg';
import arSwipe4 from '../../assets/images/solutions/pop-ups/AR/AR-4.jpg';
import arSwipe5 from '../../assets/images/solutions/pop-ups/AR/AR-5.jpg';
import arSwipe6 from '../../assets/images/solutions/pop-ups/AR/AR-6.jpg';
import arSwipe7 from '../../assets/images/solutions/pop-ups/AR/AR-7.jpg';
import arSwipe8 from '../../assets/images/solutions/pop-ups/AR/AR-8.jpg';
import arSwipe9 from '../../assets/images/solutions/pop-ups/AR/AR-9.jpg';
import arSwipe10 from '../../assets/images/solutions/pop-ups/AR/AR-10.jpg';
import arSwipe11 from '../../assets/images/solutions/pop-ups/AR/AR-11.jpg';
import arSwipe12 from '../../assets/images/solutions/pop-ups/AR/AR-12.jpg';
import tBoxSwipe1 from '../../assets/images/solutions/pop-ups/T-BOX/T-box-slider1.jpg';
import tBoxSwipe2 from '../../assets/images/solutions/pop-ups/T-BOX/T-box-slider2.jpg';
import tBoxSwipe3 from '../../assets/images/solutions/pop-ups/T-BOX/T-box-slider3.jpg';
import gisSwiper from '../../assets/images/solutions/pop-ups/GIS/swipeGIS.jpg';
import osmSwipe1 from '../../assets/images/solutions/pop-ups/OSM/OSM-slider1.jpg';
import osmSwipe2 from '../../assets/images/solutions/pop-ups/OSM/OSM-slider2.jpg';
import osmSwipe3 from '../../assets/images/solutions/pop-ups/OSM/OSM-slider3.jpg';
import osmSwipe4 from '../../assets/images/solutions/pop-ups/OSM/OSM-slider4.jpg';
import osmSwipe5 from '../../assets/images/solutions/pop-ups/OSM/OSM-slider5.jpg';

export const SOLUTIONS_TITLE = 'Solutions';

export const SOLUTIONS_OVERVIEW = 'Neusoft continuously monitors the Automotive space and is reacting on the emerging needs of our clients. As a result, Neusoft’s solutions are developed to meet our clients expectation and needs.';

export const OG_TITLE = 'OneCoreGo';
export const AR_TITLE = 'Augmented Reality';
export const OMS_TITLE = 'DB: OpenStreetMap';
export const NAV_TITLE = 'Nav-ADAS';
export const COCKPIT_TITLE = 'Intelligent Cockpit System';
export const T_BOX_TITLE = 'T-Box';
export const CLOUD_TITLE = 'Cloud Platform';
export const GIS_TITLE = 'GIS';

export const OG_SUBTITLE = 'OneCoreGo';
export const AR_SUBTITLE = 'Neusoft’s Augmented Reality solution';
export const OMS_SUBTITLE = 'DB: OSM';
export const NAV_SUBTITLE = 'Neusoft Nav-ADAS solution';
export const COCKPIT_SUBTITLE = 'Neusoft’s self-developed intelligent cockpit system designed with the dual OS concept, integrates infotainment HU and instrument cluster on one single hardware';
export const COCKPIT_SUBTITLE_MOBILE = 'Neusoft’s intelligent cockpit system is';
export const T_BOX_SUBTITLE = 'Neusoft’s T-box';
export const CLOUD_SUBTITLE = 'Neusoft’s intelligent backend platform connects vehicle and mobile devices with';
export const GIS_SUBTITLE = 'Data and scenario management for use cases as';

export const OG_PATH = '/solutions/OneCoreGo';
export const AR_PATH = '/solutions/Augmented-Reality-solution';
export const OMS_PATH = '/solutions/DB:OpenStreetMap';
export const NAV_PATH = '/solutions/Nav-ADAS';
export const COCKPIT_PATH = '/solutions/Intelligent-Cockpit-System';
export const T_BOX_PATH = '/solutions/T-Box';
export const CLOUD_PATH = '/solutions/Cloud-Platform-Solution';
export const GIS_PATH = '/solutions/GIS';

export const OG_SOLUTION_PATH = 'OneCoreGo';
export const AR_SOLUTION_PATH = 'Augmented-Reality-solution';
export const OMS_SOLUTION_PATH = 'DB:OpenStreetMap';
export const NAV_SOLUTION_PATH = 'Nav-ADAS';
export const COCKPIT_SOLUTION_PATH = 'Intelligent-Cockpit-System';
export const T_BOX_SOLUTION_PATH = 'T-Box';
export const CLOUD_SOLUTION_PATH = 'Cloud-Platform-Solution';
export const GIS_SOLUTION_PATH = 'GIS';

export const OCG_TEXT = [
  {
    sign: '•',
    text: 'Modifiable off the shelf solution',
    subtext: '- e.g. UI and feature set ',
  },
  {
    sign: '•',
    text: 'Connected solution',
    subtext: '- e.g. online DB, online routing, seamless navigation',
  },
  {
    sign: '•',
    text: 'Services',
    subtext: '- e.g. EV features, multimodal, parking',
  },

  {
    sign: '•',
    text: 'Worldwide database',
    subtext: '- e.g. NDS compilation',
  },
];
export const AR_TITLE_DESCRIPTION = 'Is an independent solution, designed for various purposes including';
export const AR_TEXT = [
  {
    sign: '•',
    text: 'Navigation',
    subtext:
      '- e.g. from AR driver mode to map display to speed limit',
  },
  {
    sign: '•',
    text: 'AR overlay',
    subtext:
      '- e.g. AR guidance from going straight, roundabouts to road mergers',
  },
  {
    sign: '•',
    text: 'Image recognition',
    subtext: '- e.g. lane shape/type detection, ADAS warnings',
  },
];

export const OSM_TEXT = [
  {
    sign: '•',
    text: 'Compilation',
    subtext:
      '- follows the NDS specification',
  },
  {
    sign: '•',
    text: 'Cost optimization',
    subtext:
      '- Open source raw data',
  },
  {
    sign: '•',
    text: 'Map',
    subtext:
      '- Worldwide coverage with high-detailed map',
  },
  {
    sign: '•',
    text: 'Layer',
    subtext:
      '- Neusoft’s own routing layer',
  },
];

export const NAV_ADAS_TEXT = [
  {
    sign: '•',
    text: 'eHorizon',
    subtext:
      '- foresee upcoming road information & conditions',
  },
  {
    sign: '•',
    text: 'High accurate positioning',
    subtext:
      '- ADASIS v2/v3',
  },
  {
    sign: '•',
    text: 'Intelligent speed assist',
    subtext:
      '- online & In-Car',
  },
];
export const COCKPIT_TEXT = [
  {
    text: 'Powered by most popular and latest high performance SOCs',
  },
  {
    text: 'Based on Neusoft’s hypervisor technology',
  },
  {
    text: 'Multiple interfaces to systems like ADAS, AVM, V2X and TCU',
  },
  {
    text: 'Supports 3rd party APPs integration',
  },
];

export const T_BOX_TITLE_DESCRIPTION = 'Is a next-generation communication domain controller including';
export const T_BOX_TEXT = [
  {
    text: 'Smart antenna',
  },
  {
    text: 'Vehicle diagnostic and control',
  },
  {
    text: 'Ethernet communication',
  },
  {
    text: '4G/5G, V2X, FOTA and more!',
  },

];

export const CLOUD_PLATFORM_TEXT = [
  {
    sign: '•',
    text: 'OEM vehicle service and connectivity cloud',

  },
  {
    sign: '•',
    text: 'Government and public cloud',

  },
  {
    sign: '•',
    text: '3rd party service and content provider cloud',
  },
];

export const GIS_TEXT = [
  {
    sign: '•',
    text: 'Traffic sign recognition',
  },
  {
    sign: '•',
    text: 'Road hazard detection',
  },
  {
    sign: '•',
    text: 'Speed limits (ISA)',
  },

  {
    sign: '•',
    text: 'Max. clearance height tunnels / bridge underpasses',
  },
];

export const OCG_DESCRIPTION_TEXT = 'Neusoft has a long history with over 30 years in navigation development and map compilation. Over last three decades, we served more than 100 SOPs for markets across the globe and up to 450 databases per year. Our global teams work with some of the world’s leading brands to bring modern navigation into the car. OneCoreGo combines our worldwide navigation core with the flexibility to modify the navigation HMI to customer needs.Parking, EV - features, online routing and many more services can be easily added by our cloud solution.';
export const OCG_LIST_TEXT = [
  {
    title: 'Modifiable-off-the-shelf solution',
    content: 'HMI and feature set are highly adaptable to customer needs',
  },
  {
    title: 'Operatable at',
    content: 'Any destination market, on a variety of different operating systems and hardware platforms',
  },
  {
    title: 'Seamless ',
    content: 'OneCoreGo is designed to run on different end user devices/HW in parallel',
  },
  {
    title: 'Hybrid navigation',
    content: 'Runs both online and offline',
  },
  {
    title: 'Database',
    content: 'Runs on any NDS database e.g. HERE, TomTom, OpenStreetMap, Zenrin and others',
  },
  {
    title: 'Multi-modal',
    content: 'Combination of different mobility concepts to support the user. It can calculate car, bike, pedestrian and public transportion routes',
  },
  {
    title: 'Connected',
    content: 'Our cloud solution brings modern services into the car e.g. EV features, parking, weather, online maps, routing and more',
  },
];
export const OCGO_SWIPER_TITLE = 'Features & services';
export const OCGO_SWIPER = [{
  image: ocgoSwipe1,
  text: 'Modifiable-Off-The-Shelf Solution',
},
{
  image: ocgoSwipe2,
  text: 'Seamless Navigation (From PC to Head Unit to Smartphone / Tablet or Bike)',
},
{
  image: ocgoSwipe3,
  text: 'Multi-modal Routing',
},
{
  image: ocgoSwipe4,
  text: 'World Navigation',
},
{
  image: ocgoSwipe5,
  text: 'Connected Services - Parking (Spots & Garages)',
},
{
  image: ocgoSwipe6,
  text: 'Connected Services - EV Routing',
},
{
  image: ocgoSwipe7,
  text: 'Connected Services - EV Routing',
}];

export const ICS_DESCRIPTION_TEXT = 'Neusoft’s self-developed intelligent cockpit system, designed with the concepts of soft power, high integration and intelligent connection, enables dual operating systems on one host and raises resource utilisation through the independently developed virtualisation technology and application. This system allows users to rapidly develop highly customized Android-based functions. It highly integrates software, hardware, systems, and ecosystems to improve user experiences and relies on core algorithms to intelligently reconstruct scenarios, thus providing more intuitive, safer, and more comfortable driving experiences for users.';
export const ICS_DESCRIPTION_PARAGRAPH = 'By integrating Neusoft’s intelligent network services, Big Data capabilities and diversified ecosystems, the intelligent cockpit system brings users healthy and personalized travel experiences with intelligent push, multi-terminal interaction, and health care.';
export const ICS_LIST_TEXT = [
  {
    item: 'Mainstream high-performance processor that supports multiple hardware platforms',
  },
  {
    item: 'Neusoft’s self-developed virtualization technology',
  },
  {
    item: 'Integrated functions like ADAS, AVM, V2X, and T-Box',
  },
  {
    item: 'Multi-modal interaction (intelligent voice, gestures, driver monitoring, etc.)',
  },
  {
    item: 'Multi-screen interaction',
  },
  {
    item: 'Cool HMI theme switching mode',
  },
  {
    item: 'Safe and quick start',
  },
  {
    item: 'Application store and third-party application management',
  },
  {
    item: 'Carplay / Mobile Link / Bluetooth / Wi-Fi',
  },
];

export const NAV_DESCRIPTION_TEXT = '<span>Neusoft Nav-ADAS: eHorizon, HD positioning and ISA</span><br/> Innovative solutions to support driver safety are key for future car lines.<br/> The demand for intelligent speed assist function is a must have not only for premium cars already today. Neusoft’s most probable path plus speed information supports these requirements in a high efficient way. We support ADASIS V2 and V3 with our ADAS solutions. Combined with a high accurate positioning module we are prepared to support your needs.';
export const NAV_LIST_TEXT = [
  {
    item: 'Most probable path to foresee upcoming road information and conditions',
  },
  {
    item: 'High accurate positioning',
  },
  {
    item: 'SD and HD map support',
  },
  {
    item: 'Intelligent speed assist - online & in-car ',
  },
  {
    item: 'Speed limits, curvature and pitch information available beyond sensor range to support the in-car control units most efficient',
  },
];
export const CLOUD_TITLE_SECOND = 'Neusoft’s Cloud Service Platfom';
export const CLOUD_DESCRIPTION_TEXT = '<span>Neusoft’s Cloud Service Platform</span> is an open ecological service platform that integrates the two hubs of <span>Content Ecology (CP)</span> and <span>Service Ecology (SP)</span>. By combining Neusoft AI and Big Data analytics, an account system is established that enables multi-terminal interactions and the development and connection of customized services.';
export const CLOUD_LIST_TEXT = [
  {
    item: 'Government & Public Cloud',
  },
  {
    item: 'Management, Operation and Maintenance ',
  },
  {
    item: 'Car Owners and Users ',
  },
  {
    item: 'Vehicles IVI and TCU',
  },
  {
    item: 'OEM System',
  },
  {
    item: 'Third Parties',
  },
  {
    item: 'Mobile Network Operators',
  },
];

export const CLOUD_SECOND_LIST_TEXT = [
  {
    item: '<span>Basic Data Service</span> – from Vehicle-, Terminal-, SIM card- to User Account- and Business Data Management',
  },
  {
    item: '<span>Connectivity Service</span> – from Real Time Data-, Remote Control-, Vehicle Diagnosis- to Stolen Tracking Service',
  },
  {
    item: '<span>Monitoring Management</span> – from Vehicle Alarm to Transmission',
  },
  {
    item: '<span>OTA Service</span> – from Update Package, SW Online Update, FOTA Update to Update Record',
  },
  {
    item: '<span>Bluetooth Key</span> – from basic Data Synchronization to Key Management',
  },
  {
    item: '<span>Operation Management Service</span> – from Data- & Shop- to Information and Aftes Sales Management',
  },
  {
    item: '<span>CP – SP Management</span> – from API / SDK input & output to Interface Management, Monitoring and Processing',
  },
  {
    item: '<span>Account System Management</span> – from Account Binding, Account Management to Multiple Channel Login',
  },
];

export const GIS_DESCRIPTION_TEXT = 'A considerable part of the functions and services for the vehicle and the driver will be provided in the cloud in the future. The provision of new services should be independent of the vehicle and the software installed in it, and should always be expandable so that it is possible to adapt to changing customer habits. Neusoft has developed a concept for collecting sensor data from the vehicle in the cloud, enriching the data with information from other sources and deriving information from it that can be made available to other customers as a service. The design of use cases is almost unlimited and enables completely different applications to be addressed; the recognition and registration of traffic signs, traffic management applications or the sharing of detected road hazards with other road users in the fleet are just first examples.';
export const GIS_SWIPER_TITLE = 'Services and use cases';
export const GIS_FIRST_LIST_TITLE = 'Objective';
export const GIS_FIRST_LIST_TEXT = [
  {
    item: 'Delivery of new features and services during vehicle lifetime',
  },
  {
    item: 'Minimizing dependencies on embedded software changes and vehicle programs',
  },
  {
    item: 'Enhance customer experience with best-of its kind product strategy (off-board integration)',
  },
  {
    item: 'Flexibility to efficiently respond to changing customer app preferences and B2B partnerships',
  },
  {
    item: 'Enable new or add-on feature subscriptions and payment models',
  },
  {
    item: 'Analysis of customer and vehicle trends (sensor data for service)',
  },
];
export const GIS_SECOND_LIST_TITLE = 'Features';
export const GIS_SECOND_LIST_TEXT = [
  {
    item: 'Data Collection',
  },
  {
    item: 'Data fusion: Aggregate geo-located information',
  },
  {
    item: 'Consolidation: Remove contradictory information',
  },
  {
    item: 'Correlation: Draw conclusions',
  },
  {
    item: 'Disseminate Information to clients',
  },
];

export const OSM_DESCRIPTION_TEXT = 'Neusoft has a long history with over 30 years in navigation development and map compilation. Our NDS compiler is built to process different raw data formats from almost all big data suppliers worldwide. We provide databases for all navigation relevant markets. OpenStreetMap data enhances our portfolio with a global NDS product. Open street map is frequently updated by the OSM community and comes with a high level of details. We ensure freshness of the data by frequent updates.';
export const OSM_LIST_SUBTITLE = 'Neusoft compiles OpenStreetMap including';
export const OSM_WARNING_TEXT = '*Not all legal aspects concerning NDS and OSM are clarified yet. Process ongoing';
export const OSM_LIST_TEXT = [
  {
    item: 'Compilation that follows the NDS specification',
  },
  {
    item: 'Lower costs due to open source raw data',
  },
  {
    item: 'Worldwide coverage',
  },
  {
    item: 'High-detailed map',
  },
  {
    item: 'Parking along the street',
  },
  {
    item: 'Neusoft’s own routing layer',
  },
  {
    item: 'Open parking spots',
  },
  {
    item: 'Online Traffic',
  },
];
export const OSM_SWIPER_TITLE = 'Neusoft’s OSM visualization';
export const OSM_SWIPER = [{
  image: osmSwipe1,
  text: 'World Coverage',
},
{
  image: osmSwipe2,
  text: 'High-detailed Map',
},
{
  image: osmSwipe3,
  text: 'Neusoft’s Own Routing Layer',
},
{
  image: osmSwipe4,
  text: 'Parking (along the street / parking spaces / open parking spots)',
},
{
  image: osmSwipe5,
  text: 'Online Traffic',
},
];

export const AR_DESCRIPTION_TEXT = 'Neusoft has developed the next general navigation model. Our AR solution increases <span>driving safety</span> by using the instrument cluster and HUD as target devices for AR navigation. Using camera images displayed by AR navigation helps drivers to <span>understand their surroundings more easily</span> as compared to using only 2D or 3D maps.The <span>direct guidance</span> reflected by the AR arrow takes you directly to the road you see. In addition, <span>ADAS-related warnings</span> are displayed along with the object, for example, a front collision warning appears with the outline of the vehicle or person ahead.';
export const AR_LIST_TEXT = [
  {
    title: 'Navigation',
    list: ['AR Driver mode', 'Basic guidance display', 'Remaining distance and time', 'Overview mode of map display', 'Lane information display', 'Current speed display', 'Speed limit'],
  },
  {

    title: 'AR overlay',
    list: ['AR guidance for going straight, turning, roundabouts, forks', 'Current lane detecting, display and departure warning', 'Car driving direction arrow display'],
  },
  {
    title: 'Image recognition',
    list: ['Current lane shape detecting', 'Current lane type detecting', 'ADAS warnings: FCW / BCW / LCW'],
  },
];
export const AR_SWIPER_TITLE = 'AR navigation forms & AR HUD 3.0 examples';
export const AR_SWIPER = [{
  image: arSwipe1,
  text: 'AR Navigation Forms',
},
{
  image: arSwipe2,
  text: 'Turn-by-Turn Guidance Tips p1: Turning',
},
{
  image: arSwipe3,
  text: 'Turn-by-Turn Guidance Tips p2: Roundabout',
},
{
  image: arSwipe4,
  text: 'Lane Departure Warning',
},
{
  image: arSwipe5,
  text: 'Collision Warning p1',
},
{
  image: arSwipe6,
  text: 'Collision Warning p2',
},
{
  image: arSwipe7,
  text: 'Merge Tips p1',
},
{
  image: arSwipe8,
  text: 'Merge Tips p2',
},
{
  image: arSwipe9,
  text: 'Fork Tips p1',
},
{
  image: arSwipe10,
  text: 'Fork Tips p2: Highway',
},
{
  image: arSwipe11,
  text: 'Highway Information Tips',
},
{
  image: arSwipe12,
  text: 'Destination Tips',
}];

export const T_BOX_SMALL_TEXT = 'Basing on the high speed, high bandwidth and low latency of 5G, Neusoft developed and launched the 5G V2X Box product integrated with the C-V2X and ETC functions, which has filled the gap in the domestic and overseas 5G markets.';
export const T_BOX_DESCRIPTION_TEXT = 'Neusoft’s T-Box is an intelligent in-vehicle interconnection terminal used to monitor new energy vehicles. As a safe and smart data channel with human-vehicle-road-life interconnection, Neusoft’s T-Box integrates T-Box, shark fin antenna, Ethernet, CAN, WIFI, and BLE to connect the network inside and outside a vehicle to create a safer and more comfortable driving environment for you. Neusoft began to develop T-Box products and participate in preparing the national standard GB/ T32960 in 2012. Since then, the products have soon opened up the market and successively won orders for dozens of vehicle models.';
export const T_BOX_FIRST_LIST_TEXT = [
  {
    item: 'Vehicle diagnostic and control',
  },
  {
    item: 'Over-the-Air (OTA) update',
  },
  {
    item: 'Bluetooth key',
  },
  {
    item: 'Security gateway',
  },
  {
    item: 'Ethernet communication',
  },
  {
    item: 'V2X customization',
  },
  {
    item: 'European eCall and Russian ERA-GLONASS',
  },
];
export const T_BOX_SECOND_LIST_TEXT = [
  {
    item: 'Present the next-generation communication domain controller solutions integrated with V2X, shark fin antenna, 5G, Ethernet, CAN, Wi-Fi, BLE, and Tuner',
  },
  {
    item: 'Focus on the communication technologies of V2X, security, FOTA, remote control, remote diagnosis, Bluetooth key, and Ethernet',
  },
  {
    item: 'Provide customized services for V2X usage scenarios ',
  },
  {
    item: 'Offer high-precision positioning and Dead Reckoning services',
  },
  {
    item: 'Integrate the ETC function',
  },
];
export const T_BOX_SWIPER_TITLE = 'Neusoft T-Box';
export const T_BOX_SWIPER = [{
  image: tBoxSwipe1,
  text: 'T-Box with Integrated Smart Antenna',
},
{
  image: tBoxSwipe2,
  text: 'T-Box',
},
{
  image: tBoxSwipe3,
  text: 'Real-Time-Monitoring Module',
},
];

export const allSolutionsPopUps = [{
  backgroundImage: ocgoImage,
  image: ocgo,
  pathName: OG_SOLUTION_PATH,
  title: OG_TITLE,
  paragraphTitle: 'Overview',
  paragraphText: OCG_DESCRIPTION_TEXT,
  sectionFirstTitle: OG_TITLE,
  sectionList: OCG_LIST_TEXT,
  sectionSecondTitle: '',
  sectionThirdTitle: '',
  swiperTitle: OCGO_SWIPER_TITLE,
  swiper: OCGO_SWIPER,
},
{
  backgroundImage: cockpitImage,
  image: cockpit,
  pathName: COCKPIT_SOLUTION_PATH,
  title: COCKPIT_TITLE,
  paragraphTitle: 'Overview',
  paragraphText: ICS_DESCRIPTION_TEXT,
  otherParagraph: ICS_DESCRIPTION_PARAGRAPH,
  sectionFirstTitle: '',
  list: ICS_LIST_TEXT,
  sectionSecondTitle: '',
  sectionThirdTitle: '',
  swiperTitle: '',
  swiper: '',
},
{
  backgroundImage: navImage,
  image: nav,
  pathName: NAV_SOLUTION_PATH,
  title: NAV_TITLE,
  paragraphTitle: 'Overview',
  paragraphText: NAV_DESCRIPTION_TEXT,
  sectionFirstTitle: NAV_TITLE,
  list: NAV_LIST_TEXT,
  sectionSecondTitle: '',
  sectionThirdTitle: '',
  swiperTitle: '',
  swiper: '',
},
{
  backgroundImage: cloudImage,
  image: cloud,
  pathName: CLOUD_SOLUTION_PATH,
  title: CLOUD_TITLE,
  paragraphTitle: 'Overview',
  paragraphText: CLOUD_DESCRIPTION_TEXT,
  sectionFirstTitle: CLOUD_TITLE_SECOND,
  sectionFirstSubtitle: 'Connects',
  sectionSecondSubtitle: 'Features',
  sectionThirdSubtitle: 'and More!',
  list: CLOUD_LIST_TEXT,
  secondList: CLOUD_SECOND_LIST_TEXT,
  sectionSecondTitle: '',
  sectionThirdTitle: '',
  swiperTitle: '',
  swiper: '',
},
{
  backgroundImage: gisImage,
  image: gis,
  pathName: GIS_SOLUTION_PATH,
  title: GIS_TITLE,
  paragraphTitle: 'Overview',
  paragraphText: GIS_DESCRIPTION_TEXT,
  sectionFirstTitle: GIS_FIRST_LIST_TITLE,
  list: GIS_FIRST_LIST_TEXT,
  sectionSecondTitle: GIS_SECOND_LIST_TITLE,
  secondList: GIS_SECOND_LIST_TEXT,
  sectionThirdTitle: '',
  swiperTitle: GIS_SWIPER_TITLE,
  swiperImage: gisSwiper,
  swiper: '',
},
{
  backgroundImage: osmImage,
  image: osm,
  pathName: OMS_SOLUTION_PATH,
  title: OMS_TITLE,
  paragraphTitle: 'Overview',
  paragraphText: OSM_DESCRIPTION_TEXT,
  sectionFirstTitle: 'DB: OSM',
  sectionFirstSubtitle: OSM_LIST_SUBTITLE,
  sectionSecondSubtitle: '',
  sectionThirdSubtitle: '',
  sectionList: '',
  list: OSM_LIST_TEXT,
  sectionSecondList: '',
  sectionSecondTitle: '',
  sectionThirdTitle: '',
  swiperTitle: OSM_SWIPER_TITLE,
  swiper: OSM_SWIPER,
},
{
  backgroundImage: arImage,
  image: ar,
  pathName: AR_SOLUTION_PATH,
  title: AR_TITLE,
  paragraphTitle: 'Overview',
  paragraphText: AR_DESCRIPTION_TEXT,
  sectionFirstTitle: 'Neusoft’s Augmented Reality solution',
  sectionList: AR_LIST_TEXT,
  sectionSecondTitle: '',
  sectionThirdTitle: '',
  swiperTitle: AR_SWIPER_TITLE,
  swiper: AR_SWIPER,
},
{
  backgroundImage: tBoxImage,
  image: tBox,
  pathName: T_BOX_SOLUTION_PATH,
  title: T_BOX_TITLE,
  paragraphTitle: 'Overview',
  paragraphText: T_BOX_DESCRIPTION_TEXT,
  sectionFirstTitle: 'Neusoft T-Box',
  smallText: T_BOX_SMALL_TEXT,
  list: T_BOX_FIRST_LIST_TEXT,
  secondList: T_BOX_SECOND_LIST_TEXT,
  sectionSecondTitle: 'Features',
  sectionThirdTitle: '',
  swiperTitle: T_BOX_SWIPER_TITLE,
  swiperSimple: T_BOX_SWIPER,
},
];

export const solutionsSwiperContent = [
  {
    title: OG_TITLE,
    subtitle: OG_SUBTITLE,
    items: OCG_TEXT,
    titleDescription: '',
    URL: OG_PATH,
    image: ocgoImage,
    displayWrapper: 'flex',
    alignItemsWrapper: 'center',
    justifyContentWrapper: 'space-between',
    order: '0',
    height: '80%',
    justifyContent: 'flex-start',
    color: textColor,
    hoverBackgroundColor: textColor,
    titleColor: neusoftBlue,
    hoverTitleColor: white,
    alignItems: 'center',
    alignSubtitle: 'center',
    background: 'transparent',
    width: '100%',
  },
  {
    title: AR_TITLE,
    subtitle: AR_SUBTITLE,
    items: AR_TEXT,
    titleDescription: AR_TITLE_DESCRIPTION,
    URL: AR_PATH,
    image: arImage,
    displayWrapper: 'flex',
    alignItemsWrapper: 'center',
    justifyContentWrapper: 'space-between',
    order: '0',
    height: '80%',
    justifyContent: 'flex-start',
    color: white,
    hoverBackgroundColor: white,
    titleColor: white,
    hoverTitleColor: neusoftBlue,
    alignItems: 'center',
    background:
      'transparent linear-gradient(91deg, #005BAC 0%, #00CCFF 100%) 0% 0% no-repeat padding-box',
  },
  {
    title: OMS_TITLE,
    subtitle: OMS_SUBTITLE,
    items: OSM_TEXT,
    titleDescription: '',
    URL: OMS_PATH,
    image: osmImage,
    displayWrapper: 'flex',
    alignItemsWrapper: 'center',
    justifyContentWrapper: 'space-between',
    order: '0',
    height: '80%',
    justifyContent: 'flex-start',
    color: textColor,
    hoverBackgroundColor: textColor,
    titleColor: neusoftBlue,
    hoverTitleColor: white,
    alignItems: 'center',
    alignSubtitle: 'center',
    background: 'transparent',
    width: '100%',
  },
  {
    title: NAV_TITLE,
    subtitle: NAV_SUBTITLE,
    items: NAV_ADAS_TEXT,
    titleDescription: '',
    URL: NAV_PATH,
    image: navImage,
    displayWrapper: 'flex',
    alignItemsWrapper: 'center',
    justifyContentWrapper: 'space-between',
    order: '0',
    height: '80%',
    justifyContent: 'flex-start',
    color: white,
    hoverBackgroundColor: white,
    titleColor: white,
    hoverTitleColor: neusoftBlue,
    alignItems: 'center',
    background:
      'transparent linear-gradient(91deg, #005BAC 0%, #00CCFF 100%) 0% 0% no-repeat padding-box',
  },
  {
    title: COCKPIT_TITLE,
    subtitle: COCKPIT_SUBTITLE_MOBILE,
    items: COCKPIT_TEXT,
    titleDescription: '',
    URL: COCKPIT_PATH,
    image: cockpitImage,
    displayWrapper: 'flex',
    alignItemsWrapper: 'center',
    justifyContentWrapper: 'space-between',
    paddingRight: '5rem',
    order: '0',
    height: '80%',
    justifyContent: 'flex-start',
    color: textColor,
    hoverBackgroundColor: textColor,
    titleColor: neusoftBlue,
    hoverTitleColor: white,
    alignItems: 'center',
    alignSubtitle: 'center',
    background: 'transparent',
    width: '100%',
  },
  {
    title: T_BOX_TITLE,
    subtitle: T_BOX_SUBTITLE,
    items: T_BOX_TEXT,
    titleDescription: T_BOX_TITLE_DESCRIPTION,
    URL: T_BOX_PATH,
    image: tBoxImage,
    displayWrapper: 'flex',
    alignItemsWrapper: 'center',
    justifyContentWrapper: 'space-between',
    order: '0',
    height: '80%',
    justifyContent: 'flex-start',
    color: white,
    hoverBackgroundColor: white,
    titleColor: white,
    hoverTitleColor: neusoftBlue,
    alignItems: 'center',
    background:
      'transparent linear-gradient(91deg, #005BAC 0%, #00CCFF 100%) 0% 0% no-repeat padding-box',
  },
  {
    title: CLOUD_TITLE,
    subtitle: CLOUD_SUBTITLE,
    items: CLOUD_PLATFORM_TEXT,
    titleDescription: '',
    URL: CLOUD_PATH,
    image: cloudImage,
    displayWrapper: 'flex',
    alignItemsWrapper: 'center',
    justifyContentWrapper: 'space-between',
    order: '0',
    height: '80%',
    justifyContent: 'flex-start',
    color: textColor,
    hoverBackgroundColor: textColor,
    titleColor: neusoftBlue,
    hoverTitleColor: white,
    alignItems: 'center',
    alignSubtitle: 'center',
    background: 'transparent',
    width: '100%',
  },
  {
    title: GIS_TITLE,
    subtitle: GIS_SUBTITLE,
    items: GIS_TEXT,
    titleDescription: '',
    URL: GIS_PATH,
    image: gisImage,
    displayWrapper: 'flex',
    alignItemsWrapper: 'center',
    justifyContentWrapper: 'space-between',
    order: '0',
    height: '80%',
    justifyContent: 'flex-start',
    color: white,
    hoverBackgroundColor: white,
    titleColor: white,
    hoverTitleColor: neusoftBlue,
    alignItems: 'center',
    background:
      'transparent linear-gradient(91deg, #005BAC 0%, #00CCFF 100%) 0% 0% no-repeat padding-box',
  },
];

export const solutionsContent = [
  {
    title: OG_TITLE,
    subtitle: OG_SUBTITLE,
    items: OCG_TEXT,
    titleDescription: '',
    URL: OG_PATH,
    image: ocgoImage,
    displayWrapper: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '44% 10% 1fr',
    gridRowStartTitle: '1',
    gridColumnStartTitle: '1',
    gridColumnEndTitle: '2',
    gridRowStartSubtitle: '1',
    gridColumnStartSubtitle: '3',
    gridColumnEndSubtitle: '4',
    paddingRight: '5rem',
    order: '0',
    height: '55%',
    justifyContent: 'center',
    color: textColor,
    hoverBackgroundColor: textColor,
    titleColor: neusoftBlue,
    hoverTitleColor: white,
    alignItems: 'flex-start',
    alignSubtitle: 'flex-end',
    marginRight: '0',
    marginRightTitle: '0',
    marginLeftTitle: '1.875rem',
    paddingLeft: '0',
    background: 'transparent',
    justifyContentWrapper: 'center',
  },
  {
    title: AR_TITLE,
    subtitle: AR_SUBTITLE,
    items: AR_TEXT,
    titleDescription: AR_TITLE_DESCRIPTION,
    URL: AR_PATH,
    image: arImage,
    displayWrapper: 'grid',
    justifyContentWrapper: 'space-between',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 4% 44%',
    gridRowStartTitle: '1',
    gridColumnStartTitle: '3',
    gridColumnEndTitle: '4',
    gridRowStartSubtitle: '1',
    gridColumnStartSubtitle: '1',
    gridColumnEndSubtitle: '2',
    paddingRight: '0',
    order: '-1',
    height: '45%',
    justifyContent: 'center',
    color: white,
    hoverBackgroundColor: white,
    titleColor: white,
    hoverTitleColor: neusoftBlue,
    alignItems: 'flex-end',
    marginRightTitle: '1.875rem',
    marginLeftTitle: '0',
    marginLeft: '0',
    paddingLeft: '5rem',
    background:
      'transparent linear-gradient(91deg, #005BAC 0%, #00CCFF 100%) 0% 0% no-repeat padding-box',

  },
  {
    title: OMS_TITLE,
    subtitle: OMS_SUBTITLE,
    items: OSM_TEXT,
    titleDescription: '',
    URL: OMS_PATH,
    image: osmImage,
    displayWrapper: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '44% 10% 1fr',
    gridRowStartTitle: '1',
    gridColumnStartTitle: '1',
    gridColumnEndTitle: '2',
    gridRowStartSubtitle: '1',
    gridColumnStartSubtitle: '3',
    gridColumnEndSubtitle: '4',
    paddingRight: '5rem',
    order: '0',
    height: '60%',
    justifyContent: 'center',
    color: textColor,
    hoverBackgroundColor: textColor,
    titleColor: neusoftBlue,
    hoverTitleColor: white,
    alignItems: 'flex-start',
    alignSubtitle: 'flex-end',
    marginRight: '0',
    marginRightTitle: '0',
    marginLeftTitle: '1.875rem',
    paddingLeft: '0',
    background: 'transparent',
    justifyContentWrapper: 'center',
  },
  {
    title: NAV_TITLE,
    subtitle: NAV_SUBTITLE,
    items: NAV_ADAS_TEXT,
    titleDescription: '',
    URL: NAV_PATH,
    image: navImage,
    displayWrapper: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 4% 44%',
    gridRowStartTitle: '1',
    gridColumnStartTitle: '3',
    gridColumnEndTitle: '4',
    gridRowStartSubtitle: '1',
    gridColumnStartSubtitle: '1',
    gridColumnEndSubtitle: '2',
    justifyContentWrapper: 'space-between',
    paddingRight: '0',
    order: '-1',
    height: '58%',
    justifyContent: 'center',
    color: white,
    hoverBackgroundColor: white,
    titleColor: white,
    hoverTitleColor: neusoftBlue,
    alignItems: 'flex-end',
    marginRightTitle: '1.875rem',
    marginLeftTitle: '0',
    marginLeft: '0',
    paddingLeft: '5rem',
    background:
      'transparent linear-gradient(91deg, #005BAC 0%, #00CCFF 100%) 0% 0% no-repeat padding-box',
  },
  {
    title: COCKPIT_TITLE,
    subtitle: COCKPIT_SUBTITLE,
    items: COCKPIT_TEXT,
    titleDescription: '',
    URL: COCKPIT_PATH,
    image: cockpitImage,
    displayWrapper: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '44% 10% 1fr',
    gridRowStartTitle: '1',
    gridColumnStartTitle: '1',
    gridColumnEndTitle: '2',
    gridRowStartSubtitle: '1',
    gridColumnStartSubtitle: '3',
    gridColumnEndSubtitle: '4',
    paddingRight: '5rem',
    order: '0',
    height: '55%',
    justifyContent: 'center',
    color: textColor,
    hoverBackgroundColor: textColor,
    titleColor: neusoftBlue,
    hoverTitleColor: white,
    alignItems: 'flex-start',
    alignSubtitle: 'flex-end',
    marginRight: '0',
    marginRightTitle: '0',
    marginLeftTitle: '1.875rem',
    paddingLeft: '0',
    background: 'transparent',
    justifyContentWrapper: 'center',
  },
  {
    title: T_BOX_TITLE,
    subtitle: T_BOX_SUBTITLE,
    items: T_BOX_TEXT,
    titleDescription: T_BOX_TITLE_DESCRIPTION,
    URL: T_BOX_PATH,
    image: tBoxImage,
    displayWrapper: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 4% 44%',
    gridRowStartTitle: '1',
    gridColumnStartTitle: '3',
    gridColumnEndTitle: '4',
    gridRowStartSubtitle: '1',
    gridColumnStartSubtitle: '1',
    gridColumnEndSubtitle: '2',
    justifyContentWrapper: 'space-between',
    paddingRight: '0',
    order: '-1',
    height: '45%',
    justifyContent: 'center',
    color: white,
    hoverBackgroundColor: white,
    titleColor: white,
    hoverTitleColor: neusoftBlue,
    alignItems: 'flex-end',
    marginRightTitle: '1.875rem',
    marginLeftTitle: '0',
    marginLeft: '0',
    paddingLeft: '5rem',
    background:
      'transparent linear-gradient(91deg, #005BAC 0%, #00CCFF 100%) 0% 0% no-repeat padding-box',
  },
  {
    title: CLOUD_TITLE,
    subtitle: CLOUD_SUBTITLE,
    items: CLOUD_PLATFORM_TEXT,
    titleDescription: '',
    URL: CLOUD_PATH,
    image: cloudImage,
    displayWrapper: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '44% 10% 1fr',
    gridRowStartTitle: '1',
    gridColumnStartTitle: '1',
    gridColumnEndTitle: '2',
    gridRowStartSubtitle: '1',
    gridColumnStartSubtitle: '3',
    gridColumnEndSubtitle: '4',
    paddingRight: '5rem',
    order: '0',
    height: '60%',
    justifyContent: 'center',
    color: textColor,
    hoverBackgroundColor: textColor,
    titleColor: neusoftBlue,
    hoverTitleColor: white,
    alignItems: 'flex-start',
    alignSubtitle: 'flex-end',
    marginRight: '0',
    marginRightTitle: '0',
    marginLeftTitle: '1.875rem',
    paddingLeft: '0',
    background: 'transparent',
    justifyContentWrapper: 'center',
  },
  {
    title: GIS_TITLE,
    subtitle: GIS_SUBTITLE,
    items: GIS_TEXT,
    titleDescription: '',
    URL: GIS_PATH,
    image: gisImage,
    displayWrapper: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 4% 44%',
    gridRowStartTitle: '1',
    gridColumnStartTitle: '3',
    gridColumnEndTitle: '4',
    gridRowStartSubtitle: '1',
    gridColumnStartSubtitle: '1',
    gridColumnEndSubtitle: '2',
    justifyContentWrapper: 'space-between',
    paddingRight: '0',
    order: '-1',
    height: '58%',
    justifyContent: 'center',
    color: white,
    hoverBackgroundColor: white,
    titleColor: white,
    hoverTitleColor: neusoftBlue,
    alignItems: 'flex-end',
    marginRightTitle: '1.875rem',
    marginLeftTitle: '0',
    marginLeft: '0',
    paddingLeft: '5rem',
    background:
      'transparent linear-gradient(91deg, #005BAC 0%, #00CCFF 100%) 0% 0% no-repeat padding-box',
  },
];
