/* eslint-disable consistent-return */
/* eslint-disable react/self-closing-comp */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { neusoftBlue } from '../../colors';

const useStyles = makeStyles(() => ({
  bannerContainer: {
    height: (props) => props.minHeight,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    background: (props) => props.bannerColor,
    '@media only screen and (max-width : 67.5em)': {
      height: (props) => props.heightTablet,
    },
    '@media only screen and (max-device-width : 67.5em)': {
      height: (props) => props.heightTablet,
    },
    '@media only screen and (max-device-height : 52.563em) and (orientation: landscape)':
    {
      height: (props) => props.heightTablet,
    },
    '@media only screen and (min-width : 140em) ': {
      height: (props) => props.heightBannerLarge,
    },
  },
  bannerImageContainer: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover',
  },
  bannerContainerTitle: {
    textAlign: 'center',
    color: neusoftBlue,
    margin: '0',
    '@media only screen and (max-width : 67.5em)': {
      fontSize: '2.2rem',
      width: '90%',
    },
    '@media only screen and (max-device-width : 67.5em)': {
      fontSize: '2.2rem',
      width: '90%',
    },
    '@media only screen and (max-width : 48em)': {
      fontSize: '2.5rem',
    },
    '@media only screen and (max-width : 40.625em)': {
      fontSize: '1.98rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      fontSize: '1.8rem',
    },
  },
  bannerText: {
    fontWeight: 'normal',
    marginTop: '1.5rem',
    '@media(max-width:67.5em)': {
      fontSize: '1.9rem',
    },
    '@media(max-device-width:67.5em)': {
      fontSize: '1.9rem',
    },
    '@media(max-width:47.938em)': {
      fontSize: '1.35rem',
      lineHeight: '1.5',
      width: '85%',
      textAlign: 'center',
      marginTop: '1rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      fontSize: '1.35rem',
      lineHeight: '1.5',
      marginTop: '1rem',
    },
    '@media(min-width:85em)': {
      marginTop: '3rem',
    },
  },
  bannerContent: {
    marginTop: '1.2rem',
    backgroundColor: neusoftBlue,
    width: '8rem',
    height: '0.25rem',
  },
}));

const Banner = ({
  deviceSize,
  title,
  content,
  bannerImageWeb,
  bannerImageMobile,
  bannerImageTablet,
  ...props
}) => {
  const classes = useStyles(props);
  const getImageByDeviceSize = () => {
    if (deviceSize.width <= '650') {
      return bannerImageMobile;
    }
    if (deviceSize.width > '650' && deviceSize.width <= '1080') {
      return bannerImageTablet;
    }
    if (deviceSize.width > '1080') {
      return bannerImageWeb;
    }
  };
  return (
    <div className={classes.bannerContainer}>
      {title ? (
        <>
          <h2 className={classes.bannerContainerTitle}>{title}</h2>
          {content ? (
            <h4 className={classes.bannerText}>{content}</h4>
          ) : (
            <div className={classes.bannerContent}></div>
          )}
        </>
      ) : (
        <div className={classes.bannerImageContainer}>
          <img
            className={classes.bannerImage}
            src={getImageByDeviceSize()}
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default Banner;
