import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/core';
import { white, textColor, neusoftBlue } from '../../colors';

const useStyles = makeStyles(() => ({
  focusAreaContainer: {
    margin: '0 auto',
    height: '86.8%',
    width: (props) => props.focusAreaContainerWidth,
    backgroundColor: textColor,
    boxShadow: '0px 10px 20px #00002ECC',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'white',
    marginTop: '2rem',
    '@media (max-width:67.5em)': {
      height: '90.6%',
    },
    '@media (max-width:67.5em) and (orientation: landscape)': {
      marginTop: '1.5rem',
      height: '89.3%',
    },
    '@media (max-device-width:67.5em) and (orientation: landscape)': {
      marginTop: '1.5rem',
      height: '89.3%',
    },
    '@media (max-width:47.938em)': {
      marginTop: '1.5rem',
      height: '89.3%',
    },
    '@media(max-device-width:67.5em)': {
      marginTop: '1.5rem',
      height: '89.3%',
    },
    '@media (max-device-width:40.625em)': {
      height: '87.8%',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      marginTop: '1.5rem',
      height: '88.1%',
    },
    '@media(min-width:150em)': {
      width: (props) => props.focusAreaContainerWidthDesktopXL,
    },
  },
  focusAreaImageContainer: {
    height: '10.813rem',
    width: '10.563rem',
    backgroundColor: neusoftBlue,
    boxShadow: '0px 10px 20px #000000CE',
    borderRadius: '0px 0px 50px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width:67.5em)': {
      height: '12.813rem',
      width: '12.563rem',
    },
    '@media (max-width:47.938em)': {
      height: '9.813rem',
      width: '9.563rem',
      borderRadius: '0px 0px 25px 0px',
    },
    '@media (max-device-width:67.5em)': {
      height: '13.45rem',
      width: '13.1rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      height: '8.813rem',
      width: '8.563rem',
      borderRadius: '0px 0px 25px 0px',
    },
    '@media(max-width:47.938em)': {
      height: '7rem',
      width: '6.85rem',
      borderRadius: '0px 0px 25px 0px',
    },
  },
  focusAreaImage: {
    width: '3.125rem',
    height: '3.125rem',
    '& img': {
      height: '100%',
      width: '100%',
    },
    '@media (max-width:67.5em)': {
      width: '4.125rem',
      height: '4.125rem',
    },
    '@media (max-width:47.938em)': {
      width: '3.125rem',
      height: '3.125rem',
    },
    '@media (max-device-width:67.5em)': {
      width: '4.125rem',
      height: '4.125rem',
    },
    '@media(max-width:47.938em)': {
      width: '2.125rem',
      height: '2.125rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      width: '2.125rem',
      height: '2.125rem',
    },
  },
  focusAreaBottomContainer: {
    height: '74%',
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media(max-width:67.5em) and (orientation: portrait)': {
      height: '75%',
    },
    '@media(max-width:47.938em)': {
      height: '75%',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      height: '73%',
      width: '95%',
    },
  },
  focusAreaTitle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '8rem',
    '& h4': {
      fontSize: '1.5rem',
      overflowWrap: 'break-word',
      textAlign: 'center',
      '@media (max-width:67.5em)': {
        marginRight: '0',
        textAlign: 'center',
      },
      '@media (max-width:47.938em)': {
        marginRight: '1.85rem',
        textAlign: 'right',
      },
      '@media (max-device-width:67.5em)': {
        marginRight: '0',
        textAlign: 'center',
        fontSize: '1.75rem',
      },
      '@media(max-device-width:40.625em)': {
        height: 'auto',
        textAlign: 'right',
        fontSize: '1.18rem',
        marginRight: '1.85rem',
      },
    },
    '@media(max-width:67.5em)': {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    '@media (max-width:47.938em)': {
      height: '8rem',
      width: '55%',
      alignItems: 'flex-end',
    },
    '@media(max-device-width:67.5em) and (orientation: portrait)': {
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    '@media(max-device-width:67.5em) and (orientation: landscape)': {
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    '@media(max-width:67.5em) and (orientation: portrait)': {
      height: '7rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      height: 'auto',
    },
  },
  focusAreaTextContainer: {
    marginTop: '2.3rem',
    height: '90%',
    '@media(max-width:47.938em)': {
      marginTop: '0',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      marginTop: '2rem',
    },
  },
  focusAreaTextContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    margin: '0',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: '2.4rem',
    '@media(max-width:67.5em)': {
      fontSize: '1.35rem',
      lineHeight: '2.9rem',
    },
    '@media(max-device-width:67.5em)': {
      fontSize: '1.2rem',
      lineHeight: '2.9rem',
    },
    '@media(max-width:47.938em)': {
      fontSize: '1.1rem',
      lineHeight: '2.3rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      fontSize: '1.1rem',
      lineHeight: '2.5rem',
    },
  },
  bullet: {
    margin: '0',
    padding: '0',
    textAlign: 'center',
    fontSize: '0.5rem',
    lineHeight: '0.1rem',
    '@media(max-width:67.5em)': {
      padding: '0.2rem 0',
      fontSize: '0.7rem',
    },
    '@media(max-width:47.938em)': {
      fontSize: '0.8rem',
      padding: '0.1rem 0',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      fontSize: '0.8rem',
      padding: '0',
    },
  },
  sectionLine: {
    marginTop: '0.8rem',
    backgroundColor: white,
    width: '6.4rem',
    height: '0.15rem',
    '@media(max-width:67.5em)': {
      marginTop: '0.8rem',
      width: '6.4rem',
      height: '0.15rem',
    },
    '@media (max-width:47.938em)': {
      width: '5.38rem',
      marginTop: '0.4rem',
      marginRight: '1.85rem',
    },
    '@media(max-device-width:67.5em)': {
      marginTop: '0.8rem',
      width: '6.4rem',
      height: '0.15rem',
    },
  },
  focusAreaBox: {
    width: '100%',
    display: 'none',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '@media(max-width:47.938em)': {
      display: 'flex',
    },
  },
  imageBox: {
    display: 'block',
    '@media(max-width:47.938em)': {
      display: 'none',
    },
  },
  focusTitleBox: {
    display: 'block',
    '@media(max-width:47.938em)': {
      display: 'none',
    },
  },
}));

const FocusAreaSection = ({
  focusAreaImage,
  focusAreaTitle,
  focusAreaContent,
  ...props
}) => {
  const classes = useStyles(props);
  return (
    <div className={classes.focusAreaContainer}>
      <div className={classes.focusAreaBox}>
        <div className={classes.focusAreaImageContainer}>
          <div className={classes.focusAreaImage}>
            <img src={focusAreaImage} alt="" />
          </div>
        </div>
        <div className={classes.focusAreaTitle}>
          <h4>{focusAreaTitle}</h4>
          <div className={classes.sectionLine} />
        </div>
      </div>
      <div className={classes.imageBox}>
        <div className={classes.focusAreaImageContainer}>
          <div className={classes.focusAreaImage}>
            <img src={focusAreaImage} alt="" />
          </div>
        </div>
      </div>
      <div className={classes.focusAreaBottomContainer}>
        <div className={classes.focusTitleBox}>
          <div className={classes.focusAreaTitle}>
            <h4>{focusAreaTitle}</h4>
            <div className={classes.sectionLine} />
          </div>
        </div>
        <div className={classes.focusAreaTextContainer}>
          {focusAreaContent.map((focusArea) => (
            <div className={classes.focusAreaTextContent}>
              <p className={classes.text}>{ReactHtmlParser(focusArea.text)}</p>
              <p className={classes.bullet}>{focusArea.sign}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FocusAreaSection;
