import React from 'react';
import { makeStyles } from '@material-ui/core';
import { textColor, white } from '../../colors';
import phoneIcon from '../../assets/images/icons/home/phone-icon.svg';
import mailIcon from '../../assets/images/icons/home/email-icon.svg';
import { CONTACT_PHONE_NUMBER, CONTACT_EMAIL_ADDRESS } from './contactItems';

const useStyles = makeStyles(() => ({
  footerContactContainer: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    backgroundColor: white,
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'center',
  },
  contact: {
    width: '50%',
    display: 'flex',
    height: 'auto',
    margin: '8rem auto',
    marginBottom: '2rem',
    justifyContent: 'space-between',
    '@media(max-width:50em)': { width: '70%' },
    '@media(max-width:67.5em)': { flexDirection: 'column' },
    '@media(max-device-width:67.5em)': { flexDirection: 'column' },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      width: '80%',
      flexDirection: 'row',
    },

    '@media(max-device-width:20em)': { width: '80%' },
  },
  contactText: {
    marginBottom: '2.5rem',
    fontWeight: 'bold',
    color: textColor,
    '@media (min-width:48em) and (max-width:67.5em)': { fontSize: '1.9rem' },
    '@media (min-device-width:48em) and (max-device-width:67.5em)': { fontSize: '1.9rem' },
    '@media(max-width:47.938em)': { fontSize: '1.7rem' },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      fontSize: '1.7rem',
    },
  },
  contactTextContainer: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: '9rem',
    '@media(max-width:67.5em)': { marginBottom: '8rem' },
    '@media(max-device-width:67.5em)': { marginBottom: '8rem' },
    '@media(max-width:47.938em)': { marginBottom: '5rem' },
  },
  contactParagraph: {
    display: 'flex',
    alignItems: 'center',
    '@media(max-width:50em)': { fontSize: '1.65rem' },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      fontSize: '1.65rem',
    },
    '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em) and (orientation: portrait)':
    {
      fontSize: '1.5rem',
    },
    '@media(max-device-width:48em)': { fontSize: '1.5rem' },
    '@media(max-width:47.938em)': { fontSize: '1.5rem' },
  },
  contactSpan: {
    marginLeft: '1rem',
    '@media (min-width:48em) and (max-width:67.5em)': { fontSize: '1.35rem' },
    '@media (min-device-width:48em) and (max-device-width:67.5em)': { fontSize: '1.35rem' },
    '@media(max-width:50em)': { marginLeft: '2rem' },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      marginLeft: '2rem',
      fontSize: '1.3rem',
    },
  },
  contactImage: {
    minHeight: '1.5rem',
  },
}));

const FooterContact = () => {
  const classes = useStyles();
  return (
    <div className={classes.footerContactContainer}>
      <div className={classes.contact}>
        <div className={classes.contactTextContainer}>
          <h4 className={classes.contactText}>Phone</h4>
          <p className={classes.contactParagraph}>
            <img className={classes.contactImage} src={phoneIcon} alt="" />
            <span className={classes.contactSpan}>{CONTACT_PHONE_NUMBER}</span>
          </p>
        </div>
        <div className={classes.contactTextContainer}>
          <h4 className={classes.contactText}>E-mail</h4>
          <p className={classes.contactParagraph}>
            <img className={classes.contactImage} src={mailIcon} alt="" />
            <span className={classes.contactSpan}>{CONTACT_EMAIL_ADDRESS}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FooterContact;
