import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import OpenPositionForm from './OpenPositionForm';
import RedirectToTop from '../Footer/RedirectToTop';
import careersImageHD from '../../assets/images/careers/open-positions/pop-up/open-positions-pop-upfull-HD.jpg';
import careersImage2k from '../../assets/images/careers/open-positions/pop-up/open-positions-pop-up-2k.jpg';
import careersImage4k from '../../assets/images/careers/open-positions/pop-up/open-positions-pop-up-4k.jpg';
import careersImageTablet from '../../assets/images/careers/open-positions/pop-up/open-positions-pop-up-tablet.jpg';
import careersImageMobile from '../../assets/images/careers/open-positions/pop-up/open-positions-pop-up-mobile.jpg';
import {
  white, neusoftBlue, bannerColor, textColor,
} from '../../colors';
import closeButton from '../../assets/images/icons/careers/open-positions/ionic-ios-close-circle-outline.svg';

const useStyles = makeStyles(() => ({
  openPositionContainer: {
    width: '100%',
    position: 'relative',
    zIndex: '2',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: bannerColor,
  },
  openPositionTop: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '70vh 30vh 1fr',
    zIndex: '3',
    height: 'auto',
    width: '100%',
    marginBottom: '4rem',
  },
  openPositionCloseButton: {
    position: 'absolute',
    width: 'auto',
    height: 'auto',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    top: '10rem',
    right: '7.5%',
    padding: '0',
  },
  openPositionImageContainer: {
    gridColumnStart: '1',
    gridRowStart: '1',
    gridRowEnd: '3',
    height: '100vh',
    width: '100%',
  },
  openPositionImage: {
    position: 'absolute',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      position: 'absolute',
      height: '100%',
      objectFit: 'cover',
      filter: 'blur(10px)',
      transform: 'scale(1.10)',
    },
  },
  openPositionImageTopContainer: {
    height: '100%',
    width: '100%',
    background: '#00CCFF 0% 0% no-repeat',
    mixBlendMode: 'multiply',
  },
  openPositionTitleBox: {
    position: 'relative',
    width: '100%',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& h1': {
      color: white,
      textAlign: 'center',
      '@media(max-width:47.938em)': {
        fontSize: '2.5rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '2rem',
      },
    },
    '@media(max-width:67.5em)': {
      width: '70%',
      margin: '0 auto',
    },
    '@media(max-width:47.938em)': {
      width: '70%',
      margin: '0 auto',
    },
  },
  subtitle: { fontWeight: 'normal', marginTop: '2rem' },
  openPositionDescriptionBox: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 10px 20px #00002ECC',
    position: 'relative',
    gridColumnStart: '1',
    gridRowStart: '2',
    gridRowEnd: '4',
    width: '87%',
    height: 'auto',
    left: '6.5%',
    '@media(max-width:67.5em)': {
      width: '95%',
      left: '2.5%',
    },
  },
  openPositionDescriptionContent: {
    position: 'relative',
    width: '85%',
    height: 'auto',
    margin: '0 auto',
    marginBottom: '4.5rem',
    '& h4': {
      color: neusoftBlue,
      marginTop: '4rem',
      marginBottom: '3rem',
      '@media(max-width:47.938em)': {
        fontSize: '1.35rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.35rem',
      },
    },
    listStylePosition: 'outside',
  },
  content: {
    fontFamily: 'EXO, sans-serif',
    '& h1,h2,h3,h4,h5': {
      color: textColor,
      marginTop: '3rem',
      marginBottom: '3rem',
      fontFamily: 'EXO, sans-serif',
      lineHeight: '1.8',
      '@media(max-width:47.938em)': {
        fontSize: '1.35rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.35rem',
      },
    },
    '& p': {
      color: textColor,
      marginTop: '1rem',
      marginBottom: '1rem',
      fontFamily: 'EXO, sans-serif',
      fontSize: '1.65rem',
      '@media(max-width:47.938em)': {
        fontSize: '1.3rem',
      },
      '@media only screen and (max-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.1rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.35rem',
      },
      '@media(min-width:48em) and (max-width:67.5em) and (orientation: portrait)': {
        fontSize: '1.8rem',
      },
    },
    '& ul': {
      paddingInlineStart: '0',
      marginLeft: '1.5rem',
      textIndent: '0',
      fontFamily: 'EXO, sans-serif',
      '& li': {
        paddingLeft: '3.5rem',
        fontSize: '1.65rem',
        '@media only screen and (max-height : 47em) and (orientation: landscape)':
        {
          fontSize: '1.1rem',
        },
        '@media(min-width:48em) and (max-width:67.5em) and (orientation: portrait)': {
          fontSize: '1.8rem',
        },
        '@media(max-width:67.5em)': {
          paddingLeft: '0.5rem',
        },
        '@media(max-width:47.938em)': {
          fontSize: '1.3rem',
        },
        '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
        {
          fontSize: '1.35rem',
        },
        '&::marker': {
          fontSize: '1.3rem',
          '@media(max-width:47.938em)': {
            fontSize: '1.35rem',
          },
          '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
          {
            fontSize: '1.35rem',
          },
        },

      },
    },
    '& ol': {
      marginLeft: '1.5rem',
      textIndent: '0',
      fontFamily: 'EXO, sans-serif',
      '& li': {
        paddingLeft: '3.5rem',
        '&::marker': {
          fontSize: '1rem',
          '@media(max-width:67.5em)': {
            fontSize: '1.8rem',
          },
          '@media(max-width:47.938em)': {
            fontSize: '1.35rem',
          },
          '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
          {
            fontSize: '1.35rem',
          },
        },
        '@media(max-width:67.5em)': {
          paddingLeft: '0.5rem',
        },
        '@media(max-width:47.938em)': {
          fontSize: '1.35rem',
        },
        '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
        {
          fontSize: '1.35rem',
        },
      },
    },
  },
  blur: {
    // color: 'transparent !important',
    // textShadow: '0 0 10px rg ba(255,255,255,0.5)',
    filter: 'blur(7px)',
  },

}));

const defaultEntry = {
  referenceNumber: 'Reference Number',
  jobStatus: true,
  jobLocation: 'Location',
  descriptionTitle: 'Description Text',
  descriptionText: `
  <h4><span style="font-size: 24px;">Loading</span></h4>\n
  <ul>\n
  <li><span style="font-size: 24px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>\n
  <li><span style="font-size: 24px;">sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut</span></li>\n
  <li><span style="font-size: 24px;">Sed ut perspiciatis unde omnis iste natus error</span></li>\n
  <li><span style="font-size: 24px;">aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</span></li>\n
  <li><span style="font-size: 24px;">Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat</span></li>\n
  <li><span style="font-size: 24px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</span></li>\n
  <li><span style="font-size: 24px;"> Duis aute irure dolor in reprehenderit</span></li>\n
  <li><span style="font-size: 24px;">aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</span></li>\n
  <li><span style="font-size: 24px;">Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat</span></li>\n
  <li><span style="font-size: 24px;">Loading loading loading Loading loading loading Loading loading loading loading loadingLoading loading loading</span></li>\n
  </ul>\n
  <h4><span style="font-size: 24px;">Your qualifications </span></h4>\n
  <ul>\n
  <li><span style="font-size: 24px;">Loading loading loading Loading loading loading Loading loading loading Loading loading loadingLoading loading loadingLoading loading loading</span></li>\n
  <li><span style="font-size: 24px;">Loading loading loading Loading loading loading Loading loading loading Loading loading loadingLoading loading loadingLoading loading loading </span></li>\n
  <li><span style="font-size: 24px;">Loading loading loading Loading loading loading Loading loading loading Loading loading loadingLoading loading loadingLoading loading loading</span></li>\n
  <li><span style="font-size: 24px;">Loading loading loading Loading loading loading Loading loading loading Loading loading loadingLoading loading loadingLoading loading loading</span></li>\n
  <li><span style="font-size: 24px;">Loading loading loading Loading loading loading Loading loading loading Loading loading loadingLoading loading loadingLoading loading loading</span></li>\n
  <li><span style="font-size: 24px;">Loading loading loading Loading loading loading Loading loading loading Loading loading loadingLoading loading loadingLoading loading loading</span></li>\n
  <li><span style="font-size: 24px;">Loading loading loading Loading loading loading Loading loading loading Loading loading loadingLoading loading loadingLoading loading loading </span></li>\n
  <li><span style="font-size: 24px;">Loading loading loading Loading loading loading Loading loading loading Loading loading loadingLoading loading loadingLoading loading loading</span></li>\n
  <li><span style="font-size: 24px;">Loading loading loading Loading loading loading Loading loading loading Loading loading loadingLoading loading loadingLoading loading loading</span></li>\n
  <li><span style="font-size: 24px;">Loading loading loading Loading loading loading Loading loading loading Loading loading loadingLoading loading loadingLoading loading loading</span></li>\n
  </ul>\n
  <h4><span style="font-size: 24px;">Who we are </span></h4>\n
  <p><span style="font-size: 24px;">Neusoft Technology Solutions GmbH is the German branch of Neusoft Corporation, China’s largest software company with 20,000 employees worldwide. Our focus is on software development, design and architecture for the automotive industry. We create software solutions for navigation and infotainment systems as well as ADAS components for autonomous driving. The latter include V2X software, AI algorithms and Big Data analyses in particular. Our customers and partners are market-leading automotive suppliers with whom we jointly develop the pioneering next-generation software solutions and products.</span></p>\n
  `,
  id: null,
  title: 'Example Job Title (E/G)',
};

const OpenPosition = ({
  desktopLayout,
  deviceSize,
  positions,
  positionId,
  ...props
}) => {
  const classes = useStyles(props);
  const history = useHistory();
  const params = useParams();
  const [position, setPosition] = useState(defaultEntry);
  useEffect(() => {
    const loadedPosition = positions.find(({ id }) => id === positionId);
    if (loadedPosition) {
      setPosition(loadedPosition);
    } else {
      setPosition(defaultEntry);
      axios.get(`${process.env.REACT_APP_URL}/api/job/${params.positionId}`).then((response) => {
        setPosition(response.data.Items[0]);
      });
    }
  }, []);
  const exitReferenceCase = () => {
    document.body.style.overflow = 'auto';
    history.goBack();
  };
  const getImageByDeviceSize = () => {
    if (deviceSize.width <= '650') {
      return careersImageMobile;
    }
    if (deviceSize.width > '650' && deviceSize.width <= '1080') {
      return careersImageTablet;
    }
    if (deviceSize.width >= '2000') {
      return careersImage2k;
    }
    if (deviceSize.width >= '4000') {
      return careersImage4k;
    }
    return careersImageHD;
  };
  return (
    <div className={classes.openPositionContainer}>
      <div className={classes.openPositionTop}>
        <div className={classes.openPositionImageContainer}>
          <div className={classes.openPositionImage}>
            <>
              <img src={getImageByDeviceSize()} alt="" />
            </>
            <div className={classes.openPositionImageTopContainer} />
          </div>
          <div className={`${classes.openPositionTitleBox} ${!position.id ? classes.blur : ''}`}>
            <h1>{position.title}</h1>
            <h1 className={classes.subtitle}>{position.jobLocation}</h1>
          </div>
          <button className={classes.openPositionCloseButton} type="button" onClick={exitReferenceCase}>
            <img src={closeButton} alt="" />
          </button>
        </div>
        <div className={classes.openPositionDescriptionBox}>
          <div className={`${classes.openPositionDescriptionContent} ${!position.id ? classes.blur : ''}`}>
            <h4>{position.referenceNumber}</h4>
            <div className={classes.content}>
              {ReactHtmlParser(position.descriptionText)}
            </div>
          </div>
        </div>
      </div>
      <OpenPositionForm position={position} />
      <RedirectToTop />
    </div>
  );
};

export default OpenPosition;
