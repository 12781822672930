/* eslint-disable operator-linebreak */
export const NAVIGATION_TEXT = [
  {
    sign: '•',
    text: 'Navigation',
    subtext:
      '- e.g. off the shelf solution, navigation core, cloud function and services',
  },
  {
    sign: '•',
    text: 'Map compilation',
    subtext:
      '- e.g. own NDS compiler, covering challenging markets as China, Japan',
  },
  {
    sign: '•',
    text: 'Navigation ADAS support',
    subtext: '- e.g. ISA, eHorizon, ADASIS v2 & v3',
  },

  {
    sign: '•',
    text: 'Augmented Reality',
    subtext: '- e.g. applicable for 3rd party navigation solutions',
  },
];

export const COCKPIT_TEXT_MOBILE = [
  {
    sign: '•',
    text: 'eCockpit - (full stack HW & SW for IVI & IC)',
    subtext: '– e.g. full stack for Infotainment system and Instrument Cluster',
  },
  {
    sign: '•',
    text: 'IVI SW development',
    subtext:
      '– embedded SW development for IVI systems from application to physical layer',
  },
  {
    sign: '•',
    text: 'IVI HW development',
    subtext:
      '– embedded SW development for IVI systems from application to physical layer',
  },
];

export const COCKPIT_TEXT = [
  {
    sign: '•',
    text: 'eCockpit',
    subtext: '– e.g. full stack for Infotainment system and Instrument Cluster',
  },
  {
    sign: '•',
    text: 'IVI SW development',
    subtext:
      '– embedded SW development for IVI systems from application to physical layer',
  },
  {
    sign: '•',
    text: 'IVI HW development',
    subtext:
      '– from infotainment head unit to Android Automotive OS',
  },
];

export const CONNECTIVITY_TEXT = [
  {
    sign: '•',
    text: 'TCU HW platform',
    subtext: '- e.g. Neusoft T-Box with integrated smart antenna',
  },
  {
    sign: '•',
    text: 'V2X',
    subtext: '- e.g. full stack V2X solution from SW stack to HW',
  },
  {
    sign: '•',
    text: 'Backend',
    subtext:
      '- e.g. cloud service platform development, Chinese automotive ecosystem integration',
  },
  {
    sign: '•',
    text: 'Application',
    subtext:
      '- e.g. development and integration of embedded-, mobile-, web-, service apps',
  },
];
export const TESTING_TEXT = [
  {
    sign: '•',
    text: 'Manual testing',
    subtext: '- e.g. bench tests',
  },
  {
    sign: '•',
    text: 'Automatic testing',
    subtext: '- e.g. different abstraction layers',
  },
  {
    sign: '•',
    text: 'Field testing',
    subtext: '- e.g. test product in the environment it will be running ',
  },
  {
    sign: '•',
    text: 'Test driving',
    subtext: '- e.g. test user experience / system or functions in the car',
  },
];

export const NAVIGATIONsubtitle = 'Products and development for';
export const COCKPITsubtitle = 'Products and development for';
export const CONNECTIVITYsubtitle = 'Products and development for';
// eslint-disable-next-line operator-linebreak
export const TESTINGsubtitle = 'Test as a service for';

export const NAVIGATION_URL = '/navigation';
export const COCKPIT_URL = '/cockpit';
export const CONNECTIVITY_URL = '/connectivity';
export const TESTING_URL = '/testing';
export const ABOUT_URL = '/who-we-are';

// eslint-disable-next-line operator-linebreak
export const FIRST_BANNER_TITLE =
  'Broad portfolio from IP products to Engineering Services';
export const SECOND_BANNER_TITLE = 'Let’s talk business!';
// eslint-disable-next-line operator-linebreak
export const SECOND_BANNER_TEXT =
  'Please contact us, if you have any questions!';
export const HOME_DESCRIPTION_PARAGRAPH1 =
  'is the European headquarter of the international Neusoft Corporation, which is one of the largest software companies in China.';
export const HOME_DESCRIPTION_PARAGRAPH2 =
  'With Neusoft Technology Solutions GmbH, customers have a reliable partner with proven expertise and target-oriented development of solutions for automotive embedded software development at their side.';
export const HOME_DESCRIPTION_PARAGRAPH3 =
  'Neusoft Corporation, founded in 1991, is the first listed software company in China. Today, Neusoft has nearly 20,000 employees worldwide, and has established an R&D, sales and service network.';
export const HOME_DESCRIPTION_TITLE = 'Neusoft Technology Solutions GmbH (NTS)';
export const NTS_NAME = 'Neusoft Technology Solutions';
export const HOME_HERO_SECTION_SUBTITLE = 'The Magic of Software';
