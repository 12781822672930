import * as homeItems from './homeText';
import navigationImage from '../../assets/images/home/Navigation-HD.jpg';
import cockpitImage from '../../assets/images/home/Cockpit-HD.jpg';
import connectivityImage from '../../assets/images/home/Connectivity-HD.jpg';
import testingImage from '../../assets/images/home/Testing-HD.jpg';
import { white, neusoftBlue, textColor } from '../../colors';

export const homeServiceContentSwiper = [
  {
    title: 'NAVIGATION',
    subtitle: homeItems.NAVIGATIONsubtitle,
    titleDescription: '',
    items: homeItems.NAVIGATION_TEXT,
    URL: homeItems.NAVIGATION_URL,
    image: navigationImage,
    displayWrapper: 'flex',
    alignItemsWrapper: 'center',
    justifyContentWrapper: 'space-between',
    order: '0',
    height: '80%',
    justifyContent: 'flex-start',
    color: textColor,
    hoverBackgroundColor: textColor,
    titleColor: neusoftBlue,
    hoverTitleColor: white,
    alignItems: 'center',
    alignSubtitle: 'center',
    background: 'transparent',
    width: '100%',
  },
  {
    title: 'COCKPIT',
    subtitle: homeItems.COCKPITsubtitle,
    titleDescription: '',
    items: homeItems.COCKPIT_TEXT_MOBILE,
    URL: homeItems.COCKPIT_URL,
    image: cockpitImage,
    displayWrapper: 'flex',
    alignItemsWrapper: 'center',
    justifyContentWrapper: 'space-between',
    order: '0',
    height: '80%',
    justifyContent: 'flex-start',
    color: white,
    hoverBackgroundColor: white,
    titleColor: white,
    hoverTitleColor: neusoftBlue,
    alignItems: 'center',
    background:
      'transparent linear-gradient(91deg, #005BAC 0%, #00CCFF 100%) 0% 0% no-repeat padding-box',
  },
  {
    title: 'CONNECTIVITY',
    subtitle: homeItems.CONNECTIVITYsubtitle,
    titleDescription: '',
    items: homeItems.CONNECTIVITY_TEXT,
    URL: homeItems.CONNECTIVITY_URL,
    image: connectivityImage,
    displayWrapper: 'flex',
    order: '0',
    height: '80%',
    justifyContent: 'flex-start',
    alignItemsWrapper: 'center',
    justifyContentWrapper: 'space-between',
    color: textColor,
    hoverBackgroundColor: textColor,
    titleColor: neusoftBlue,
    hoverTitleColor: white,
    alignItems: 'center',
    alignSubtitle: 'center',
    background: 'transparent',
    width: '100%',
  },
  {
    title: 'TESTING',
    subtitle: homeItems.TESTINGsubtitle,
    titleDescription: '',
    items: homeItems.TESTING_TEXT,
    URL: homeItems.TESTING_URL,
    image: testingImage,
    displayWrapper: 'flex',
    order: '0',
    height: '80%',
    justifyContent: 'flex-start',
    alignItemsWrapper: 'center',
    justifyContentWrapper: 'space-between',
    color: white,
    hoverBackgroundColor: white,
    titleColor: white,
    hoverTitleColor: neusoftBlue,
    alignItems: 'center',
    background:
      'transparent linear-gradient(91deg, #005BAC 0%, #00CCFF 100%) 0% 0% no-repeat padding-box',
  },
];
export const homeServiceContent = [
  {
    title: 'NAVIGATION',
    subtitle: homeItems.NAVIGATIONsubtitle,
    titleDescription: '',
    items: homeItems.NAVIGATION_TEXT,
    URL: homeItems.NAVIGATION_URL,
    image: navigationImage,
    displayWrapper: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '44% 8% 1fr',
    gridRowStartTitle: '1',
    gridColumnStartTitle: '1',
    gridColumnEndTitle: '2',
    gridRowStartSubtitle: '1',
    gridColumnStartSubtitle: '3',
    gridColumnEndSubtitle: '4',
    paddingRight: '5rem',
    order: '0',
    height: '55%',
    justifyContent: 'center',
    color: textColor,
    hoverBackgroundColor: textColor,
    titleColor: neusoftBlue,
    hoverTitleColor: white,
    alignItems: 'flex-start',
    alignSubtitle: 'flex-end',
    marginRight: '0',
    marginRightTitle: '0',
    marginLeftTitle: '1.875rem',
    paddingLeft: '0',
    background: 'transparent',
    justifyContentWrapper: 'center',
  },
  {
    title: 'COCKPIT',
    subtitle: homeItems.COCKPITsubtitle,
    titleDescription: '',
    items: homeItems.COCKPIT_TEXT,
    URL: homeItems.COCKPIT_URL,
    image: cockpitImage,
    displayWrapper: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 4% 44%',
    gridRowStartTitle: '1',
    gridColumnStartTitle: '3',
    gridColumnEndTitle: '4',
    gridRowStartSubtitle: '1',
    gridColumnStartSubtitle: '1',
    gridColumnEndSubtitle: '2',
    justifyContentWrapper: 'space-between',
    paddingRight: '0',
    order: '-1',
    height: '45%',
    justifyContent: 'center',
    color: white,
    hoverBackgroundColor: white,
    titleColor: white,
    hoverTitleColor: neusoftBlue,
    alignItems: 'flex-end',
    marginRightTitle: '1.875rem',
    marginLeftTitle: '0',
    marginLeft: '0',
    paddingLeft: '5rem',
    background:
      'transparent linear-gradient(91deg, #005BAC 0%, #00CCFF 100%) 0% 0% no-repeat padding-box',
  },
  {
    title: 'CONNECTIVITY',
    subtitle: homeItems.CONNECTIVITYsubtitle,
    titleDescription: '',
    items: homeItems.CONNECTIVITY_TEXT,
    URL: homeItems.CONNECTIVITY_URL,
    image: connectivityImage,
    displayWrapper: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '44% 8% 1fr',
    gridRowStartTitle: '1',
    gridColumnStartTitle: '1',
    gridColumnEndTitle: '2',
    gridRowStartSubtitle: '1',
    gridColumnStartSubtitle: '3',
    gridColumnEndSubtitle: '4',
    paddingRight: '5rem',
    order: '0',
    height: '60%',
    justifyContent: 'center',
    color: textColor,
    hoverBackgroundColor: textColor,
    titleColor: neusoftBlue,
    hoverTitleColor: white,
    alignItems: 'flex-start',
    alignSubtitle: 'flex-end',
    marginRight: '0',
    marginRightTitle: '0',
    marginLeftTitle: '1.875rem',
    paddingLeft: '0',
    background: 'transparent',
    justifyContentWrapper: 'center',
  },
  {
    title: 'TESTING',
    subtitle: homeItems.TESTINGsubtitle,
    titleDescription: '',
    items: homeItems.TESTING_TEXT,
    URL: homeItems.TESTING_URL,
    image: testingImage,
    displayWrapper: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 4% 44%',
    gridRowStartTitle: '1',
    gridColumnStartTitle: '3',
    gridColumnEndTitle: '4',
    gridRowStartSubtitle: '1',
    gridColumnStartSubtitle: '1',
    gridColumnEndSubtitle: '2',
    justifyContentWrapper: 'space-between',
    paddingRight: '0',
    order: '-1',
    height: '58%',
    justifyContent: 'center',
    color: white,
    hoverBackgroundColor: white,
    titleColor: white,
    hoverTitleColor: neusoftBlue,
    alignItems: 'flex-end',
    marginRightTitle: '1.875rem',
    marginLeftTitle: '0',
    marginLeft: '0',
    paddingLeft: '5rem',
    background:
      'transparent linear-gradient(91deg, #005BAC 0%, #00CCFF 100%) 0% 0% no-repeat padding-box',
  },
];
