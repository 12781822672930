import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { neusoftBlue, textColor } from '../../colors';
import UseScrollToTop from '../../hooks/UseScrollToTop';

const useStyles = makeStyles(() => ({
  contactsContainer: {
    overflow: 'hidden',
    minHeight: '95vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& h3': {
      color: neusoftBlue,
    },
  },
  footerLink: {
    fontSize: '1.125rem',
    textDecoration: 'none',
    color: textColor,
    padding: '0',
    fontWeight: '500',
    '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em) and (orientation: portrait)':
      {
        fontSize: '1.35rem',
      },
    '@media only screen and (max-device-width : 48em) and (orientation: portrait)':
      {
        fontSize: '1.55rem',
      },
    '@media(max-width:47.938em)': { fontSize: '1.1rem' },
    '@media only screen and (max-device-width : 67.5em) and (orientation: landscape)':
      {
        fontSize: '1.1rem',
      },
  },
}));

const RedirectHandler = () => {
  const classes = useStyles();

  const location = useLocation();
  const redirectPath = location.pathname.split('redirect/')[1];

  console.log(redirectPath);

  const redirectUrl = `${process.env.REACT_APP_URL}${redirectPath}`;

  useEffect(async () => {
    const response = await fetch(`${process.env.REACT_APP_URL}/api/redirect/${redirectPath}`);
    const data = await response.json();

    if (!data.filePath) {
      window.location.href = '/';
      return;
    }

    window.location.href = `${process.env.REACT_APP_URL}${data.filePath}`;
  }, [redirectPath]);

  return (
    <div className={classes.contactsContainer}>
      <h3>Redirecting...</h3>

      {redirectUrl && <Link to={redirectUrl}>Click here to redirect</Link>}
      <Link className={classes.footerLink} to="/">
        Go back to Home page
      </Link>

      <UseScrollToTop />
    </div>
  );
};

export default RedirectHandler;
