import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { neusoftBlue, textColor } from '../../colors';
import UseScrollToTop from '../../hooks/UseScrollToTop';
import RedirectToTop from '../../components/Footer/RedirectToTop';

const useStyles = makeStyles(() => ({
  contactsContainer: {
    overflow: 'hidden',
    minHeight: '95vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& h3': {
      color: neusoftBlue,
    },
  },
  footerLink: {
    fontSize: '1.125rem',
    textDecoration: 'none',
    color: textColor,
    padding: '0',
    fontWeight: '500',
    '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em) and (orientation: portrait)':
      {
        fontSize: '1.35rem',
      },
    '@media only screen and (max-device-width : 48em) and (orientation: portrait)':
      {
        fontSize: '1.55rem',
      },
    '@media(max-width:47.938em)': { fontSize: '1.1rem' },
    '@media only screen and (max-device-width : 67.5em) and (orientation: landscape)':
      {
        fontSize: '1.1rem',
      },
  },
}));

const PageNotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.contactsContainer}>
      <h3>Page not found</h3>
      <Link className={classes.footerLink} to="/">
        Go back to Home page
      </Link>
      <RedirectToTop />
      <UseScrollToTop />
    </div>
  );
};

export default PageNotFound;
