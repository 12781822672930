import React from 'react';
import { useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/core';
import { allSolutionsPopUps } from './solutionsItems';
import RedirectToTop from '../Footer/RedirectToTop';
import {
  white, bannerColor, neusoftBlue, textColor, errorInputColor,
} from '../../colors';
import closeButton from '../../assets/images/icons/careers/open-positions/ionic-ios-close-circle-outline.svg';
import SolutionPopUpSwiper from './SolutionPopUpSwiper';

const useStyles = makeStyles(() => ({
  solutionContainer: {
    width: '100%',
    position: 'relative',
    zIndex: '2',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: bannerColor,
  },
  solutionTop: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '43vh 50vh 1fr',
    zIndex: '3',
    height: 'auto',
    width: '100%',
    marginBottom: '4rem',
    marginTop: '7vh',
  },
  solutionCloseButton: {
    position: 'absolute',
    width: 'auto',
    height: 'auto',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    top: '5rem',
    right: '7.5%',
    padding: '0',
    '@media (max-width:47.938em)': {
      width: '2.5rem',
      height: '2.5rem',
    },
    '@media (max-height:47em) and (orientation: landscape)': {
      width: '2.5rem',
      height: '2.5rem',
    },
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  solutionImageContainer: {
    gridColumnStart: '1',
    gridRowStart: '1',
    gridRowEnd: '3',
    height: '93vh',
    width: '100%',
  },
  solutionImage: {
    position: 'absolute',
    width: '100%',
    height: '93vh',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      position: 'absolute',
      height: '100%',
      objectFit: 'cover',
      filter: 'blur(10px)',
      transform: 'scale(1.05)',
    },
  },
  solutionImageTopContainer: {
    height: '100%',
    width: '100%',
    background: '#BEDFF5 0% 0% no-repeat',
    mixBlendMode: 'multiply',
    opacity: '1',
  },
  solutionTitleBox: {
    position: 'relative',
    width: '100%',
    height: '65%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& h1': {
      color: white,
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)': {
        fontSize: '1.8rem',
      },
      '@media only screen and (max-device-width: 20em)': {
        fontSize: '2.2rem',
      },
    },
  },
  sectionTitle: {
    height: '5rem',
    margin: '4rem 0',
    marginBottom: '6rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& h3': {
      color: neusoftBlue,
      textAlign: 'center',
      '@media(max-width:47.938em)': {
        margin: '0',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.38rem',
      },
      '@media only screen and (max-device-height : 47em) and (min-device-width : 70em) and (orientation: landscape)':
      {
        fontSize: '1.5rem',
      },
    },
    '@media(max-width:68.75em)': {
      marginBottom: '3rem',
    },
    '@media(max-device-width:67.5em)': {
      marginBottom: '3rem',
    },
    '@media(max-width:47.938em)': {
      marginTop: '2.5rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      margin: '3rem 0',
    },
  },
  sectionLine: {
    marginTop: '1.2rem',
    backgroundColor: neusoftBlue,
    width: '8rem',
    height: '0.25rem',
    zIndex: '1',
  },
  solutionDescriptionBox: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 10px 20px #00002ECC',
    position: 'relative',
    gridColumnStart: '1',
    gridRowStart: '2',
    gridRowEnd: '4',
    width: '85%',
    height: 'auto',
    left: '7.5%',
    '@media(max-width:68.75em)': {
      width: '95%',
      left: '2.5%',
    },
    '@media(max-device-width:67.5em)': {
      width: '95%',
      left: '2.5%',
    },
  },
  solutionDescriptionContent: {
    position: 'relative',
    width: '70%',
    height: 'auto',
    margin: '0 auto',
    marginBottom: '10.5rem',
    '@media(min-width:150em)': {
      marginBottom: '17rem',
    },
    '& h4': {
      fontWeight: 'normal',
      margin: '3rem 0',
      textAlign: 'center',
      '@media(max-width:68.75em)': {
        textAlign: 'left',
      },
      '@media(max-device-width:67.5em)': {
        textAlign: 'center',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.18rem',
      },
    },
    '& span': {
      fontWeight: 'bold',
      color: neusoftBlue,
    },
    '@media(max-width:68.75em)': {
      width: '85%',
      marginBottom: '5rem',
    },
    '@media(max-device-width:67.5em)': {
      width: '85%',
      marginBottom: '5rem',
    },
    '@media(max-width:47.938em)': {
      width: '85%',
      marginBottom: '3rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      width: '85%',
      marginBottom: '3rem',
    },
  },
  solutionSwiperSection: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    backgroundColor: white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& h4': {
      fontWeight: 'normal',
      textAlign: 'center',
      width: '65%',
      marginBottom: '12rem',
    },
  },
  solutionSwiperSectionImage: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    backgroundColor: white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media(max-width:68.75em)': {
      display: 'none',
    },
    '@media(max-device-width:67.5em)': {
      display: 'none',
    },
    '& h4': {
      fontWeight: 'normal',
      textAlign: 'center',
      width: '65%',
      marginBottom: '12rem',
    },
  },
  solutionsTitle: {
    height: '5rem',
    marginTop: '2rem',
    marginBottom: '3rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& h3': {
      color: neusoftBlue,
      textAlign: 'center',
      '@media(max-width:47.938em)': {
        margin: '0',
        fontSize: '1.5rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '2rem',
      },
      '@media only screen and (max-device-height : 47em) and (min-device-width : 70em) and (orientation: landscape)':
      {
        fontSize: '1.5rem',
      },
    },
    '@media(max-width:68.75em)': {
      marginBottom: '3rem',
    },
    '@media(max-device-width:67.5em)': {
      marginBottom: '3rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      margin: '3rem 0',
    },
  },
  solutionSwiperSimple: {
    width: '85%',
    height: 'auto',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '1fr',
    gridColumnGap: '1.5rem',
    marginBottom: '5rem',
  },
  swiperSimpleImage: {
    marginBottom: '2rem',
    width: '45%',
    '@media(max-width:68.75em)': {
      width: '90%',
      marginBottom: '7rem',
    },
    '@media(max-device-width:67.5em)': {
      width: '90%',
      marginBottom: '7rem',
    },
    '@media(max-width:47.938em)': {
      width: '90%',
      marginBottom: '6rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      width: '90%',
      marginBottom: '6rem',
    },
    '@media only screen and (max-device-height : 47em) and (min-device-width : 70em) and (orientation: landscape)':
    {
      marginBottom: '2rem',
      width: '45%',
    },
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  solutionContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'auto',
    height: 'auto',
    '& h4': {
      fontWeight: 'normal',
      width: '70%',
      margin: '0 auto',
      textAlign: 'center',
      overflowWrap: 'normal',
      marginTop: '2rem',
    },
  },
  imageBoxSimple: {
    height: 'auto',
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  solutionBottomContainer: {
    height: 'auto',
    width: '85%',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    '@media(max-width:68.75em)': {
      width: '100%',
      flexDirection: 'column',
    },
    '@media(max-device-width:67.5em)': {
      width: '100%',
      flexDirection: 'column',
    },
  },
  solutionBottomTextContainer: {
    marginBottom: '3rem',
    width: '50%',
    height: 'auto',
    order: '0',
    '& h3': {
      color: neusoftBlue,
      margin: '2rem 0',
    },
    '& p': {
      marginBottom: '2rem',
      '@media(min-width:48em)': {
        fontSize: '1.65rem',
      },
      '@media(max-width:68.75em)': {
        fontSize: '2.18rem',
      },
      '@media(max-width:47.938em)': {
        fontSize: '1.18rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)': {
        fontSize: '1.18rem',
      },
    },
    '@media(max-width:68.75em)': {
      width: '90%',
      marginBottom: '5rem',
    },
    '@media(max-device-width:67.5em)': {
      width: '90%',
      marginBottom: '5rem',
    },
  },
  sectionListItem: {
    marginBottom: '1.8rem',
    '& ul': {
      paddingInlineStart: '0',
      marginLeft: '1.3rem',
      '& li': {
        '@media(min-width:48em)': {
          fontSize: '1.65rem',
        },
        '@media only screen and (max-height : 47em) and (min-device-width : 70em) and (orientation: landscape)':
        {
          fontSize: '1.18rem',
        },
        '@media only screen and (max-device-height : 47em) and (min-device-width : 70em) and (orientation: landscape)':
        {
          fontSize: '1.18rem',
        },
        paddingLeft: '0.8rem',
        '&::marker': { fontSize: '1.3rem' },
      },
    },
    '& h3': {
      color: textColor,
    },
  },
  listContent: {
    fontWeight: 'normal',
    width: '85%',
    '@media(max-width:68.75em)': {
      width: '100%',
    },
    '@media(max-device-width:67.5em)': {
      width: '100%',
    },
  },
  solutionBottomImageContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '5rem',
    marginBottom: '8rem',
    width: '50%',
    height: 'auto',
    order: '0',
    '& img': {
      width: 'auto',
      boxShadow: '0px 10px 20px #00002ECC',
      '@media(max-width:120em)': {
        maxHeight: '45rem',
      },
      '@media(max-width:85em)': {
        maxHeight: '35rem',
      },
      '@media(min-width:120em)': {
        maxHeight: '50rem',
      },
      '@media(max-width:68.75em)': {
        width: '100%',
        objectFit: 'cover',
        maxHeight: '48.9rem',
        boxShadow: 'none',
        objectPosition: 'center center',
      },
      '@media(max-device-width:67.5em)': {
        width: '100%',
        objectFit: 'cover',
        maxHeight: '48.9rem',
        boxShadow: 'none',
        objectPosition: 'center center',
      },
      '@media(max-width:47.938em)': {
        maxHeight: '21.61rem',
        objectPosition: 'center center',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        maxHeight: '27.61rem',
        objectPosition: 'center center',
        objectFit: 'cover',
      },
      '@media only screen and (max-device-height : 47em) and (min-device-width : 70em) and (orientation: landscape)':
      {
        width: '100%',
        objectFit: 'cover',
        maxHeight: '45rem',
        boxShadow: 'none',
        objectPosition: 'center center',
      },
    },
    '@media(max-width:68.75em)': {
      order: '-1',
      width: '100%',
      marginTop: '4.45rem',
      marginBottom: '4rem',
    },
    '@media(max-device-width:67.5em)': {
      order: '-1',
      width: '100%',
      marginTop: '4.45rem',
      marginBottom: '4rem',
    },
    '@media(max-width:47.938em)': {
      marginTop: '0',
      marginBottom: '2rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      marginTop: '0',
      marginBottom: '2rem',
    },
    '@media only screen and (max-device-height : 47em) and (min-device-width : 67.5em) and (orientation: landscape)':
    {
      marginTop: '5rem',
      marginBottom: '8rem',
      width: '50%',
      height: 'auto',
      order: '0',
    },
  },
  solutionListContainer: {
    '& h3': { color: textColor },
    '& h4': {
      color: neusoftBlue,
      width: '85%',
      marginBottom: '5rem',
      '@media(max-device-width:67.5em)': {
        width: '100%',
      },
    },
    '& ul': {
      width: '90%',
      paddingInlineStart: '0',
      marginLeft: '1.3rem',
      '& li': {
        '@media(min-width:48em)': {
          fontSize: '1.65rem',
        },
        '@media only screen and (max-height : 43.75em) and (min-device-width : 67.5em) and (orientation: landscape)':
        {
          fontSize: '1.18rem',
        },
        '@media only screen and (max-device-height : 47em) and (min-device-width : 67.5em) and (orientation: landscape)':
        {
          fontSize: '1.18rem',
        },
        paddingLeft: '0.8rem',
        '&::marker': { fontSize: '1.3rem' },
      },
    },
    '& span': {
      fontWeight: 'bold',
    },
  },
  swiperImages: {
    display: 'none',
    '@media(max-width:68.75em)': {
      display: 'block',
    },
    '@media(max-device-width:67.5em)': {
      display: 'block',
    },
  },
  ordinaryList: {
    '& span': { color: errorInputColor },
  },
  warningItem: {
    width: '85%',
    margin: '0 auto',
    marginBottom: '2rem',
    color: errorInputColor,
    '@media(max-width:68.75em)': {
      width: '90%',
    },
    '@media(max-device-width:67.5em)': {
      width: '90%',
    },
  },
  solutionBottomBox: {
    height: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const SolutionCasePopUp = ({
  desktopLayout,
  windowSize,
  pathSolution,
  ...props
}) => {
  const classes = useStyles(props);
  const history = useHistory();
  const solutionPopUp = allSolutionsPopUps.find(({ pathName }) => pathName === pathSolution);
  const exitReferenceCase = () => {
    document.body.style.overflow = 'auto';
    history.goBack();
  };

  return (
    <div className={classes.solutionContainer}>
      <div className={classes.solutionTop}>
        <div className={classes.solutionImageContainer}>
          <div className={classes.solutionImage}>
            <>
              <img src={solutionPopUp.backgroundImage} alt="" />
            </>
            <div className={classes.solutionImageTopContainer} />
          </div>
          <div className={classes.solutionTitleBox}>
            <h1>{solutionPopUp.title}</h1>
          </div>
          <button className={classes.solutionCloseButton} type="button" onClick={exitReferenceCase}>
            <img src={closeButton} alt="" />
          </button>
        </div>
        <div className={classes.solutionDescriptionBox}>
          <div className={classes.solutionDescriptionContent}>
            <div className={classes.sectionTitle}>
              <h3>{solutionPopUp.paragraphTitle}</h3>
              <div className={classes.sectionLine} />
            </div>
            <div className={classes.content}>
              <h4>{ReactHtmlParser(solutionPopUp.paragraphText)}</h4>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.solutionBottomBox}>
        <div className={classes.solutionBottomContainer}>
          <div className={classes.solutionBottomTextContainer}>
            {solutionPopUp.sectionFirstTitle && <h3>{solutionPopUp.sectionFirstTitle}</h3>}
            {solutionPopUp.smallText && <p>{solutionPopUp.smallText}</p>}
            {solutionPopUp.sectionList && (
              <div>
                {solutionPopUp.sectionList.map((listItem) => (
                  <div className={classes.sectionListItem}>
                    {listItem.list && (
                      <>
                        <h3>{listItem.title}</h3>
                        <ul>
                          {listItem.list.map((item) => (
                            <li>{item}</li>
                          ))}
                        </ul>
                      </>
                    )}
                    {listItem.content && (
                      <>
                        <h4>{listItem.title}</h4>
                        <h4 className={classes.listContent}>{listItem.content}</h4>
                      </>
                    )}
                  </div>
                ))}
              </div>
            )}
            {solutionPopUp.list && (
              <div className={classes.solutionListContainer}>
                {solutionPopUp.sectionFirstSubtitle
                  && (
                    <h3>
                      {solutionPopUp.sectionFirstSubtitle}
                    </h3>
                  )}
                {solutionPopUp.otherParagraph && <h4>{solutionPopUp.otherParagraph}</h4>}
                <ul className={classes.ordinaryList}>
                  {solutionPopUp.list.map((itemList) => (
                    <li key={itemList.item}>{ReactHtmlParser(itemList.item)}</li>
                  ))}
                </ul>

              </div>
            )}
            {solutionPopUp.secondList && (
              <>
                {solutionPopUp.sectionSecondTitle && <h3>{solutionPopUp.sectionSecondTitle}</h3>}
                <div className={classes.solutionListContainer}>
                  {solutionPopUp.sectionSecondSubtitle
                    && <h3>{solutionPopUp.sectionSecondSubtitle}</h3>}
                  <ul>
                    {solutionPopUp.secondList.map((itemList) => (
                      <li key={itemList.item}>{ReactHtmlParser(itemList.item)}</li>
                    ))}
                  </ul>
                  {solutionPopUp.sectionThirdSubtitle
                    && <h3>{solutionPopUp.sectionThirdSubtitle}</h3>}
                </div>
              </>
            )}
          </div>
          <div className={classes.solutionBottomImageContainer}>
            <img src={solutionPopUp.image} alt="" />
          </div>
        </div>
        {solutionPopUp.warningText && (
          <p className={classes.warningItem}>
            {solutionPopUp.warningText}
          </p>
        )}
      </div>
      {solutionPopUp.swiperImage && (
        <div className={classes.solutionSwiperSection}>
          <div className={classes.solutionsTitle}>
            <h3>{solutionPopUp.swiperTitle}</h3>
            <div className={classes.sectionLine} />
          </div>
          <div className={classes.swiperSimpleImage}>
            <img src={solutionPopUp.swiperImage} alt="" />
          </div>
        </div>
      )}
      {solutionPopUp.swiperSimple && (
        <div className={classes.solutionSwiperSectionImage}>
          <div className={classes.solutionsTitle}>
            <h3>{solutionPopUp.swiperTitle}</h3>
            <div className={classes.sectionLine} />
          </div>
          <div className={classes.solutionSwiperSimple}>
            {solutionPopUp.swiperSimple.map((swipeImage) => (
              <div className={classes.solutionContent}>
                <div className={classes.imageBoxSimple}>
                  <img src={swipeImage.image} alt="" />
                </div>
                <h4>{swipeImage.text}</h4>
              </div>
            ))}
          </div>
        </div>
      )}
      {solutionPopUp.swiper && (
        <SolutionPopUpSwiper
          windowSize={windowSize}
          items={solutionPopUp.swiper}
          label={solutionPopUp.swiperTitle}
          marginTop="2rem"
          marginBottom="3rem"
        />
      )}
      {solutionPopUp.swiperSimple && (
        <div className={classes.swiperImages}>
          <SolutionPopUpSwiper
            windowSize={windowSize}
            items={solutionPopUp.swiperSimple}
            label={solutionPopUp.swiperTitle}
            marginTop="2rem"
            marginBottom="3rem"
          />
        </div>
      )}
      <RedirectToTop />
    </div>

  );
};

export default SolutionCasePopUp;
