import React from 'react';
import { makeStyles } from '@material-ui/core';
import { bannerColor } from '../../colors';
import {
  CONTACT_TITLE, CONTACT_DESCRIPTION, CONTACT_PHONE_NUMBER, CONTACT_EMAIL_ADDRESS,
} from '../Footer/contactItems';
import dropButton from '../../assets/images/icons/home/scroll-icon.svg';
import phoneIcon from '../../assets/images/icons/home/phone-icon.svg';
import mailIcon from '../../assets/images/icons/home/email-icon.svg';

const useStyles = makeStyles(() => ({
  heroSectionContainer: {
    position: (props) => props.positionSection,
    gridColumnStart: (props) => props.gridColumnStart,
    gridColumnEnd: (props) => props.gridColumnEnd,
    gridRowStart: (props) => props.gridRowStart,
    gridRowEnd: (props) => props.gridRowEnd,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      justifyContent: 'flex-start',
    },
  },
  heroSectionContact: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  heroSectionContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'absolute',
    '@media only screen and (max-width: 67.5em)': {
      marginTop: '4%',
      width: '34.5rem',
      height: '34.5rem',
    },
    '@media only screen and (max-device-width: 67.5em)': {
      marginTop: '4%',
      width: '34.5rem',
      height: '34.5rem',
    },
    '@media(min-width:67.5em)': {
      marginTop: '2%',
      right: '10%',
      width: '32.5rem',
      height: '32.5rem',
    },
    '@media(min-device-width:67.5em)': {
      marginTop: '2%',
      right: '10%',
      width: '32.5rem',
      height: '32.5rem',
    },
    '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em) and (orientation: landscape)':
    {
      marginTop: '1%',
      width: '40.6rem',
      height: '40.6rem',
      right: 'auto',
    },
    '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em) and (orientation: portrait)':
    {
      width: '40.6rem',
      height: '40.6rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      width: '58vh',
      height: '58vh',
      marginTop: '10%',
    },
    '@media(max-width:47.938em) and (orientation: portrait)': {
      width: '27.9rem',
      height: '27.9rem',
    },
    '@media(min-width:100em)': {
      marginTop: '2.8%',
      right: '15%',
      width: '34.5rem',
      height: '34.5rem',
    },
    '@media only screen and (max-device-height : 47em) and (min-width : 100em) and (orientation: landscape)':
    {
      marginTop: '10%',
    },
    '@media only screen and (max-height : 47em) and (min-device-width : 85em) and (orientation: landscape)':
    {
      marginTop: '1%',
      width: '30rem',
      height: '30rem',
    },
    '@media only screen and (max-height : 47em) and (min-device-width : 120em) and (orientation: landscape)':
    {
      marginTop: '3%',
      width: '27rem',
      height: '27rem',
    },
    '@media only screen and (max-height : 40em) and (min-device-width : 85em) and (orientation: landscape)':
    {
      marginTop: '1%',
      width: '24rem',
      height: '24rem',
    },
    '@media only screen and (max-device-height : 43.75em) and (min-device-width : 70em) and (orientation: landscape)':
    {
      marginTop: '10%',
      width: '30rem',
      height: '30rem',
    },
    '@media(min-width:150em)': {
      marginTop: '2.8%',
      right: '15%',
    },
    '@media only screen and (max-device-width: 20em)': {
      marginTop: '5%',
      width: '24rem',
      height: '24rem',
    },
  },
  heroSectionContactEffect: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'absolute',
    '@media(min-width:67.5em)': {
      marginTop: '2%',
      right: '10%',
      width: '32.5rem',
      height: '32.5rem',
    },
    '@media(min-device-width:67.5em)': {
      marginTop: '2%',
      right: '10%',
      width: '32.5rem',
      height: '32.5rem',
    },
    '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em) and (orientation: landscape)':
    {
      marginTop: '1%',
      width: '40.6rem',
      height: '40.6rem',
      right: 'auto',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      width: '58vh',
      height: '58vh',
      marginTop: '10%',
    },
    '@media(min-width:100em)': {
      marginTop: '2.8%',
      right: '15%',
      width: '34.5rem',
      height: '34.5rem',
    },
    '@media only screen and (max-device-height : 47em) and (min-width : 100em) and (orientation: landscape)':
    {
      marginTop: '10%',
    },
    '@media only screen and (max-height : 47em) and (min-device-width : 85em) and (orientation: landscape)':
    {
      marginTop: '1%',
      width: '30rem',
      height: '30rem',
    },
    '@media only screen and (max-height : 47em) and (min-device-width : 120em) and (orientation: landscape)':
    {
      marginTop: '3%',
      width: '27rem',
      height: '27rem',
    },
    '@media only screen and (max-height : 40em) and (min-device-width : 85em) and (orientation: landscape)':
    {
      marginTop: '1%',
      width: '24rem',
      height: '24rem',
    },
    '@media only screen and (max-device-height : 43.75em) and (min-device-width : 70em) and (orientation: landscape)':
    {
      marginTop: '10%',
      width: '30rem',
      height: '30rem',
    },
    '@media(min-width:150em)': {
      marginTop: '2.8%',
      right: '15%',
    },
    '@media only screen and (max-width: 67.5em)': {
      display: 'none',
    },
    '@media only screen and (max-device-width: 67.5em)': {
      display: 'none',
    },
  },
  heroSectionVideo: {
    overflow: 'hidden',
    width: '100%',
    height: '100vh',
    '@media only screen and (max-device-width: 67.5em)': {
      objectPosition: '20% center',
    },
    '@media only screen and (max-device-width: 40.625em)': {
      objectPosition: '20% center',
    },
  },
  heroSectionImageContainer: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  backgroundEffect: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '0rem 6.896rem 0rem 0rem',
    background: 'rgba(0, 91, 172, 1) 0% 0% no-repeat padding-box',
    mixBlendMode: 'multiply',
    zIndex: '2',
  },
  heroSectionTitle: {
    zIndex: '2',
    width: '68%',
    marginLeft: '20%',
    height: '100%',
    lineHeight: '1.4',
    color: bannerColor,
    display: 'flex',
    flexDirection: 'column',
    '& h1': {
      '@media only screen and (min-device-width:48em) and  (max-device-width: 67.5em)': {
        fontSize: '3.5rem',
      },
      '@media only screen and (max-device-height : 30em) and (min-device-width :30em) and (orientation: landscape)': {
        fontSize: '2.2rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)': {
        fontSize: '2.2rem',
      },
      '@media only screen and (max-device-height : 35em) and (orientation: landscape)': {
        fontSize: '1.55rem',
      },
      '@media only screen and (max-device-width: 20em)': {
        fontSize: '2.2rem',
      },
      '@media only screen and (max-device-height : 47em) and (min-device-width : 60em) and (orientation: landscape)':
      {
        fontSize: '2.5rem',
      },
    },
  },
  title: {
    marginTop: '6rem',
    '@media (max-width: 67.5em)': {
      marginTop: '5rem',
    },
    '@media (max-device-width: 67.5em)': {
      marginTop: '6rem',
    },
    '@media (max-width: 40.625em)': {
      marginTop: '5rem',
    },

    '@media only screen and (max-height : 40em) and (orientation: landscape)':
    {
      marginTop: '3rem',
    },
    '@media only screen and (max-height : 47em) and (min-device-width : 85em) and (orientation: landscape)':
    {
      marginTop: '3rem',
    },
  },
  heroSectionSubtitle: {
    fontWeight: 'normal',
    marginTop: '2rem',
    '@media (max-device-width: 67.5em)': {
      marginTop: '2.6rem',
    },
    '@media (max-width: 48em)': {
      marginTop: '2.3rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      marginTop: '2rem',
    },
  },
  dropButton: {
    width: '10%',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
  },
  dropButtonLink: {
    position: 'absolute',
    bottom: '0',
    height: '6.25rem',
    '@media only screen and (max-height : 43.75em) and (min-device-width : 85em) and (orientation: landscape)':
    {
      height: '4rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      height: '2.5rem',
    },
    '@media only screen and (max-device-height : 47em) and (min-device-width : 67.5em) and (orientation: landscape)':
    {
      height: '4rem',
    },
  },
  dropButtonImage: {
    height: '100%',
  },
  heroSectionImageBox: {
    width: '57.5%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    '@media only screen and (max-width : 67.5em)':
    {
      width: '100%',
    },
    '@media only screen and (max-device-width : 67.5em)':
    {
      width: '100%',
    },
  },
  heroSectionContactDetails: {
    width: '42.5%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: bannerColor,
    '@media(max-width:67.5em)': {
      display: 'none',
    },
    '@media(max-device-width:67.5em)': {
      display: 'none',
    },
  },
  heroSectionContactBox: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '80%',
    marginLeft: '10%',
  },
  heroSectionTitleBox: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
    '& h4': {
      fontWeight: 'normal',
      margin: '1.5rem 0',
    },
  },
  heroSectionSubtitleBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  heroSectionContactDetail: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: '6rem',
    '@media(max-width:120em)': {
      marginRight: '3rem',
    },
    '@media (min-width: 67.5em) and (max-width:80em)': {
      marginRight: '1rem',
    },
    '& p': {
      '@media (min-width: 67.5em) and (max-width:80em)': {
        fontSize: '1rem',
      },
    },
  },
  heroSectionContactDetailItem: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-start',

  },
  heroSectionContactIconImage: {
    height: '1.5rem',
    width: '1.5rem',
    marginRight: '0.7rem',
    '& img': { width: '100%', height: '100%' },
  },
}));

const HeroSection = ({
  deviceSize,
  headerRef,
  subtitle,
  video,
  videoFormat,
  safariAgent,
  content4k,
  content2k,
  HDcontent,
  mobileContent,
  tabletContent,
  sectionId,
  contactPage,
  ...props
}) => {
  const classes = useStyles(props);
  const getImageByDeviceSize = () => {
    if (deviceSize.width <= '650') {
      return mobileContent;
    }
    if (deviceSize.width > '650' && deviceSize.width <= '1080') {
      return tabletContent;
    }
    if (deviceSize.width >= '2000') {
      return content2k;
    }
    if (deviceSize.width >= '4000') {
      return content4k;
    }
    return HDcontent;
  };

  const scrollDownBtn = () => {
    const heroSectionElement = document.getElementById('hero-section');
    const scrollBy = heroSectionElement.offsetHeight
      - headerRef.current.offsetHeight - window.scrollY;
    window.scrollBy(0, scrollBy);
  };

  return (
    <div id="hero-section" className={classes.heroSectionContainer}>
      {(mobileContent && !contactPage) && (
        <>
          <div className={classes.heroSectionImageContainer}>
            <img className={classes.image} src={getImageByDeviceSize()} alt="" />
          </div>
        </>
      )}
      {video
        && (
          <video autoPlay muted loop playsInline className={classes.heroSectionVideo}>
            <source
              src={video}
              type={videoFormat === 'webm' ? 'video/webm' : 'video/mp4'}
            />
          </video>
        )}
      {contactPage ? (
        <>
          <div className={classes.heroSectionContact}>
            <div className={classes.heroSectionContactDetails}>
              <div className={classes.heroSectionContactBox}>
                <div className={classes.heroSectionTitleBox}>
                  <h2>{CONTACT_TITLE}</h2>
                  <h4>{CONTACT_DESCRIPTION}</h4>
                </div>
                <div className={classes.heroSectionSubtitleBox}>
                  <div className={classes.heroSectionContactDetail}>
                    <h4>Phone</h4>
                    <div className={classes.heroSectionContactDetailItem}>
                      <div className={classes.heroSectionContactIconImage}>
                        <img src={phoneIcon} alt="" />
                      </div>
                      <p>{CONTACT_PHONE_NUMBER}</p>
                    </div>
                  </div>
                  <div className={classes.heroSectionContactDetail}>
                    <h4>Email</h4>
                    <div className={classes.heroSectionContactDetailItem}>
                      <div className={classes.heroSectionContactIconImage}>
                        <img src={mailIcon} alt="" />
                      </div>
                      <p>{CONTACT_EMAIL_ADDRESS}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.heroSectionImageBox}>
              <img className={classes.image} src={getImageByDeviceSize()} alt="" />
            </div>
          </div>
          <div className={classes.heroSectionContactEffect}>
            <div className={classes.backgroundEffect} />
            <div className={classes.heroSectionTitle}>
              <h1 className={classes.title}>Neusoft Technology Solutions</h1>
              <h1 className={classes.heroSectionSubtitle}>{subtitle}</h1>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classes.heroSectionContent}>
            <div className={classes.backgroundEffect} />
            <div className={classes.heroSectionTitle}>
              <h1 className={classes.title}>Neusoft Technology Solutions</h1>
              <h1 className={classes.heroSectionSubtitle}>{subtitle}</h1>
            </div>
          </div>
        </>
      )}
      {video && (
        <div className={classes.dropButton}>
          <a
            href="#home"
            onClick={scrollDownBtn}
            className={classes.dropButtonLink}
          >
            <img
              className={classes.dropButtonImage}
              src={dropButton}
              alt="Press"
            />
          </a>
        </div>
      )}
    </div>
  );
};

export default HeroSection;
