import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination } from 'swiper';
import 'swiper/swiper.scss';
import '../../global/swiperPagination.scss';
import Banner from '../../components/HomeSection/Banner';
import TitleSection from '../../components/Careers/TitleSection';
import HeroSection from '../../components/HomeSection/HeroSection';
import SliderWeb from '../../components/HomeSection/SliderWeb';
import HomeService from '../../components/HomeSection/HomeService';
import RedirectToTop from '../../components/Footer/RedirectToTop';
import SolutionPopUp from '../../components/Solutions/SolutionPopUp';
import {
  solutionsContent,
  solutionsSwiperContent,
} from '../../components/Solutions/solutionsItems';
import UseScrollToTop from '../../hooks/UseScrollToTop';
import { bannerColor } from '../../colors';

const useStyles = makeStyles(() => ({
  solutionsContainer: {
    overflow: 'hidden',
    minHeight: '100vh',
    width: '100%',
    position: 'relative',
  },
  webSlider: {
    display: 'flex',
    '@media(max-width:67.5em)': {
      display: 'none',
    },
    '@media(max-device-width:67.5em)': {
      display: 'none',
    },
  },
  tabletSlider: {
    display: 'none',
    '@media(max-width:67.5em)': {
      display: 'flex',
    },
    '@media(max-device-width:67.5em)': {
      display: 'flex',
    },
  },
}));

const Solutions = ({
  deviceSize,
  windowSize,
  subtitle,
  video,
  content4k,
  content2k,
  HDcontent,
  mobileContent,
  tabletContent,
  bannerTitle,
  items,
  label,
  titleParagraph,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const solutionPath = location.pathname.split('/')[2];

  return (
    <div className={classes.solutionsContainer}>
      {!solutionPath && (
        <>
          <HeroSection
            deviceSize={deviceSize}
            positionSection="relative"
            subtitle={subtitle}
            video={video}
            content4k={content4k}
            content2k={content2k}
            HDcontent={HDcontent}
            mobileContent={mobileContent}
            tabletContent={tabletContent}
            justifyContent="flex-start"
            margin="2.5rem"
          />
          <TitleSection
            windowSize={windowSize}
            items={items}
            label={label}
            marginTop="7rem"
            marginBottom="3rem"
            titleParagraph={titleParagraph}
          />
          <Banner
            minHeight="18rem"
            minHeightTablet="18rem"
            title={bannerTitle}
            content=""
            image=""
            bannerColor={bannerColor}
            justifyContent="center"
          />
          <div className={classes.webSlider}>
            <SliderWeb
              content={solutionsContent}
            />
          </div>
          <div className={classes.tabletSlider}>
            <Swiper
              modules={[Pagination]}
              pagination={{ clickable: true }}
              spaceBetween={0}
              grabCursor
              slidesPerView={1}
            >
              {solutionsSwiperContent.map((homeService) => (
                <SwiperSlide key={homeService.title}>
                  <HomeService
                    title={homeService.title}
                    subtitle={homeService.subtitle}
                    titleDescription={homeService.titleDescription}
                    items={homeService.items}
                    URL={homeService.URL}
                    image={homeService.image}
                    displayWrapper={homeService.displayWrapper}
                    alignItemsWrapper={homeService.alignItemsWrapper}
                    order={homeService.order}
                    height={homeService.height}
                    justifyContent={homeService.justifyContent}
                    color={homeService.color}
                    hoverBackgroundColor={homeService.hoverBackgroundColor}
                    titleColor={homeService.titleColor}
                    hoverTitleColor={homeService.hoverTitleColor}
                    alignItems={homeService.alignItems}
                    alignSubtitle={homeService.alignSubtitle}
                    background={homeService.background}
                    width={homeService.width}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      )}
      {solutionPath && (
        <SolutionPopUp
          windowSize={windowSize}
          pathSolution={solutionPath}
        />
      )}
      <RedirectToTop />
      <UseScrollToTop />
    </div>
  );
};

export default Solutions;
