import React from 'react';
import { makeStyles } from '@material-ui/core';
import { bannerColor, white, neusoftBlue } from '../../colors';
import UseScrollToTop from '../../hooks/UseScrollToTop';
import HeroSection from '../../components/HomeSection/HeroSection';
import RedirectToTop from '../../components/Footer/RedirectToTop';
import brezelIcon from '../../assets/images/icons/contact/icons8-brezel-100.svg';
import fishIcon from '../../assets/images/icons/contact/icons8-fish-100.svg';
import phoneIcon from '../../assets/images/icons/contact/phone-icon.svg';
import mailIcon from '../../assets/images/icons/contact/email-icon.svg';
import contactHD from '../../assets/images/contact/contact-full-HD.jpg';
import contact2k from '../../assets/images/contact/contact-2k.jpg';
import contact4k from '../../assets/images/contact/contact-4k.jpg';
import contactMobile from '../../assets/images/contact/contact-mobile.jpg';
import contactTablet from '../../assets/images/contact/contact-tablet.jpg';

import {
  CONTACT_TITLE,
  CONTACT_DESCRIPTION,
  CONTACT_PHONE_NUMBER,
  CONTACT_EMAIL_ADDRESS,
  CONTACT_DETAIL,
  CONTACT_HAMBURG_OFFICE_NAME,
  CONTACT_MUNICH_OFFICE_NAME,
} from '../../components/Footer/contactItems';

const useStyles = makeStyles(() => ({
  contactsContainer: {
    overflow: 'hidden',
    minHeight: '100vh',
    width: '100%',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '85vh 15vh 1fr',
    '@media(max-width: 100em)': {
      gridTemplateRows: '90vh 10vh 1fr',
    },
    '@media(max-width: 67.5em)': {
      gridTemplateColumns: '7.5% 85% 7.5%',
    },
    '@media(max-device-width: 67.5em)': {
      gridTemplateColumns: '7.5% 85% 7.5%',
    },
    '@media(max-width: 40em)': {
      gridTemplateRows: '85vh 15vh 1fr',
    },
    '@media (max-width: 25em)': {
      gridTemplateRows: '95vh 5vh 1fr',
    },
    '@media (min-device-height: 43.75em) and (max-device-height: 53.125em) and (orientation: portrait)': {
      gridTemplateRows: '75vh 25vh 1fr',
    },
    '@media only screen and (max-device-height : 43.75em) and (orientation: landscape)':
    {
      gridTemplateRows: '95vh 5vh 1fr',
    },
  },
  contactBox: {
    gridColumnStart: '1',
    gridRowStart: '3',
    gridRowEnd: '4',
    width: '100%',
    backgroundColor: bannerColor,
    '@media(max-width: 67.5em)': {
      gridColumnStart: '1',
      gridColumnEnd: '4',
    },
    '@media(max-device-width: 67.5em)': {
      gridColumnStart: '1',
      gridColumnEnd: '4',
    },
  },
  contactDetailsBox: {
    gridColumnStart: '1',
    gridRowStart: '2',
    gridRowEnd: '4',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: '0',
    right: '6.5%',
    width: '87%',
    margin: '0 auto',
    height: '62rem',
    backgroundColor: white,
    boxShadow: '0px 10px 20px #00002ECC',
    '& h2': {
      marginBottom: '5%',
      marginTop: '8%',
      '@media(max-width: 67.5em)': {
        marginBottom: '10%',
        marginTop: '17%',
      },
      '@media(max-device-width: 67.5em)': {
        marginBottom: '10%',
        marginTop: '17%',
      },
      '@media (max-width: 40em)': {
        fontSize: '1.7rem',
        marginTop: '40%',
      },
      '@media only screen and (max-device-height : 43.75em) and (orientation: landscape)':
      {
        fontSize: '1.7rem',
        marginBottom: '5%',
      },
    },
    '@media(max-width: 67.5em)': {
      height: 'auto',
      gridColumnStart: '2',
      gridColumnEnd: '3',
      right: '0',
      margin: '0',
      width: '100%',
      boxShadow: 'none',
    },
    '@media(max-device-width: 67.5em)': {
      height: 'auto',
      gridColumnStart: '2',
      gridColumnEnd: '3',
      right: '0',
      margin: '0',
      width: '100%',
      boxShadow: 'none',
    },
  },
  contactDetailsAdressBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
    height: 'auto',
    '& h2': {
      '@media (min-device-width: 48em) and (max-device-width: 67.5em)':
        { fontSize: '3rem' },
      '@media only screen and (max-device-height : 43.75em) and (orientation: landscape)':
      {
        fontSize: '1.7rem',
      },
    },
  },
  contactDetailsAdressContainerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '85%',
    margin: '0 auto',
    marginBottom: '5%',
    height: 'auto',
    '@media(max-width: 67.5em)': {
      marginBottom: '4rem',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '@media(max-device-width: 67.5em)': {
      marginBottom: '4rem',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '@media (max-width: 40em)': {
      width: '95%',
      marginBottom: '6rem',
    },
    '@media (min-width: 120em)': {
      width: '75%',
    },
  },
  contactDetailsAdressContainer: {
    display: 'flex',
    width: '32rem',
    height: 'auto',
    '@media(max-width: 67.5em)': {
      marginBottom: '7rem',
      width: 'auto',
    },
    '@media(max-device-width: 67.5em)': {
      marginBottom: '7rem',
      width: 'auto',
    },
    '@media (max-width: 40em)': {
      marginBottom: '5rem',
    },
    '@media only screen and (max-device-height : 43.75em) and (orientation: landscape)':
    {
      marginBottom: '3rem',
      width: '60%',
    },
  },
  detailIcon: {
    height: 'auto',
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 40em)': {
      '& img': { width: '3.4rem', height: '3.4rem' },
    },
  },
  detailLine: {
    marginTop: '1.3rem',
    height: '10.125rem',
    width: '0.2rem',
    backgroundColor: neusoftBlue,
    '@media (max-device-width: 67.5em)': {
      marginTop: '1.5rem',
      height: '10rem',
    },
    '@media (max-width: 40em)': {
      height: '7rem',
      marginTop: '1.5rem',
    },
    '@media only screen and (max-device-height : 43.75em) and (orientation: landscape)':
    {
      height: '9.3rem',
      marginTop: '1.5rem',

    },
  },
  addressDetails: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    width: '85%',
    paddingLeft: '5rem',
    '& h4': {
      '@media (min-device-width: 48em) and (max-device-width: 67.5em)': {
        fontSize: '1.6rem',
      },
      '@media only screen and (max-device-height : 43.75em) and (orientation: landscape)':
      {
        fontSize: '1.6rem',
      },
    },
    '@media(max-width: 67.5em)': {
      width: '100%',
      paddingLeft: '5rem',
    },
    '@media(max-device-width: 67.5em)': {
      width: '100%',
      paddingLeft: '5rem',
    },
    '@media (max-width: 40em)': {
      paddingLeft: '2rem',
    },
    '@media only screen and (max-device-height : 43.75em) and (orientation: landscape)':
    {
      paddingLeft: '3.5rem',
    },
  },
  addressName: { marginBottom: '2rem' },
  addressDetail: {
    fontWeight: 'normal',
  },
  contactDetailsContainer: {
    display: 'none',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '20% 1fr',
    gridColumnStart: '2',
    gridColumnEnd: '3',
    gridRowStart: '1',
    gridRowEnd: '2',
    '@media only screen and (max-device-height : 43.75em) and (orientation: landscape)':
    {
      gridTemplateRows: '25% 1fr',
    },
    '@media (max-width: 67.5em)': {
      display: 'grid',
    },
    '@media (max-device-width: 67.5em)': {
      display: 'grid',
    },
  },
  heroSectionContactDetails: {
    gridColumnStart: '1',
    gridRowStart: '2',
    gridRowEnd: '3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: bannerColor,
    '@media (max-width: 40em)': {
      alignItems: 'center',
    },
  },
  heroSectionContactBox: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '80%',
    marginLeft: '15%',
    '@media(max-width: 67.5em)': {
      marginLeft: '10%',
      width: '75%',
    },
    '@media(max-device-width: 67.5em)': {
      marginLeft: '10%',
      width: '75%',
    },
    '@media (max-width: 40em)': {
      marginLeft: '0',
      width: '85%',
    },
    '@media only screen and (max-device-height : 43.75em) and (orientation: landscape)':
    {
      marginLeft: '0',
      width: '85%',
    },
  },
  heroSectionTitleBox: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
    '@media (min-device-width: 48em) and (max-device-width: 67.5em)': {
      marginBottom: '10rem',
    },
    '& h4': {
      fontWeight: 'normal',
      margin: '1.5rem 0',
      '@media (min-device-width: 48em) and (max-device-width: 67.5em)': {
        fontSize: '1.9rem',
      },
      '@media (max-width: 40em)': {
        textAlign: 'center',
        fontSize: '1.2rem',
      },
      '@media only screen and (max-device-height : 43.75em) and (orientation: landscape)':
      {
        textAlign: 'center',
        fontSize: '1.2rem',
      },
    },
    '& h2': {
      '@media (min-device-width: 48em) and (max-device-width: 67.5em)': {
        fontSize: '3rem',
      },
      '@media (max-width: 40em)': {
        fontSize: '1.7rem',
        textAlign: 'center',
      },
      '@media only screen and (max-device-height : 43.75em) and (orientation: landscape)':
      {
        fontSize: '1.7rem',
        textAlign: 'center',
      },
    },
    '@media (max-width: 40em) and (orientation: portrait)': {
      marginBottom: '3rem',
    },
    '@media only screen and (max-device-height : 43.75em) and (orientation: landscape)':
    {
      marginBottom: '1rem',
    },
  },
  heroSectionSubtitleBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    '@media(max-width: 67.5em)': {
      justifyContent: 'space-between',
    },
    '@media(max-device-width: 67.5em)': {
      justifyContent: 'space-between',
    },
    '@media (max-width: 40em) and (orientation: portrait)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  heroSectionContactDetail: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: '6rem',
    '@media(max-width:120em)': {
      marginRight: '3rem',
    },
    '@media (min-width: 67.5em) and (max-width:80em)': {
      marginRight: '0.5rem',
    },
    '& h4': {
      '@media (min-device-width: 48em) and (max-device-width: 67.5em)': {
        fontSize: '1.9rem',
      },
      '@media (max-width: 40em)': {
        fontSize: '1.7rem',
      },
      '@media only screen and (max-device-height : 43.75em) and (orientation: landscape)':
      {
        fontSize: '1.7rem',
      },
    },
    '@media (max-width: 40em) and (orientation: portrait)': {
      marginRight: '0',
      marginBottom: '3rem',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '@media only screen and (max-device-height : 43.75em) and (orientation: landscape)':
    {
      marginRight: '0',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '@media (max-device-width: 40em) and (orientation: landscape)': {
      marginBottom: '2rem',
    },
  },
  heroSectionContactDetailItem: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-start',
    '@media (max-width: 40em) and (orientation: portrait)': {
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '3rem',
    },
    '& p': {
      '@media (min-device-width: 48em) and (max-device-width: 67.5em)': {
        fontSize: '1.35rem',
      },
    },
  },
  heroSectionContactIconImage: {
    height: '100%',
    width: '1.5rem',
    marginRight: '0.7rem',
    '@media (min-width: 67.5em) and (max-width:80em)': {
      width: '1rem',
    },
    '& img': { width: '100%', height: '100%' },
  },
}));

const Contacts = ({
  deviceSize,
  subtitle,
  video,
  webContent,
  mobileContent,
  tabletContent,
  contactPage,
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <div className={classes.contactsContainer}>
      <HeroSection
        deviceSize={deviceSize}
        subtitle={subtitle}
        video={video}
        content4k={contact4k}
        content2k={contact2k}
        HDcontent={contactHD}
        mobileContent={contactMobile}
        tabletContent={contactTablet}
        justifyContent="flex-start"
        margin="2.5rem"
        contactPage="true"
        gridColumnStart="1"
        gridColumnEnd="4"
        gridRowStart="1"
        gridRowEnd="3"
      />
      <div className={classes.contactBox} />

      <div className={classes.contactDetailsContainer}>
        <div className={classes.heroSectionContactDetails}>
          <div className={classes.heroSectionContactBox}>
            <div className={classes.heroSectionTitleBox}>
              <h2>{CONTACT_TITLE}</h2>
              <h4>{CONTACT_DESCRIPTION}</h4>
            </div>
            <div className={classes.heroSectionSubtitleBox}>
              <div className={classes.heroSectionContactDetail}>
                <h4>Phone</h4>
                <div className={classes.heroSectionContactDetailItem}>
                  <div className={classes.heroSectionContactIconImage}>
                    <img src={phoneIcon} alt="" />
                  </div>
                  <p>{CONTACT_PHONE_NUMBER}</p>
                </div>
              </div>
              <div className={classes.heroSectionContactDetail}>
                <h4>Email</h4>
                <div className={classes.heroSectionContactDetailItem}>
                  <div className={classes.heroSectionContactIconImage}>
                    <img src={mailIcon} alt="" />
                  </div>
                  <p>{CONTACT_EMAIL_ADDRESS}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.contactDetailsBox}>
        <div className={classes.contactDetailsAdressBox}>
          <h2>
            {CONTACT_DETAIL}
          </h2>
          <div className={classes.contactDetailsAdressContainerBox}>
            <div className={classes.contactDetailsAdressContainer}>
              <div className={classes.detailIcon}>
                <img src={fishIcon} alt="" />
                <div className={classes.detailLine} />
              </div>
              <div className={classes.addressDetails}>
                <h4 className={classes.addressName}>{CONTACT_HAMBURG_OFFICE_NAME}</h4>
                <h4 className={classes.addressDetail}>
                  Neusoft Technology
                  <br />
                  Solutions GmbH
                  <br />
                  Hugh-Greene-Weg 2-4
                  <br />
                  22529 Hamburg – Germany
                </h4>
              </div>
            </div>
            <div className={classes.contactDetailsAdressContainer}>
              <div className={classes.detailIcon}>
                <img src={brezelIcon} alt="" />
                <div className={classes.detailLine} />
              </div>
              <div className={classes.addressDetails}>
                <h4 className={classes.addressName}>{CONTACT_MUNICH_OFFICE_NAME}</h4>
                <h4 className={classes.addressDetail}>
                  Neusoft Technology
                  <br />
                  Solutions GmbH
                  <br />
                  Stefan-George-Ring 2
                  <br />
                  81929 Munich – Germany
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RedirectToTop />
      <UseScrollToTop />
    </div>
  );
};

export default Contacts;
