import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  HOME_DESCRIPTION_TITLE,
  HOME_DESCRIPTION_PARAGRAPH1,
  HOME_DESCRIPTION_PARAGRAPH2,
  HOME_DESCRIPTION_PARAGRAPH3,
  ABOUT_URL,
} from './homeText';
import DescriptionComponent from './DescriptionComponent';
import MottoComponent from './MottoComponent';

const useStyles = makeStyles(() => ({
  homeDescriptionContainer: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
    width: '87%',
    '@media only screen and (max-width: 67.5em)': {
      width: '100%',
    },
    '@media only screen and (max-device-width: 67.5em)': {
      width: '100%',
    },
  },
  homeDescriptionContent: {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'space-between',
    justifyContent: 'space-between',
    '@media only screen and (max-width: 67.5em)': {
      alignItems: 'flex-start',
      height: '100%',
      flexDirection: 'column',
    },
    '@media only screen and (max-device-width: 67.5em)': {
      alignItems: 'flex-start',
      height: '100%',
      flexDirection: 'column',
    },
  },
}));

const HomeDescription = ({ sectionId, ...props }) => {
  const classes = useStyles(props);

  return (
    <div className={classes.homeDescriptionContainer} id={sectionId}>
      <div className={classes.homeDescriptionContent}>
        <DescriptionComponent
          title={HOME_DESCRIPTION_TITLE}
          paragraph={HOME_DESCRIPTION_PARAGRAPH1}
          secondParagraph={HOME_DESCRIPTION_PARAGRAPH2}
          thirdParagraph={HOME_DESCRIPTION_PARAGRAPH3}
          URL={ABOUT_URL}
        />
        <MottoComponent />
      </div>
    </div>
  );
};

export default HomeDescription;
