/* eslint-disable react/self-closing-comp */
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  homeServiceContainer: {
    height: 'auto',
    zIndex: '2',
    width: '100%',
    background: (props) => props.background,
    '@media (max-width: 67.5em)': {
      height: '100.65rem',
    },
    '@media (max-width: 60em)': {
      height: '95.65rem',
    },
    '@media(max-width:50em)': {
      height: '90rem',
    },
    '@media(max-width:47.938em)': {
      height: '75rem',
    },
    '@media(max-width:45.938em)': {
      height: '65rem',
    },
    '@media (min-width: 67.5em)': {
      height: 'auto',
    },
    '@media (max-device-width: 67.5em)': {
      height: '107.65rem',
    },
    '@media (min-device-width:48em) and (max-device-width:67.5em) and  (orientation: landscape)': { height: '85.65rem' },
    '@media (max-device-height:45em) and  (orientation: landscape)': { height: '58.65rem' },
    '@media only screen and (max-device-height : 47em) and (min-device-width : 67.5em) and (orientation: landscape)':
    {
      height: 'auto',
    },
    '@media(max-device-width:40.625em)': {
      height: '70rem',
    },
    '@media(max-width:40.625em)': {
      height: '62rem',
    },
    '@media(max-device-width:20em)': {
      height: '50rem',
    },
  },
  homeServiceWrapper: {
    width: '87%',
    height: '100%',
    margin: '0 auto',
    display: (props) => props.displayWrapper,
    gridTemplateRows: (props) => props.gridTemplateRows,
    gridTemplateColumns: (props) => props.gridTemplateColumns,
    alignItems: (props) => props.alignItemsWrapper,
    justifyContent: (props) => props.justifyContentWrapper,
    '@media (max-width: 67.5em)': {
      flexDirection: 'column',
      justifyContent: (props) => props.justifyContent,
      width: '90%',
    },
    '@media (max-device-width: 67.5em)': {
      flexDirection: 'column',
      justifyContent: (props) => props.justifyContent,
      width: '90%',
    },
    '@media(max-width:47.938em)': {
      justifyContent: (props) => props.justifyContent,
    },
    '@media(max-device-width:20em)': {
      justifyContent: (props) => props.justifyContentWrapper,
    },
    '@media only screen and (max-device-height : 43.75em) and (orientation: landscape)':
    {
      justifyContent: (props) => props.justifyContent,
    },
    '@media only screen and (max-device-height : 43.75em) and (min-device-width : 70em) and (orientation: landscape)':
    {
      justifyContent: (props) => props.justifyContentWrapper,
    },
    '@media (min-width: 150em)': {
      width: '70%',
    },
    '@media (min-width: 200em)': {
      width: '68%',
    },
  },
  titleContainer: {
    gridRowStart: (props) => props.gridRowStartTitle,
    gridColumnStart: (props) => props.gridColumnStartTitle,
    gridColumnEnd: (props) => props.gridColumnEndTitle,
    width: 'auto',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '@media(max-width:67.5em)': { paddingRight: '0', width: 'auto', height: 'auto' },
    '@media(max-device-width:67.5em)': { paddingRight: '0', width: 'auto', height: 'auto' },
    '@media(max-width:47.938em)': {
      height: 'auto',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      height: 'auto',
    },
    '@media only screen and (max-device-height : 47em) and (min-device-width : 70em) and (orientation: landscape)':
    {
      height: '100%',
    },
    alignItems: (props) => props.alignItems,
    '& h1': {
      color: (props) => props.titleColor,
      fontWeight: '500',
      fontStyle: 'italic',
      padding: '0',
      marginRight: (props) => props.marginRightTitle,
      marginLeft: (props) => props.marginLeftTitle,
      marginBottom: '3rem',
      '@media(max-width:67.5em)': { margin: '3.125rem 0', fontSize: '3.7rem' },
      '@media(max-width:47.938em)': {
        fontSize: '1.98rem',
        fontWeight: 'bold',
        margin: '1.5rem 0',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.8rem',
        margin: '1.5rem 0',
      },
      '@media(min-width:67.5em)': { marginTop: '5rem' },
      '@media(min-width:85.375em)': { marginTop: '2rem' },
    },
  },
  image: {
    height: 'auto',
    width: '100%',
    marginBottom: '3.5rem',
    boxShadow: '0 10px  20px rgba(0, 0, 46, 0.8)',
    '@media(max-width:67.5em)': { width: '80%' },
    '@media(max-device-width:67.5em)': { height: '75%', width: '100%', marginBottom: '2.5rem' },
    '@media(min-width:67.5em)': { height: '60%', width: '100%' },
    '@media (min-device-width:48em) and (max-device-width:67.5em) and  (orientation: landscape)': { width: '100%', height: '36.188rem', marginBottom: '2.5rem' },
    '@media(min-width:85.375em)': { height: '36.188rem' },
    '@media(min-width:200em)': { height: '38rem' },
    '@media(max-device-width:47em) and (orientation: portrait)': { height: '75%', marginBottom: '1.5rem' },
    '@media only screen and (max-device-height : 48em) and (orientation: landscape)':
    {
      height: '23.7rem',
      width: 'auto',
      marginBottom: '2rem',
    },
    '@media only screen and (max-device-height : 47em) and (min-device-width : 67.5em) and (orientation: landscape)':
    {
      height: '35rem',
      width: '100%',
      marginBottom: '3.5rem',
    },
    '@media only screen and (max-device-height : 35em) and (min-device-width : 67.5em) and (orientation: landscape)':
    {
      height: '30rem',
      width: '100%',
      marginBottom: '3.5rem',
    },
    '@media(max-device-width:20em)': {
      height: '17.7rem',
      marginBottom: '2rem',
      width: '100%',
    },
    '& img': {
      height: '100%', width: '100%', objectFit: 'cover', display: 'block',
    },
  },
  homeRedirect: {
    marginTop: '3rem',
    marginBottom: '3rem',
    '@media(max-width:67.5em)': { marginBottom: '5rem' },
    '@media(max-device-width:67.5em)': { marginBottom: '5rem' },
    '@media(max-width:47.938em)': { marginTop: '1rem', marginBottom: '3rem' },
  },
  homeButton: {
    cursor: 'pointer',
    width: '10.5rem',
    border: '2px solid',
    borderColor: (props) => props.color,
    padding: '1rem 0.2rem',
    background: 'transparent',
    color: (props) => props.color,
    fontWeight: 'bold',
    fontSize: '1.125rem',
    textTransform: 'uppercase',
    '@media(max-device-width:40.625em)': {
      width: '9.2rem',
      fontSize: '1.35rem',
      marginBottom: '0',
      padding: '0.6rem 0.2rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      width: '8.5rem',
    },
    '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em) and (orientation: portrait)':
    {
      width: '12.45rem',
      fontSize: '1.35rem',
      padding: '1.3rem 0.2rem',
    },
    '@media only screen and (max-device-width : 20em)': {
      padding: '0.5rem 0.2rem',
      width: '10.5rem',
    },
    '&:hover': {
      background: (props) => props.hoverBackgroundColor,
      color: (props) => props.hoverTitleColor,
    },
  },
  subtitleContainer: {
    order: (props) => props.order,
    marginLeft: (props) => props.margin,
    gridRowStart: (props) => props.gridRowStartSubtitle,
    gridColumnStart: (props) => props.gridColumnStartSubtitle,
    gridColumnEnd: (props) => props.gridColumnEndSubtitle,
    width: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media(max-width:67.5em)': {
      width: '90%',
      margin: '0 auto',
      justifyContent: 'center',
    },
    '@media(max-device-width:67.5em)': {
      width: '90%',
      margin: '0 auto',
      justifyContent: 'center',
    },
    '@media(max-width:54em)': {
      width: '95%',
      margin: '0 auto',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      width: '80%',
      margin: '0 auto',
      justifyContent: 'center',
    },
    '@media only screen and (max-device-height : 30em) and (orientation: landscape)':
    {
      width: '70%',
      margin: '0 auto',
      justifyContent: 'center',
    },
    '@media only screen and (max-device-height : 47em) and (min-device-width : 70em) and (orientation: landscape)':
    {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
    },
  },
  subtitleBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: (props) => props.justifyContent,
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      marginTop: '1rem',
    },
  },
  descriptionContainer: {
    width: '100%',
    height: 'auto',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    '@media(max-width:67.5em)': {
      justifyContent: 'space-between',
      height: '100%',
    },
    '@media(max-device-width:67.5em)': {
      justifyContent: 'space-between',
      height: '100%',
    },
    '& ul': {
      paddingInlineStart: '0',
      listStyleType: 'disc',
      listStylePosition: 'outside',
      width: '100%',
      marginLeft: '1.5rem',
      textIndent: '0',
      '@media(max-width:67.5em)': {
        marginLeft: '2rem',
      },
      '@media(max-device-width:67.5em)': {
        marginLeft: '2rem',
      },
      '@media(max-width:47.938em)': {
        marginLeft: '1.5rem',
      },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        marginLeft: '1.3rem',
      },
    },
    '& button': {
      marginLeft: (props) => props.marginLeft,
    },
  },
  subtitle: {
    fontWeight: 'bold',
    marginBottom: '2rem',
    marginTop: '10rem',
    marginLeft: (props) => props.marginLeft,
    color: (props) => props.color,
    '@media(max-width:67.5em)': {
      margin: '0',
      marginBottom: '1rem',
      fontSize: '2.2rem',
    },
    '@media(max-device-width:67.5em)': {
      margin: '0',
      marginBottom: '1rem',
      fontSize: '2.2rem',
    },
    '@media(max-width:47.938em)': {
      fontSize: '1.5rem',
      margin: '0',
      marginBottom: '1rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      fontSize: '1.5rem',
      marginBottom: '1rem',
    },
    '@media only screen and (max-device-height : 30em) and (orientation: landscape)':
    {
      fontSize: '1.5rem',
      marginBottom: '1rem',
    },
  },
  titleDescription: {
    fontWeight: 'normal',
    marginBottom: '2rem',
    marginLeft: (props) => props.marginLeft,
    color: (props) => props.color,
    '@media(max-width:67.5em)': {
      margin: '0',
    },
    '@media(max-device-width:67.5em)': {
      margin: '0',
    },
    '@media(max-width:47.938em)': {
      fontSize: '1.2rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      fontSize: '1.2rem',
      marginBottom: '0',
    },
    '@media only screen and (max-device-height : 47em) and (min-device-width : 70em) and (orientation: landscape)':
    {
      fontSize: '1.4rem',
      marginBottom: '0',
    },
  },
  listItemBox: {
    display: 'flex',
    alignItems: 'baseline',
    '@media(max-width:120em)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    '@media(min-width:120em)': {
      flexDirection: 'row',
      alignItems: 'baseline',
    },
    '& p': {
      width: 'auto',
      marginLeft: '0.5rem',
      fontStyle: 'italic',
      fontWeight: 'normal',
      fontSize: '1.05rem',
      color: (props) => props.color,
      '@media(min-width:67.5em) and (max-width:80em)': {
        fontSize: '1rem',
      },
      '@media(max-width:67.5em)': {
        padding: '0.15rem 0',
        fontSize: '1.2rem',
      },
      '@media(max-device-width:67.5em)': {
        padding: '0.15rem 0',
      },
      '@media(max-width:47.938em)': {
        display: 'none',
      },
      '@media(max-height:31.25em)': {
        display: 'none',
      },
    },
  },
  listItem: {
    '@media(max-width:67.5em)': {
      padding: '0.15rem 0',
      fontSize: '1.8rem',
    },
    '@media(min-width:67.50em)': { width: '22rem' },
    '@media(max-device-width:67.5em)': {
      padding: '0.15rem 0',
      width: 'auto',
    },
    '@media only screen and (max-height : 40em) and (orientation: landscape)':
    {
      fontSize: '1.18rem',
      padding: '0.15rem 0',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      padding: '0.15rem 0',
    },
    '@media only screen and (max-device-height : 30em) and (orientation: landscape)':
    {
      fontSize: '1.2rem',
      padding: '0.15rem 0',
    },
    '@media(max-width:47.938em)': {
      fontSize: '1.2rem',
    },
    '@media(min-width:85.375em)': { width: 'auto' },
    '@media(min-width:85.375em) and (max-width:100em)': { fontSize: '1.3rem' },
    '@media only screen and (max-device-height : 43.75em) and (min-device-width : 70em) and (orientation: landscape)':
    {
      fontSize: '1.5rem',
    },
    paddingLeft: '0.1rem',
    fontSize: '1.5rem',
    width: 'auto',
    margin: '0',
    fontWeight: 'normal',
    color: (props) => props.color,
    '& span': {
      marginRight: '2rem',
    },
  },
}));

const HomeService = ({
  title,
  subtitle,
  titleDescription,
  items,
  URL,
  image,
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <div className={classes.homeServiceContainer}>
      <div className={classes.homeServiceWrapper}>
        <div className={classes.titleContainer}>
          <h1>{title}</h1>
          <div className={classes.image}>
            <img src={image} alt="" />
          </div>
        </div>
        <div className={classes.subtitleContainer}>
          <div className={classes.subtitleBox}>
            <h3 className={classes.subtitle}>{subtitle}</h3>
            {titleDescription && (<h4 className={classes.titleDescription}>{titleDescription}</h4>)}
            <div className={classes.descriptionContainer}>
              <ul>
                {items.map((item) => (
                  <div className={classes.listItemBox} key={item.text}>
                    <li className={classes.listItem}>
                      {item.text}
                    </li>
                    <p>{ReactHtmlParser(item.subtext)}</p>
                  </div>
                ))}
              </ul>
              <Link className={classes.homeRedirect} to={URL}>
                <button type="button" className={classes.homeButton}>
                  More
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default HomeService;
