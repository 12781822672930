import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation } from 'swiper';
import 'swiper/swiper.scss';
import '../../global/swiperNavigation.scss';
import { neusoftBlue, white } from '../../colors';
import { OFFERING_PATHS } from '../Header/items';

const useStyles = makeStyles(() => ({
  solutionSwiperSection: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    backgroundColor: white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& h4': {
      fontWeight: 'normal',
      textAlign: 'center',
      width: '65%',
      marginBottom: '12rem',
    },
  },
  solutionsTitle: {
    height: '5rem',
    marginTop: '2rem',
    marginBottom: '3rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& h3': {
      color: neusoftBlue,
      textAlign: 'center',
      '@media(max-width:47.938em)': {
        margin: '0',
      },
    },
    '@media(max-width:67.5em)': {
      marginBottom: '4rem',
      marginTop: '3rem',
    },
    '@media(max-device-width:67.5em)': {
      marginBottom: '4rem',
      marginTop: '3rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      margin: '3rem 0',
    },
    '@media(max-width:47.938em)': {
      margin: '4rem 0',
    },
  },
  sectionLine: {
    marginTop: '0.8rem',
    backgroundColor: neusoftBlue,
    width: '8rem',
    height: '0.25rem',
    zIndex: '1',
  },
  solutionsSwiper: {
    width: '90%',
    margin: 'auto',
    height: 'auto',
    marginBottom: '3rem',
    position: 'relative',
    '@media(max-width:85em)': {
      width: '95%',
    },
    '@media(max-width:67.5em)': {
      width: '90%',
      marginBottom: '6rem',
    },
    '@media(max-device-width:67.5em)': {
      width: '90%',
      marginBottom: '6rem',
    },
    '@media(max-width:47.938em)': {
      width: '100%',
    },
    '@media(max-width:85em) and (orientation: landscape)': {
      width: '85%',
    },
    '@media(min-width:150em)': {
      width: '70%',
    },
  },
  solutionContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: 'auto',
    position: 'relative',
    '& h4': {
      fontWeight: 'normal',
      width: '70%',
      margin: '0 auto',
      textAlign: 'center',
      overflowWrap: 'normal',
      marginTop: '2rem',
      '@media(max-width:67.5em)': {
        width: '85%',
      },
      '@media(max-device-width:67.5em)': {
        width: '85%',
      },
      '@media(max-height:47em) and (orientation: landscape)': {
        width: '70%',
      },
    },
    '@media(max-width:47.938em)': {
      width: '95%',
      justifyContent: 'center',
      margin: '0 auto',
    },
  },
  solutionImage: {
    height: 'auto',
    '& img': {
      width: '100%',
      height: '100%',
    },
    '@media(max-width:67.5em)': {
      width: '85%',
    },
    '@media(max-device-width:67.5em)': {
      width: '85%',
    },
    '@media(max-width:47.938em)': {
      width: '93.5%',
    },
  },
  shadow: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const SolutionPopUpSwiper = ({
  windowSize,
  items,
  label,
  titleParagraph,
  ...props
}) => {
  const classes = useStyles(props);
  const location = useLocation();
  const swiperLayout = () => {
    if (!OFFERING_PATHS.includes(location.pathname)) {
      const prevButton = document.querySelector('.swiper-button-prev');
      const nextButton = document.querySelector('.swiper-button-next');
      prevButton.style.left = '1rem';
      nextButton.style.right = '1rem';
      prevButton.style.bottom = '0';
      nextButton.style.bottom = '0';
      nextButton.style.paddingBottom = '12%';
      prevButton.style.paddingBottom = '12%';
      prevButton.style.height = '100%';
      nextButton.style.height = '100%';
      prevButton.style.width = '0';
      nextButton.style.width = '0';
      prevButton.style.backgroundColor = 'transparent';
      nextButton.style.backgroundColor = 'transparent';
      prevButton.style.boxShadow = '5px 0px 89px 40px #fff';
      nextButton.style.boxShadow = '-5px 0px 89px 40px #fff';
      if (windowSize.width > '480') {
        prevButton.style.left = '0.9rem';
        nextButton.style.right = '0.9rem';
        prevButton.style.position = 'absolute';
        nextButton.style.position = 'absolute';
      }
      if (windowSize.width > '810') {
        prevButton.style.boxShadow = '10px 0px 39px 70px #fff';
        nextButton.style.boxShadow = '-10px 0px 39px 70px #fff';
        prevButton.style.backgroundColor = white;
        nextButton.style.backgroundColor = white;
        prevButton.style.width = 'auto';
        nextButton.style.width = 'auto';
        prevButton.style.left = '0';
        nextButton.style.right = '0';
        nextButton.style.paddingBottom = '10%';
        prevButton.style.paddingBottom = '10%';
      }
      if (windowSize.width > '1300') {
        nextButton.style.paddingBottom = '7%';
        prevButton.style.paddingBottom = '7%';
      }
    } else if (OFFERING_PATHS.includes(location.pathname)) {
      const prevButton = document.querySelector('.swiper-button-prev');
      const nextButton = document.querySelector('.swiper-button-next');
      prevButton.style.left = '40%';
      nextButton.style.right = '40%';
      prevButton.style.bottom = '0';
      nextButton.style.bottom = '0';
    }
  };
  useEffect(() => {
    swiperLayout();
  });

  return (
    <div className={classes.solutionSwiperSection}>
      <div className={classes.solutionsTitle}>
        <h3>{label}</h3>
        <div className={classes.sectionLine} />
      </div>
      {titleParagraph && (
        <h4>{titleParagraph}</h4>
      )}
      {items && (
        <div className={classes.solutionsSwiper}>
          <Swiper
            modules={[Navigation]}
            slidesPerView="auto"
            centeredSlides
            spaceBetween={0}
            grabCursor
            breakpoints={{
              0: { slidesPerView: '1', spaceBetween: 0 },
              640: { slidesPerView: '1', spaceBetween: 0 },
              768: { slidesPerView: '1', spaceBetween: 0 },
              1025: { slidesPerView: '3', spaceBetween: 10 },
              1400: { slidesPerView: '3', spaceBetween: 10 },
            }}
            navigation
            loop
          >
            {items.map((item) => (
              <SwiperSlide key={item.image}>
                <div className={classes.solutionContent}>
                  <div className={classes.solutionImage}>
                    <img src={item.image} alt="" />
                  </div>
                  <h4>{item.text}</h4>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  );
};

export default SolutionPopUpSwiper;
