const imageSources = {
  data: [
    {
      id: 1,
      mediaItem: 'Adobe Stock video',
      reference: 'AdobeStock_361297830',
    },
    {
      id: 2,
      mediaItem: 'Navigation - Offering+Banner',
      reference: 'AdobeStock_293321987',
    },
    {
      id: 3,
      mediaItem: 'Cockpit - Offering+Banner',
      reference: 'AdobeStock_337967427',
    },
    {
      id: 4,
      mediaItem: 'Connectivity - Offering+Banner',
      reference: 'AdobeStock_300915243',
    },
    {
      id: 5,
      mediaItem: 'Testing - Offering+Banner',
      reference: 'AdobeStock_282642733',
    },
    {
      id: 6,
      mediaItem: 'Who We Are - Banner',
      reference: 'AdobeStock_206321037',
    },
    {
      id: 7,
      mediaItem: 'Hamburg Office',
      reference: 'Neusof Hamburg Office',
    },
    {
      id: 8,
      mediaItem: 'Munich Office',
      reference: 'Neusoft Munich Office',
    },
    {
      id: 9,
      mediaItem: 'Neusoft Corporate',
      reference: 'Neusoft Corporate 1',
    },
    {
      id: 10,
      mediaItem: 'Our Values - Banner',
      reference: 'AdobeStock_399744184',
    },
    {
      id: 11,
      mediaItem: 'Our Values - First Image #1',
      reference: 'AdobeStock_350234019',
    },
    {
      id: 12,
      mediaItem: 'Our Values - First Image #2',
      reference: 'AdobeStock_331686848',
    },
    {
      id: 13,
      mediaItem: 'Our Values - Second Image',
      reference: 'AdobeStock_436672674',
    },
    {
      id: 14,
      mediaItem: 'Global Footprint - Banner',
      reference: 'AdobeStock_303377125',
    },
    {
      id: 15,
      mediaItem: 'Global Footprint -  Image',
      reference: 'photo by Wikilmages - https://pixabay.com/de/photos/erde-welt-karte-kontinente-land-11595/',
    },
    {
      id: 16,
      mediaItem: 'Navigation - First Image',
      reference: 'AdobeStock_258293111',
    },

    {
      id: 17,
      mediaItem: 'Navigation - Second Image',
      reference: 'AdobeStock_272596255',
    },
    {
      id: 18,
      mediaItem: 'Navigation - Third Image',
      reference: 'AdobeStock_294423276',
    },
    {
      id: 19,
      mediaItem: 'Navigation - Fourth Image',
      reference: 'AdobeStock_37825542',
    },
    {
      id: 20,
      mediaItem: 'Navigation - Fifth Image',
      reference: 'AdobeStock_244312786',
    },
    {
      id: 21,
      mediaItem: 'Cockpit - First Image',
      reference: 'AdobeStock_337967192',
    },
    {
      id: 23,
      mediaItem: 'Cockpit - Second Image',
      reference: 'AdobeStock_161901352',
    },
    {
      id: 24,
      mediaItem: 'Cockpit - Third Image',
      reference: 'AdobeStock_62891068',
    },
    {
      id: 25,
      mediaItem: 'Cockpit - Fourth Image',
      reference: 'AdobeStock_190949733',
    },
    {
      id: 27,
      mediaItem: 'Connectivity - First Image',
      reference: 'Neusoft 5G T-Box',
    },
    {
      id: 28,
      mediaItem: 'Connectivity - Second Image',
      reference: 'Neusoft 4G RTM',
    },
    {
      id: 29,
      mediaItem: 'Connectivity - Third Image',
      reference: 'Neusoft 4G T-Box',
    },
    {
      id: 30,
      mediaItem: 'Connectivity - Fourth Image',
      reference: 'AdobeStock_351595938',
    },
    {
      id: 31,
      mediaItem: 'Connectivity - Fifth Image',
      reference: 'AdobeStock_362825877',
    },
    {
      id: 32,
      mediaItem: 'Testing - First Image',
      reference: 'AdobeStock_155506247',
    },

    {
      id: 33,
      mediaItem: 'Testing - Second Image',
      reference: 'AdobeStock_170631963',
    },
    {
      id: 34,
      mediaItem: 'Testing - Third Image',
      reference: 'Photo by Pat Kay - https://unsplash.com/photos/B9j-xgMVf90',
    }, {
      id: 35,
      mediaItem: 'Testing - Fourth Image',
      reference: 'AdobeStock_316108241',
    }, {
      id: 36,
      mediaItem: 'Testing - Fifth Image',
      reference: 'AdobeStock_294798032',
    }, {
      id: 37,
      mediaItem: 'Testing - Sixth Image',
      reference: 'AdobeStock_167736232',
    },
    {
      id: 39,
      mediaItem: 'Solutions - Banner',
      reference: 'AdobeStock_305628557',
    },
    {
      id: 40,
      mediaItem: 'Solutions - First Image',
      reference: 'Neusoft OneCoreGo',
    },
    {
      id: 41,
      mediaItem: 'Solutions - First Image - Pop-up 1 Image',
      reference: 'OneCoreGo running on Polestar2 Android Automotive Emulator',
    },
    {
      id: 42,
      mediaItem: 'Solutions - First Image - Pop-up 2 Image',
      reference: 'Neusoft Modifiable-Off-The-Shelf-Solution',
    },
    {
      id: 43,
      mediaItem: 'Solutions - First Image - Pop-up 3 Image',
      referenceLong: ['Neusoft Seamless Navigation photo by:',
        'Linus Mimietz - https://unsplash.com/photos/gvptKmonylk',
        'Jay Miller - https://unsplash.com/photos/DBurdz7Dm8Q',
        'Tamas Tuzes-Katai - https://unsplash.com/photos/rEn-AdBr3Ig',
        'Tobias - https://unsplash.com/photos/CyX3ZAti5DA]'],
    },
    {
      id: 44,
      mediaItem: 'Solutions - First Image - Pop-up 4 Image',
      reference: 'Neusoft Multi-Modal-Routing',
    },
    {
      id: 45,
      mediaItem: 'Solutions - First Image - Pop-up 5 Image',
      reference: 'Neusoft World Navigation',
    },
    {
      id: 46,
      mediaItem: 'Solutions - First Image - Pop-up 6 Image',
      reference: 'Neusoft Connected Services - Parking',
    },
    {
      id: 47,
      mediaItem: 'Solutions - First Image - Pop-up 7 Image',
      reference: 'Neusoft Connected Services - EV Routing 1',
    },
    {
      id: 48,
      mediaItem: 'Solutions - First Image - Pop-up 8 Image',
      reference: 'Neusoft Connected Services - EV Routing 2',
    },
    {
      id: 49,
      mediaItem: 'Solutions - Second Image',
      reference: 'Neusoft AR Solution',
    },
    {
      id: 50,
      mediaItem: 'Solutions - Second Image - Pop-up 1 Image',
      reference: 'Neusoft AR Solution',
    },
    {
      id: 51,
      mediaItem: 'Solutions - Second Image - Pop-up 2 Image',
      reference: 'Neusoft AR Navigation Forms',
    },
    {
      id: 52,
      mediaItem: 'Solutions - Second Image - Pop-up 3 Image',
      reference: 'Neusoft AR Turn by Turn Guidance Tips: Turning',
    },
    {
      id: 53,
      mediaItem: 'Solutions - Second Image - Pop-up 4 Image',
      reference: 'Neusoft AR Turn by Turn Guidance Tips: Roundabout',
    },
    {
      id: 54,
      mediaItem: 'Solutions - Second Image - Pop-up 5 Image',
      reference: 'Neusoft AR Lane Departure Warning',
    },
    {
      id: 55,
      mediaItem: 'Solutions - Second Image - Pop-up 6 Image',
      reference: 'Neusoft AR Collision Warning 1',
    },
    {
      id: 56,
      mediaItem: 'Solutions - Second Image - Pop-up 7 Image',
      reference: 'Neusoft AR Collision Warning 2',
    },
    {
      id: 57,
      mediaItem: 'Solutions - Second Image - Pop-up 9 Image',
      reference: 'Neusoft AR  Merge Tips 1',
    },
    {
      id: 58,
      mediaItem: 'Solutions - Second Image - Pop-up 10 Image',
      reference: 'Neusoft AR  Merge Tips 2',
    },
    {
      id: 59,
      mediaItem: 'Solutions - Second Image - Pop-up 11 Image',
      reference: 'Neusoft AR  Fork Tips',
    }, {
      id: 60,
      mediaItem: 'Solutions - Second Image - Pop-up 12 Image',
      reference: 'Neusoft AR  Fork Tips: Highway',
    }, {
      id: 62,
      mediaItem: 'Solutions - Third Image',
      reference: 'Neusoft AR Highway Information Tips',
    }, {
      id: 63,
      mediaItem: 'Solutions - Third Image - Pop-up 1 Image',
      reference: 'Neusoft AR Destination Tips',
    }, {
      id: 64,
      mediaItem: 'Solutions - Third Image - Pop-up 2 Image',
      reference: 'Neusoft Navigation Map: OpenStreetMap',
    }, {
      id: 65,
      mediaItem: 'Solutions - Third Image - Pop-up 3 Image',
      reference: 'Neusoft DB Map OSM',
    }, {
      id: 66,
      mediaItem: 'Solutions - Third Image - Pop-up 4 Image',
      reference: 'Neusoft DB Map OSM: World Coverage',
    }, {
      id: 67,
      mediaItem: 'Solutions - Third Image - Pop-up 5 Image',
      reference: 'Neusoft DB Map OSM: High Detailed Map',
    }, {
      id: 68,
      mediaItem: 'Solutions - Third Image - Pop-up 6 Image',
      reference: 'Neusoft DB Map OSM: Own Routing Layer',
    }, {
      id: 69,
      mediaItem: 'Solutions - Second Image - Pop-up 8 Image',
      reference: 'Neusoft DB Map OSM: Parking',
    }, {
      id: 70,
      mediaItem: 'Solutions - Fourth Image',
      reference: 'Neusoft DB Map OSM: Online Traffic',
    }, {
      id: 71,
      mediaItem: 'Solutions - Fourth Image - Pop-up 1 Image',
      reference: 'Photo by Avtodor Russian Highways - https://unsplash.com/photos/KSpZT9Z4bDs',
    }, {
      id: 72,
      mediaItem: 'Solutions - Fifth Image',
      reference: 'Photo by FLY:D - https://unsplash.com/photos/hjnbEUSwVuE',
    }, {
      id: 73,
      mediaItem: 'Solutions - Fifth Image - Pop-up 1 Image',
      reference: 'Neusoft Intelligent Cockpit System',
    }, {
      id: 74,
      mediaItem: 'Solutions - Fifth Image - Pop-up 2 Image',
      reference: 'Neusoft T-Box with Smart Antenna 1',
    }, {
      id: 75,
      mediaItem: 'Solutions - Fifth Image - Pop-up 3 Image',
      reference: 'Neusoft T-Box with Smart Antenna 2',
    }, {
      id: 76,
      mediaItem: 'Solutions - Fifth Image - Pop-up 4 Image',
      reference: 'Neusoft T-Box',
    }, {
      id: 77,
      mediaItem: 'Solutions - Sixt Image',
      reference: 'Neusoft RTM',
    }, {
      id: 78,
      mediaItem: 'Solutions - Seventh Image',
      reference: 'AdobeStock_260728592',
    }, {
      id: 79,
      mediaItem: 'Solutions - Seventh Image - Pop-up 1 Image',
      referenceLong: ['Neusoft Cloud Platform photo by:',
        'AdobeStock_354227159',
        'Photo by Marcel Strauß - https://unsplash.com/photos/EMj6W_r24gM',
        'Bram Van Oost - https://unsplash.com/photos/tU-L__PI7gw',
        'Bagus Hernawan - https://unsplash.com/photos/A6JxK37IlPo',
        'Leon Seibert - https://unsplash.com/photos/2m71l9fA6mg',
        'Frederic Köberl - https://unsplash.com/photos/x_0hW-KaCgI',
        'Eyestetix Studio - https://unsplash.com/photos/hqaFp0zclUk',
        'Alexander Shatov - https://unsplash.com/photos/PEJtZfT6C1Q',
        'Alexander Shatov - https://unsplash.com/photos/qt7S2QuAhJI',
        'Mika Baumeister - https://unsplash.com/photos/PZao9UjlbMY',
        'Tony Stoddard - https://unsplash.com/photos/DgZxBnZeQo8',
        'Claudio Schwarz - https://unsplash.com/photos/EPEYcAxt54c',
        'Matheus Bardemaker - https://unsplash.com/photos/-x3B_XHiE3A'],
    }, {
      id: 80,
      mediaItem: 'Solutions - Eighth Image',
      reference: 'AdobeStock_355388920',
    }, {
      id: 81,
      mediaItem: 'Solutions - Seventh Image - Pop-up 1 Image',
      reference: 'AdobeStock_354864144',
    }, {
      id: 82,
      mediaItem: 'Solutions - Seventh Image - Pop-up 2 Image',
      referenceLong: ['Neusoft GIS photo by:',
        'Denys Nevozhai - https://unsplash.com/photos/_QoAuZGAoPY',
        'Adrian N - https://unsplash.com/photos/xi4qYegtxb4',
        'Zane Lee - https://unsplash.com/photos/BWDX6jXTHME',
        'David Tatum - https://unsplash.com/photos/ZJZIxHnUeTo',
        'Patryk Sikora - https://unsplash.com/photos/G9nty0Pg5NE',
        'Markus Winkler - https://unsplash.com/photos/EbqlnKG6iEw',
        'Jonas Augustin - https://unsplash.com/photos/skltJUhLGWY',
        'Jonas Augustin - https://unsplash.com/photos/C2oqTVGjER0',
        'Jonas Augustin - https://unsplash.com/photos/OSHI4y7CLf0',
        'Jacek Dylag - https://unsplash.com/photos/mmar_Xs8_mk',
        'Jacek Dylag - https://unsplash.com/photos/YEfx6bdQ37k',
      ],
    }, {
      id: 83,
      mediaItem: 'Working With Us - Banner',
      reference: 'AdobeStock_293152428',
    }, {
      id: 84,
      mediaItem: 'Working With Us - Image',
      reference: 'AdobeStock_274192221',
    }, {
      id: 85,
      mediaItem: 'Open Positions - Banner',
      reference: 'Neusoft Corporate 2',
    }, {
      id: 86,
      mediaItem: 'Contacts - Image',
      reference: 'AdobeStock_434458540',
    }, {
      id: 87,
      mediaItem: 'Imprint & Data Policy Image',
      reference: 'Photo by Romain Dancre - https://unsplash.com/photos/doplSDELX7E',
    },
  ],
};
export default imageSources;
