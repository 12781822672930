import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  listAbout: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: '1.5rem 0',
    '@media(max-width:47.938em)': {
      margin: '1rem 0',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      margin: '1rem 0',
    },
    '& h4': {
      '@media(min-width:48em) and (max-width:67.5em)': { fontSize: '1.8rem' },
    },
  },
  descriptionContainer: {
    width: '100%',
    minHeight: (props) => props.height,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    '@media(max-width:67.5em)': {
      justifyContent: 'space-between',
    },
    '@media(max-device-width:67.5em)': {
      justifyContent: 'space-between',
    },
  },
  subtitle: {
    fontWeight: 'bold',
    margin: '0',
    color: (props) => props.color,
    '@media(max-width:47.938em)': {
      fontSize: '1.2rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      fontSize: '1.2rem',
    },
  },
  descriptionList: {
    width: '100%',
    marginLeft: '1rem',
    marginTop: '0.5rem',
  },
  lisItem: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.625rem',
    '@media only screen and (max-width: 67.5em)': {
      fontSize: '1.7rem',
    },
    '@media only screen and (max-device-width: 67.5em)': {
      fontSize: '1.7rem',
    },
    '@media(max-width:47.938em)': { fontSize: '1.3rem' },
    '@media only screen and (min-device-width : 48em) and (max-device-width : 67.5em)':
    {
      fontSize: '2rem',
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      fontSize: '1.3rem',
      margin: '0',
    },
    '@media(max-width:92em)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    '& h4': {
      '@media(max-width:47.938em)': { fontSize: '1.2rem' },
      '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
      {
        fontSize: '1.3rem',
      },
      '@media(min-width:67.5em)': { width: 'auto' },
      '@media(min-width:85.375em)': { width: 'auto' },
      width: 'auto',
      margin: '0.3rem 0',
      fontWeight: 'normal',
      color: (props) => props.color,
      '& span': {
        marginRight: '1.5rem',
      },
    },
    '& p': {
      marginLeft: '0.5rem',
      fontStyle: 'italic',
      fontWeight: 'normal',
      color: (props) => props.color,
    },
  },
}));

// eslint-disable-next-line object-curly-newline
const ListComponent = ({ list, listTitle, ...props }) => {
  const classes = useStyles(props);

  return (
    <div className={classes.listAbout}>
      <h4 className={classes.subtitle}>{listTitle}</h4>
      <div className={classes.descriptionContainer}>
        <div className={classes.descriptionList}>
          {list.map((item) => (
            <div key={item.text} className={classes.lisItem}>
              <h4>
                <span>{item.sign}</span>
                {item.text}
              </h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ListComponent;
