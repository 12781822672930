import React from 'react';
import { makeStyles } from '@material-ui/core';
import { neusoftBlue, white } from '../../colors';
import quote from '../../assets/images/icons/home/quotes-icon.svg';

const useStyles = makeStyles(() => ({
  mottoContainer: {
    order: '0',
    backgroundColor: neusoftBlue,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    boxShadow: ' 0px 10px 20px #00002ECB',
    width: 'auto',
    height: 'auto',
    '@media only screen and (max-width: 67.5em)': {
      width: '100%',
      justifyContent: 'space-evenly',
      order: '-1',
    },
    '@media only screen and (max-device-width: 67.5em)': {
      width: '100%',
      justifyContent: 'space-evenly',
      order: '-1',
    },

    '@media only screen and (max-device-width : 67.5em) and (orientation: landscape)':
    {
      justifyContent: 'center',
    },
  },
  content: {
    margin: '4rem 4rem',
    marginRight: '2rem',
    '@media(max-width:67.5em)': { margin: '7.5rem 0', marginRight: '3rem' },
    '@media(max-device-width:67.5em)': { margin: '7.5rem 0', marginRight: '3rem' },
    '@media(max-width:47.938em)': { margin: '3.65rem 0', marginRight: '1rem' },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      margin: '2.5rem 0', marginRight: '3rem',
    },
    '@media only screen and (max-device-height : 47em) and (min-device-width : 67.5em) and (orientation: landscape)':
    {
      margin: '3rem 3rem',
      marginRight: '2rem',
    },
  },
  mottoTitle: {
    fontStyle: 'italic',
    color: white,
    fontWeight: 'normal',
    lineHeight: '1.2',
    '@media(max-width:67.5em)': { fontSize: '5.15rem' },
    '@media(max-device-width:67.5em)': { fontSize: '5.15rem' },
    '@media(max-width:47.938em)': { fontSize: '2.87rem', lineHeight: '1.4' },

    '@media(min-width:67.5em)': { fontSize: '3.5rem' },
    '@media only screen and (max-height : 40em) and (orientation: landscape)':
    {
      fontSize: '3rem',
      lineHeight: '1.4',
    },
    '@media only screen and (max-device-height : 50em) and (orientation: landscape)':
    {
      fontSize: '4.5rem',
      lineHeight: '1.4',
    },
    '@media only screen and (max-device-height : 47em) and (orientation: landscape)':
    {
      fontSize: '3.2rem',
      lineHeight: '1.4',
    },
    '@media(min-width:85.375em)': { fontSize: '4rem' },
    '@media only screen and (max-device-height : 47em) and (min-device-width : 67.5em) and (orientation: landscape)':
    {
      fontSize: '2.9rem',
      lineHeight: '1.4',
    },
    '@media(max-device-width:20em)': { fontSize: '2.5rem' },
  },
  quotes: {
    marginRight: '3rem',
    marginTop: '3rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%',
    '@media(max-width:67.5em)': {
      marginRight: '1rem',
      marginTop: '2rem',
    },
    '@media(max-device-width:67.5em)': {
      marginRight: '1rem',
      marginTop: '2rem',
    },
    '@media only screen and (max-width: 40.625em)': {
      marginRight: '1rem',
      marginTop: '2rem',
    },
  },
  mottoImageContainer: {
    height: '9.4rem',
    '@media only screen and (max-width: 100em)': {
      height: '7.5rem',
    },
    '@media only screen and (max-width: 67.5em)': {
      height: '10.6rem',
    },
    '@media only screen and (max-device-width: 67.5em)': {
      height: '10.6rem',
    },
    '@media only screen and (min-width: 150em)': {
      height: '9.5rem',
    },
    '@media only screen and (max-device-height : 67.5em) and (orientation: landscape)':
    {
      height: '8rem',
    },
    '@media only screen and (max-device-height : 30em) and (orientation: landscape)':
    {
      height: '5rem',
    },
    '@media only screen and (max-width: 40.625em)': {
      height: '5rem',
    },
  },
  qouteImage: {
    height: '100%',
  },
}));

const MottoComponent = ({ ...props }) => {
  const classes = useStyles(props);

  return (
    <div className={classes.mottoContainer}>
      <div className={classes.content}>
        <h1 className={classes.mottoTitle}>Pioneering the</h1>
        <h1 className={classes.mottoTitle}>industry for</h1>
        <h1 className={classes.mottoTitle}>over 30 years</h1>
      </div>
      <div className={classes.quotes}>
        <div className={classes.mottoImageContainer}>
          <img className={classes.qouteImage} src={quote} alt="Neusoft" />
        </div>
      </div>
    </div>
  );
};

export default MottoComponent;
